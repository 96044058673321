import { useState } from "react";
import "./dashboard.css";
import DataCountServices from '../../services/dataCountService';
import { useEffect } from "react";
import EmpChart from "../../components/chart/empChart"
import StudentParentChart from "../../components/chart/studentParentChart";
import AllTripStudentChart from "../../components/chart/allTripStudentChart";
import AllUsersChart from "../../components/chart/allUsersChart";
import {  getToken } from "firebase/messaging";
import { messaging } from "../../config/firebae";
import userService from "../../services/userService";


function SchoolDashBoard(){
    const schoolId=localStorage.getItem('schoolId');
    const [firstLineItem,setFirstLineItem]=useState([
        {
        id:1,
        title:"Students",
        count:0,
    },
    {
        id:2,
        title:"Parents",
        count:0
    },
    {
        id:3,
        title:"Managers",
        count:0
    },
    {
        id:4,
        title:"Operations Manager",
        count:0
    },
    {
        id:5,
        title:"Bus Drivers",
        count:0
    },
    {
        id:6,
        title:"Bus Asistent",
        count:0
    },
    {
        id:7,
        title:"Buses",
        count:0
    },
    {
        id:8,
        title:"Trip",
        count:0
    },
    {
        id:9,
        title:"All Trip",
        count:0
    },
    {
      id:10,
      title:"Active Trip",
      count:0
  },
  {
    id:11,
    title:"Finish Trip",  
    count:0
},
{
  id:12,
  title:"Cancelled Trip",
  count:0 
}
]);
// async function requestNotifcationPermission(){
//   console.log("--------ssssssssssssssssssssssssssssssssssssssssss===================");
//   const permission=await Notification.requestPermission();
//   if(permission=="granted"){
//     const fcm=await getToken(messaging,{vapidKey:"BDU0lnccPubHYP48RB3CVQiyLsKR0VkZOuEGaL9ESyjZ1rkqtgha-8bJqaT2P_9A46oQcxrvFQnoc32wMQE8n60"})
//     console.log("FCM Token",fcm);
//       localStorage.setItem('fcm', fcm);
//       if(fcm!=null&&fcm!="null"&&fcm!=""){
//          const userId= localStorage.getItem('id');
//         await userService.updateWebFcm(userId,fcm);
//       }
//   }else if(permission=="denied"){
//     alert("You denied for notification")
//   }
//   }
useEffect(() => {
    getUserBySchoolAndRole(0,6);
    getUserBySchoolAndRole(1,8);
    getUserBySchoolAndRole(2,3);
    getUserBySchoolAndRole(3,9);
    getUserBySchoolAndRole(4,4);
    getUserBySchoolAndRole(5,5);
    getBusesBySchool(6);
    getTripBySchool(7);
    getTripHistoryBySchool(8);
    getActiveTripSchool(9);
    getFinishTripSchool(10);
    getCancledTripSchool(11);
  // requestNotifcationPermission();
  }, []);

 function getUserBySchoolAndRole(itemIndex,roleId) {
     DataCountServices.getUserBySchoolAndRole(schoolId, roleId).then(
      (data) => {
        let newArr = [...firstLineItem];
        newArr[itemIndex].count =data.length;
        setFirstLineItem(newArr);
      }
   
    );
  }
  function getBusesBySchool(itemIndex) {
    DataCountServices.getBusesBySchool(schoolId).then(
     (data) => {
       let newArr = [...firstLineItem];
       newArr[itemIndex].count =data.length;
       setFirstLineItem(newArr);
     }
   );
 }

function getTripHistoryBySchool(itemIndex) {
  DataCountServices.getTripHistoryBySchool(schoolId).then(
   (data) => {
     let newArr = [...firstLineItem];
     newArr[itemIndex].count =data.length;
     setFirstLineItem(newArr);
   }

 );
}

 function getTripBySchool(itemIndex) {
    DataCountServices.getTripBySchool(schoolId).then(
     (data) => {

       let newArr = [...firstLineItem];
       newArr[itemIndex].count =data.length;
       setFirstLineItem(newArr);
     }
  
   );
 }
 function getActiveTripSchool(itemIndex) {
  DataCountServices.getActiveTripBySchool(schoolId).then(
   (data) => {

     let newArr = [...firstLineItem];
     newArr[itemIndex].count =data.length;
     setFirstLineItem(newArr);
   }

 );
}
function getFinishTripSchool(itemIndex) {
  DataCountServices.getTripBySchoolAndStatus(schoolId,3).then(
   (data) => {

     let newArr = [...firstLineItem];
     newArr[itemIndex].count =data.length;
     setFirstLineItem(newArr);
   }

 );
}
function getCancledTripSchool(itemIndex) {
  DataCountServices.getTripBySchoolAndStatus(schoolId,4).then(
   (data) => {

     let newArr = [...firstLineItem];
     newArr[itemIndex].count =data.length;
     setFirstLineItem(newArr);
   }

 );
}

return(
    <div className="dashboard">
 <div className="item-count-box">  {    firstLineItem.map((p,index) =>{
    return (
    <div className="info-box-card">
        <div className="image-section">
        <span className="image">
            <i className="fa fa-user" aria-hidden="true"></i>
          </span>
        </div>
        <div className="info-section">
            <div className="info-title">{p.title}</div>
            <div className="info-sub-title">{p.count}</div>
        </div>
    
    </div>
    )})}</div>
    <div>

      
    </div>

  <div className="first-chart-section">
  <div className="empChartBox">
      <div className="first-line-chart">
            <AllUsersChart
            student={firstLineItem[0].count}
            parent={firstLineItem[1].count}
    managers={firstLineItem[2].count}
    opManagers={firstLineItem[3].count}
    busDrivers={firstLineItem[4].count}
    busAssistent={firstLineItem[5].count}
    >
    </AllUsersChart>
      </div>
      

    </div>
  <div className="empChartBox">
  <div className="first-line-chart">

    <StudentParentChart
    students={firstLineItem[0].count}
    parents={firstLineItem[1].count}
    >
    </StudentParentChart>
      </div>
    </div>
    <div className="empChartBox">
      <div className="first-line-chart">
            <EmpChart

    managers={firstLineItem[2].count}
    opManagers={firstLineItem[3].count}
    busDrivers={firstLineItem[4].count}
    busAssistent={firstLineItem[5].count}
    >
    </EmpChart>
      </div>
      

    </div>
 
    

  </div>
  <AllTripStudentChart></AllTripStudentChart>
    </div>
);


}

export default SchoolDashBoard;