import { Row, Col, Form, Button, Dropdown, Modal, Card } from 'react-bootstrap'
import "../../common/custoForm.css"
import { useEffect, useState } from 'react';
import LoadingCompinentCommon from '../../common/commonLoading';
import UserServices from '../../services/userService';
import { useNavigate, useParams } from 'react-router-dom';
import cityService from '../../services/cityService';
import { useLocation } from "react-router-dom";
import UploadFileService from '../../services/uploadFile';
import HeaderSubTitle from '../../header/headerSubTitle';
import userService from '../../services/userService';
import roleService from '../../services/roleService';
import SyncServices from '../../services/syncService';
import Geocode from "react-geocode";
import schoolServices from '../../services/schoolServices';
import moment from "moment";
import Table from 'react-bootstrap/Table';
import Select from 'react-select';
import "./user.css" 
import api_url from "../../my_config"; 
import validation from '../../helper/validation';
import GoogleMapComponent from "../../components/googleMap/googleMap";

function UpdateUsers() {
    const id=localStorage.getItem('id');
    const roleId=localStorage.getItem('roleId');
    const schoolId=localStorage.getItem('schoolId');
    const schoolTitle=localStorage.getItem('schoolTitle');
    const [isShowParentSync, setIsShowParentSync] = useState(null);
    const [isShowStudentSync, setIsShowStudentSync] = useState(null);
    const [mapLatLng,setMapLatLng]=useState(null);
    const [schoolLat, setschoolLat] = useState(null);
    const [schoolLng, setschoolLng] = useState(null);
    const { userID } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({
        phone: "",
        address: "",
        first_name: "",
        last_name: "",
        image_id:null,
        image:null
    });
    
    const [isLoading, setLoading] = useState(false);
    const [isAdminRole, setAdminRole] = useState(false);
    const [isManagerRole, setManagerRole] = useState(false);
    const [resSuccess, setResSuccess] = useState(false);
    const [resMsg, setResMsg] = useState('')
    const [show, setShow] = useState(false);
    const [showCBox, setShowCBox] = useState(false);

    const [userTypes, setUserTypes] = useState([]);
    const [isDataNull, setisDataNull] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [image, setImage] = useState(null)
    const [imageId, setImageId] = useState(null)
    const [isImageUploading, setFlagUloadImage] = useState(false)

    const [syncData, setSyncData] = useState([]);
    const [syncParentData, setSyncParentData] = useState([]);
    const [syncStudentData, setSyncStudentData] = useState([]);
    const [selectedSyncId,setSelectedSycnId]=useState(null);
    const [schoolTitleName,setSchoolTitleName]=useState(null);
    const [roleTitleSelect,setRollTitle]=useState(null);
    const [deleteSyncFlag,setDeleteSyncFlag]=useState(0);//0=sysn user 1=parent sync
    const [schoolOPData,setschoolOPData] = useState([]);
    const [schoolUsersOPData,setschoolUsersOPData] = useState([]);
    const [selectedUsersOPData,setselectedUsersOPData] = useState(null);
    const [selectedSchoolIdForU, setselectedSchoolIdForU] = useState(null);
    const [selectedRoolId, setselectedRoolId] = useState(null);
    const [selectedSchoolIdForP, setselectedSchoolIdForP] = useState(null);

    const [formData, setFormData] = useState({});
    const [sameAddFlag, setSameAddFlag] = useState(false);
    
    const [errorsFName, setErrorsFName] = useState(null);
    const [errorsLName, setErrorsLName] = useState(null);
    const [errorPhone, setErrorsPhone] = useState(null);

    const [pErrorsBN, setPErrorsBN] = useState(null);
    const [pErrorsSN, setPErrorsSN] = useState(null);
    const [pErrorsAN, setPErrorsAN] = useState(null);
    const [pErrorsCity, setPErrorsCity] = useState(null);
    const [pErrorsPicCode, setPErrorsPicCode] = useState(null);
    const [pErrorsLat, setErrorsLat] = useState(null);
    const [pErrorsLng, setErrorsLng] = useState(null);
  
    const [dErrorsBN, setDErrorsBN] = useState(null);
    const [dErrorsSN, setDErrorsSN] = useState(null);
    const [dErrorsAN, setDErrorsAN] = useState(null);
    const [dErrorsCity, setDErrorsCity] = useState(null);
    const [dErrorsPicCode, setDErrorsPicCode] = useState(null);
    const [dErrorsLat, setErrorsDLat] = useState(null);
    const [dErrorsLng, setErrorsDLng] = useState(null);
    const [showGoogleMapBox, setShowGoogleMapBox] = useState(false);

    const [pickMapForDrop,setPickMapForDrop]=useState(false);


    function getSChool() {
        schoolServices.getData()
            .then((data) => {
                const dataArr=[];
            
                data.map((e) => {
                    dataArr.push({
                         value: e.id, label: e.title 
                        
                    });
                });
                setschoolOPData([...dataArr]);
         
            })
    }
         function getSChoolById(school_id) {
        schoolServices.getDataById(school_id)
            .then((data) => {
                setschoolLat(data.lat);
                setschoolLng(data.lng);
    
            })
    }
    function getSChoolUsers(school_id) {
        setselectedUsersOPData(null);
        UserServices.getAllDataBySchoolId(school_id)
        .then((data) => {
            const dataArr=[];
          const newData=  data.filter((item)=>
            {
              for(var i=0;i<item.role.length;i++){
              if(item.role[i].role_id==6)
              return true;
              }
            }   
            )
            
            newData.map((e) => {
             
                dataArr.push({value: e.id, label: e.first_name+" "+e.last_name});

            });
            setschoolUsersOPData([...dataArr])
          

            })

    }
    
    function getSyncData() {
        if(roleId==1){
            UserServices.getSyncUsers(userID)
            .then((data) => {
                setSyncData(data);
        
            })
        }
       
        else if(roleId==2||roleId==3){
            UserServices.getSyncUsersSChoolUserId(userID,schoolId)
            .then((data) => {
                setSyncData(data);
                for(var i=0;i<data.length;i++){
                    if(data[i]['role_id']==3)
                    setManagerRole(true);
                    if(data[i]['role_id']==2){
                     setAdminRole(true);
                   }
               
                    }
        
            })
        }
      
    }

    function getParentSyncData() {
        if(roleId==2||roleId==3){
            UserServices.getSyncUsersSChoolUserRoleId(userID,schoolId,8)
            .then((data) => {
                    if(data!=null){
                        if(data.length>0){
                            setIsShowParentSync(true);
            
                            SyncServices.getParentSyncUsersByParentSchool(schoolId,userID)
                            .then((data) => {
                                setSyncParentData(data);
                        
                            })
                            
                        }
                    }
        
            })
            UserServices.getSyncUsersSChoolUserRoleId(userID,schoolId,6)
            .then((data) => {
                    if(data!=null){
                        if(data.length>0){
                            setIsShowStudentSync(true);
            

                            SyncServices.getParentSyncUsersByStudentSchool(schoolId,userID)
                            .then((data) => {
                                setSyncStudentData(data);
                        
                            })
                            
                        }
                    }
        
            })
        }   
        
    }

    function handleClose() {
        setShow(false);
        
    }
    function handleCloseCBox() {
        setShowCBox(false);
        // if (resSuccess == true)
        //     navigate(-1)
    }
    const handleShow = () => setShow(true);
    const handleShowCBox = () => setShowCBox(true);
    function getCity() {
        setLoading(true);
        if(roleId==1){
            setUserTypes([{value:"2",label:"Admin"}])
        }else{
            if(schoolId!="null"&&schoolId!=null)
            setselectedSchoolIdForU({"value":schoolId,"label":schoolTitle});
     
            if(roleId==2)
     {       
        const dataArr=[];
        roleService.getDataSchool()
            .then((data) => {
                data.map((e) => {
                    if( e.id!=7)
                    dataArr.push({
                         value: e.id, label: e.title 
                        
                    });
                });
                setUserTypes([...dataArr]);
            
            })}
            if(roleId==3)
            {       
               const dataArr=[];
               roleService.getDataManager()
                   .then((data) => {
                       data.map((e) => {
                           if( e.id!=7)
                           dataArr.push({
                                value: e.id, label: e.title 
                               
                           });
                       });
                       setUserTypes([...dataArr]);
                   
                   })}
        }
   
        userService.getDataById(userID)
            .then((data) => {
         
               if (data == null)
                    setisDataNull(true)
                else {
                    setData({
                        'email': data['email'],
                        'phone': data['phone'],
                        'first_name':  data['first_name'],
                        'last_name':  data['last_name'],
                        'address':  data['address'],
                        'phone_secondary': data['phone_secondary'],
                        'active': data['active'],
                        'image_id':data['image_id'],
                        'image':data['image'],
                        
                    })
                    setSelectedTravellerData(data);
                }
                setLoading(false);

            })
    }
    useEffect(() => {
        getCity();
        getSChool();
        getSyncData();
        getParentSyncData();
        if(roleId==2||roleId==3){
    
            setselectedSchoolIdForP({"value":schoolId,"label":schoolTitle})
            getSChoolUsers(schoolId);
            getSChoolById(schoolId)
        }
    }, []);
    function handleSubmitSync() {
   
        setLoading(true);
        setResMsg('')
        setResSuccess(false);
    
        UserServices.syncUser(userID, selectedSchoolIdForU.value,selectedRoolId.value).then((res) => {
            if (res == 'error') {
                setResMsg('Something went wrong')
            } else {
                if (res['status'] == true) { setResSuccess(true); 
                    getSyncData();
                }
                setResMsg(res['message'])
            }
            
            handleShow();

            setLoading(false);
        });
    }
    function  handleSubmitCredit(e) {
        e.preventDefault();
        setLoading(true);
        setResMsg('')
        setResSuccess(false);    


        UserServices.updateCreditData(userID, data.phone,data.email).then((res) => {
            if (res == 'error') {
                setResMsg('Something went wrong')
            } else {
                if (res['status'] == true) { setResSuccess(true); 
                    getParentSyncData();
                }
                setResMsg(res['message'])
            }
            handleShow();

            setLoading(false);
        });
    }
    function   handleSubmitSyncP() {
        setLoading(true);
        setResMsg('')
        setResSuccess(false);    
        SyncServices.syncUserP(userID, selectedSchoolIdForP.value,selectedUsersOPData.value).then((res) => {
            if (res == 'error') {
                setResMsg('Something went wrong')
            } else {
                if (res['status'] == true) { setResSuccess(true); 
                    getParentSyncData();
                }
                setResMsg(res['message'])
            }
            handleShow();

            setLoading(false);
        });
    }
    function handleSubmit(e) {
        e.preventDefault();
        let isValidFName=false;
        let isValidLName=false;
        let isValidPhone=false;
        const fNameValidate=validation.checkNameValidation(data.first_name);
        if(fNameValidate){
            isValidFName=true;
            setErrorsFName(null);
        }
            else{
                isValidFName=false
                setErrorsFName("Enter valid name");
            
            }
       
            const lNameValidate=validation.checkNameValidation(data.last_name);
            if(lNameValidate){
                isValidLName=true;
              setErrorsLName(null);
          
            }
                else{
                    isValidLName=false
                    setErrorsLName("Enter valid name");
           
                }
            
                if(data.phone_secondary!==null&&data.phone_secondary!==""){
                    const phoneValidate=validation.checkPhoneValidation(data.phone_secondary);

                    if(phoneValidate){
                        isValidPhone=true;
                    setErrorsPhone(null);
                    
                    }
                        else{
                            isValidPhone=false
                            setErrorsPhone("Enter valid 10 digit phone number");
                    
                        }
                }else{
                    isValidPhone=true;
                    setErrorsPhone(null);
                }
        
                if(isValidFName==true&&isValidLName==true&&isValidPhone==true){
                    setLoading(true);
        setResMsg('')
        setResSuccess(false);
       
    
        if(selectedFile!=null)
        { 
          console.log("case 1")
          UserServices.updateData(userID,data.active,data.first_name,data.last_name,data.phone_secondary,data.address,selectedFile,data.image_id)
          .then((res) => {
              if (res == 'error') {
                  setResMsg('Something went wrong')
              } else {
                  if (res['status'] == true) { setResSuccess(true); }
                  setResMsg(res['message'])
              }
              handleShow();
  
              setLoading(false);
          });}
          else if(selectedFile==null){
              
              if(data.image_id==null)
              {  
                  console.log("case 2")
                  UserServices.updateData(userID,data.active,data.first_name,data.last_name,data.phone_secondary,data.address,selectedFile,data.image_id)

                  .then((res) => {
                      if (res == 'error') {
                          setResMsg('Something went wrong')
                      } else {
                          if (res['status'] == true) { setResSuccess(true); }
                          setResMsg(res['message'])
                      }
                      handleShow();
          
                      setLoading(false);
                  });}
                  else if(data.image_id!=null&&data.image!=null)
                  {  
                      console.log("case 3")
                      UserServices.updateData(userID,data.active,data.first_name,data.last_name,data.phone_secondary,data.address,selectedFile,null)
                      .then((res) => {
                          if (res == 'error') {
                              setResMsg('Something went wrong')
                          } else {
                              if (res['status'] == true) { setResSuccess(true); }
                              setResMsg(res['message'])
                          }
                          handleShow();
              
                          setLoading(false);
                      });
                  
                  }
                  else if(data.image_id!=null&&data.image==null)
                  { 
                      console.log("case 4")
                      UserServices.updateData(userID,data.active,data.first_name,data.last_name,data.phone_secondary,data.address,selectedFile,data.image_id)
                      .then((res) => {
                          if (res == 'error') {
                              setResMsg('Something went wrong')
                          } else {
                              if (res['status'] == true) { setResSuccess(true); }
                              setResMsg(res['message'])
                          }
                          handleShow();
              
                          setLoading(false);
                      });
                  
                  }
                  
          }
                }

    
    }
    function handleChange(e) {
        const newData = { ...data }
        newData[e.target.id] = e.target.value
        setData(newData);
        console.log(newData);

        
    }
    function  handleChangeSwitch(e){
        const newData = { ...data }
        if(newData[e.target.id]==1)
        newData[e.target.id] = 0
        else if(newData[e.target.id]==0)
        newData[e.target.id] = 1
        setData(newData);
        setData(newData);
    }

    function handleChangePOP(e) {
        setselectedSchoolIdForP(e)
        getSChoolUsers(e.value)
        // const newData = { ...data }
        // newData[e.target.id] = e.target.value
        // setData(newData);

        
    }
    function handleShowGoogleMapBox(pickMapForDropStatus) {
        setPickMapForDrop(pickMapForDropStatus)
        setShowGoogleMapBox(true);
       
      }
      function handleCloseGoogleMapBox() {
        setShowGoogleMapBox(false);
      }
      function handleSubmitGoogleMap() {
        if(mapLatLng!=null)
    {    if(pickMapForDrop){
      setCordinatesDropLatLng(mapLatLng)
    
    }else{
      setCordinatesPickUpLatLng(mapLatLng);
    }
     }
        setShowGoogleMapBox(false);
      }
    function handleChangeModelForm(e) {
        const newData = { ...formData };
        if (e.target.id == "is_pick_up")
          newData[e.target.id] = formData[e.target.id] ? false : true;
        else if (e.target.id == "is_drop")
          newData[e.target.id] = formData[e.target.id] ? false : true;
        else newData[e.target.id] = e.target.value;
        setFormData(newData);
        console.log(newData);
      }
      function handleChangeSAFlag(e) {
        const newValue=!sameAddFlag
        setSameAddFlag(newValue);
        if(newValue){
          const newData = { ...formData };
          //formData['drop_lat']= formData['pick_up_lat'];
          newData['drop_lng']= formData.pick_up_lng;
          newData['drop_lat']= formData.pick_up_lat;
          newData['drop_building_number']= formData.pick_up_building_number;
          newData['drop_street_name']= formData.pick_up_street_name;
          newData['drop_area_name']= formData.pick_up_area_name;
          newData['drop_city']= formData.pick_up_city;
          newData['drop_pin_code']= formData.pick_up_pin_code;
      
          setFormData(newData);
        }else{
          const newData = { ...formData };
          //formData['drop_lat']= formData['pick_up_lat'];
          newData['drop_lng']= '';
          newData['drop_lat']= '';
          newData['drop_building_number']= '';
          newData['drop_street_name']= '';
          newData['drop_area_name']= '';
          newData['drop_city']= '';
          newData['drop_pin_code']= '';
      
          setFormData(newData);
        }
      }
    function handleRemoveImage(){
        setImage(null);
        setImageId(null);
        setSelectedFile(null);
        const newData = { ...data }
        newData['image'] =null;
        setData(newData);
        console.log(newData);
        
    }
    function handleChangeImage(event) {
        if (event.target.files && event.target.files[0]) {
            if (!event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i))
                 alert('not an image');
    
    else{
        let file_size = event.target.files[0].size;
        if(file_size>0&&file_size<512000)
   {    
    
    const newData = { ...data }
    newData['image'] =null;
    setData(newData);
    setSelectedFile(event.target.files[0])
        setImage(URL.createObjectURL(event.target.files[0]));
    }
        else{
            alert("File Size must be less then 500kb");
            // alert(file_size);
        }
    }
           
        }
    }
    function setCordinatesDropLatLng(cordinates){
        Geocode.setApiKey("AIzaSyA3GkUvfEQeMptos3NG_yufZ7tvZCenvJk");
        // set response language. Defaults to english.
        Geocode.setLanguage("en");
        Geocode.setLocationType("ROOFTOP");
        
        Geocode.fromLatLng(cordinates.lat, cordinates.lng).then(
          (response) => {
            const address = response.results[0].formatted_address;
            let city, state, country, postalCode,streetName,areaName, buildingNumber;
            for (let i = 0; i < response.results[0].address_components.length; i++) {
              for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                switch (response.results[0].address_components[i].types[j]) {
                  case "locality":
                    city = response.results[0].address_components[i].long_name;
                    break;
                  case "administrative_area_level_1":
                    state = response.results[0].address_components[i].long_name;
                    break;
                  case "country":
                    country = response.results[0].address_components[i].long_name;
                    break;
                    case "postal_code":
                      postalCode = response.results[0].address_components[i].long_name;
                      break;
                      case "route":
                        streetName = response.results[0].address_components[i].long_name;
                        break;
                        case "sublocality_level_1":
                          areaName = response.results[0].address_components[i].long_name;
                          break;
                          case "premise":
                            buildingNumber = response.results[0].address_components[i].long_name;
                            break;
                                       
                }
              }
            }
            // console.log(city, state, country,postalCode, streetName,areaName,buildingNumber);
           console.log(address);
            // console.log(cordinates.lat)
            // console.log(cordinates.lng)
            const newData = { ...formData };
            newData['drop_building_number']= buildingNumber??"";
            newData['drop_street_name']= streetName??"";
            newData['drop_area_name']= areaName??"";
            newData['drop_city']= city??"";
            newData['drop_pin_code']= postalCode??"";
            newData["drop_lat"] = cordinates.lat??"";
            newData["drop_lng"] = cordinates.lng??"";
            setFormData(newData);
            console.log("giiiiiiiiiiiii",newData);
          },
          (error) => {
            console.error(error);
          }
        );
        const newData = { ...formData };
        newData["drop_lat"] = cordinates.lat;
        newData["drop_lng"] = cordinates.lng;
        setFormData(newData);
      }
      function setCordinatesPickUpLatLng(cordinates){
        Geocode.setApiKey("AIzaSyA3GkUvfEQeMptos3NG_yufZ7tvZCenvJk");
    
    // set response language. Defaults to english.
    Geocode.setLanguage("en");
    Geocode.setLocationType("ROOFTOP");
    
    Geocode.fromLatLng(cordinates.lat, cordinates.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        let city, state, country, postalCode,streetName,areaName, buildingNumber;
        for (let i = 0; i < response.results[0].address_components.length; i++) {
          for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
                case "postal_code":
                  postalCode = response.results[0].address_components[i].long_name;
                  break;
                  case "route":
                    streetName = response.results[0].address_components[i].long_name;
                    break;
                    case "sublocality_level_1":
                      areaName = response.results[0].address_components[i].long_name;
                      break;
                      case "premise":
                        buildingNumber = response.results[0].address_components[i].long_name;
                        break;
                  
                  
            }
          }
        }
        // console.log(city, state, country,postalCode, streetName,areaName,buildingNumber);
        // console.log(address);
        // console.log(cordinates.lat)
        // console.log(cordinates.lng)
        const newData = { ...formData };
        newData['pick_up_building_number']= buildingNumber??"";
        newData['pick_up_street_name']= streetName??"";
        newData['pick_up_area_name']= areaName??"";
        newData['pick_up_city']= city??"";
        newData['pick_up_pin_code']= postalCode??"";
        newData["pick_up_lat"] = cordinates.lat??"";
        newData["pick_up_lng"] = cordinates.lng??"";
        setFormData(newData);
      },
      (error) => {
        console.error(error);
      }
    );
    
        const newData = { ...formData };
        newData["pick_up_lat"] = cordinates.lat;
        newData["pick_up_lng"] = cordinates.lng;
        setFormData(newData);
      }
    const handleUploadImage = async (e) => {
        setFlagUloadImage(true);
        setResMsg('')
        setResSuccess(false);
        e.preventDefault();
        UploadFileService.addData(selectedFile, 'profile_image').then((res) => {
            if (res == 'error') {
                setResMsg('Something went wrong')
            } else {
                if (res['status'] == true) { 
                     setResSuccess(true); 
                    if(res['id']!=null)
                    setImageId(res['id']);}
                setResMsg(res['message'])
            }
            handleShow();
            setFlagUloadImage(false);
        });

    
      };

    function handleDeleteClick(id,title,deleteFlag,roleTitle){
                setSelectedSycnId(id);
                setSchoolTitleName(title);
                setDeleteSyncFlag(deleteFlag);
                setRollTitle(roleTitle);
                handleShowCBox();
    }
    function handleDeleteData(){
        handleCloseCBox();
        setLoading(true);
        setResMsg('')
        setResSuccess(false);
            if(deleteSyncFlag==0){
                UserServices.unSyncUser(selectedSyncId).then((res) => {
                    if (res == 'esrror') {
                        setResMsg('Something went wrong')
                    } else {
                        if (res['status'] == true) { setResSuccess(true); 
                            getSyncData();
                        }
                        setResMsg(res['message'])
                    }
                    handleShow();
        
                    setLoading(false);
                });
            }else if(deleteSyncFlag==1){
                SyncServices.unSyncUser(selectedSyncId).then((res) => {
                    if (res == 'esrror') {
                        setResMsg('Something went wrong')
                    } else {
                        if (res['status'] == true) { setResSuccess(true); 
                            getParentSyncData();
                        }
                        setResMsg(res['message'])
                    }
                    handleShow();
        
                    setLoading(false);
                });
            }
  
    }
       function setSelectedTravellerData(data){

    const newData = { ...formData };
    
    newData['pick_up_lat']= data['pick_up_lat']??"";
    newData['pick_up_lng']= data['pick_up_lng']??"";
    newData['pick_up_building_number']= data['pick_up_building_number']??"";
    newData['pick_up_street_name']=data['pick_up_street_name']??"";
    newData['pick_up_area_name']=data['pick_up_area_name']??"";
    newData['pick_up_city']=data['pick_up_city']??"";
    newData['pick_up_pin_code']= data['pick_up_pin_code'] ??"";

    
    newData['drop_building_number']= data['drop_building_number']??"";
    newData['drop_street_name']= data['drop_street_name']??"";
    newData['drop_area_name']= data['drop_area_name']??"";
    newData['drop_city']= data['drop_city']??"";
    newData['drop_pin_code']= data['drop_pin_code']??"";
    newData['drop_lat']=data['drop_lat']??"";
    newData['drop_lng']= data['drop_lng']??"";

    newData['is_pick_up']= 1;
    newData['is_drop']= 1;
    setFormData(newData);

  }
    function handleSubmitModelForm(e) {
        e.preventDefault();
        let isValidPBN=true;
        let isValidPSN=true;
        let isValidPAN=true;
        let isValidPCity=true;
        let isValidPinCode=true;
        let isValidPLat=true;
        let isValidPLng=true;
    
        let isValidDBN=true;
        let isValidDSN=true;
        let isValidDAN=true;
        let isValidDCity=true;
        let isValidDPinCode=true;
        let isValidDLat=true;
        let isValidDLng=true;
     
    
    
        if(formData.is_pick_up){
          const bNValidate=validation.checkTwoLengthValidation(formData.pick_up_building_number);
          if(bNValidate){
            isValidPBN=true;
              setPErrorsBN(null);
          }
              else{
                isValidPBN=false
                setPErrorsBN("Length must be greater than 1 letters");
              
              }
              const snValidate=validation.checkTwoLengthValidation(formData.pick_up_street_name);
              if(snValidate){
                isValidPSN=true;
                  setPErrorsSN(null);
              }
                  else{
                    isValidPSN=false
                    setPErrorsSN("Length must be greater than 2 letters");
                  
                  }
              const anValidate=validation.checkFourLengthValidation(formData.pick_up_area_name);
              if(anValidate){
                isValidPAN=true;
                  setPErrorsAN(null);
              }
                  else{
                    isValidPAN=false
                    setPErrorsAN("Length must be greater than 3 letters");
                  }
                  const cityValidate=validation.checkFourLengthValidation(formData.pick_up_city);
                  if(cityValidate){
                    isValidPCity=true;
                      setPErrorsCity(null);
                  }
                      else{
                        isValidPCity=false
                        setPErrorsCity("Length must be greater than 3 letters");
                      }
                  
                      const pcValidate=validation.checkPinCodeValidation(formData.pick_up_pin_code);
                      if(pcValidate){
                        isValidPinCode=true;
                          setPErrorsPicCode(null);
                      }
                          else{
                            isValidPinCode=false
                            setPErrorsPicCode("Input must be interger and Length must be 6 letters");
                          }
                          const latValidate=validation.checkLatValidation(formData.pick_up_lat);
                          if(latValidate){
                              isValidPLat=true;
                              setErrorsLat(null);
                          }
                              else{
                                isValidPLat=false
                                  setErrorsLat("Enter valid latitude");
                              }
                              const lngValidate=validation.checkLngValidation(formData.pick_up_lng);
                              if(lngValidate){
                                isValidPLng=true;
                                setErrorsLng(null);
                              }
                                  else{
                                      isValidPLng=false
                                      setErrorsLng("Enter valid longitude");
                                  
                                  }
                      
        }
    
         if(formData.is_drop){
          const bNValidate=validation.checkTwoLengthValidation(formData.drop_building_number);
          if(bNValidate){
            isValidDBN=true;
              setDErrorsBN(null);
          }
              else{
                isValidDBN=false
                setDErrorsBN("Length must be greater than 1 letters");
              
              }
         
              const snValidate=validation.checkFourLengthValidation(formData.drop_street_name);
              if(snValidate){
                isValidDSN=true;
                setDErrorsSN(null);
              }
                  else{
                    isValidDSN=false
                    setDErrorsSN("Length must be greater than 3 letters");
                  
                  }
              const anValidate=validation.checkFourLengthValidation(formData.drop_area_name);
              if(anValidate){
                isValidDAN=true;
                  setDErrorsAN(null);
              }
                  else{
                    isValidDAN=false
                    setDErrorsAN("Length must be greater than 3 letters");
                  }
                  const cityValidate=validation.checkFourLengthValidation(formData.drop_city);
                  if(cityValidate){
                    isValidDCity=true;
                      setDErrorsCity(null);
                  }  else{
                        isValidDCity=false
                        setDErrorsCity("Length must be greater than 3 letters");
                      }
    
                      const pcValidate=validation.checkPinCodeValidation(formData.drop_pin_code);
                      if(pcValidate){
                        isValidDPinCode=true;
                        setDErrorsPicCode(null);
                      }
                          else{
                            isValidDPinCode=false
                            setDErrorsPicCode("Input must be interger and Length must be 6 letters");
                          }
                          const latValidate=validation.checkLatValidation(formData.drop_lat);
                          if(latValidate){
                              isValidDLat=true;
                              setErrorsDLat(null);
                          }
                              else{
                                isValidDLat=false
                                setErrorsDLat("Enter valid latitude");
                              }
                              const lngValidate=validation.checkLngValidation(formData.drop_lng);
                              if(lngValidate){
                                isValidDLng=true;
                                setErrorsDLng(null);
                              }
                                  else{
                                    isValidDLng=false
                                      setErrorsDLng("Enter valid longitude");
                                  
                                  }
                      
        }
        if(isValidPBN==true&&isValidPSN==true&&isValidPAN==true&& isValidPCity==true&& isValidPinCode==true&& isValidPLat==true&& isValidPLng==true
          && isValidDBN==true
          && isValidDSN==true
          && isValidDAN==true
          && isValidDCity==true
          && isValidDPinCode==true
          && isValidDLat==true
          && isValidDLng==true
          ){
              setLoading(true);
        setResMsg("");
        setResSuccess(false);

        UserServices.updateUserAddressDetails(
            userID,
          formData.is_pick_up,
          formData.is_drop,
          formData.pick_up_building_number,
          formData.pick_up_street_name,
          formData.pick_up_area_name,
          formData.pick_up_pin_code,
          formData.pick_up_city,
          formData.pick_up_lat,
          formData.pick_up_lng,
          formData.drop_building_number,
          formData.drop_street_name,
          formData.drop_area_name,
          formData.drop_pin_code,
          formData.drop_city,
          formData.drop_lat,
          formData.drop_lng
        ).then((res) => {
          if (res == "error") {
            setResMsg("Something went wrong");
          } else {
            if (res["status"] == true) {
              handleClose();
              setResSuccess(true);
            }
            setResMsg(res["message"]);
      
          }
          handleShow();

          setLoading(false);
        });
          }
    
    
      
      }

    return (
        <div className='UpdateUser'> 
             <Modal
        dialogClassName="map-modal"
        show={showGoogleMapBox}
        onHide={handleCloseGoogleMapBox}
        className="alert-box"
      >
        <Modal.Header closeButton>
          <Modal.Title>Mark Location</Modal.Title>
        </Modal.Header>
        <Modal.Body className="alert-box-body">
        {pickMapForDrop?<GoogleMapComponent 
        schoolLat={schoolLat}
        schoolLng={schoolLng}
        returnCordinates={setMapLatLng} />:<GoogleMapComponent
        schoolLat={schoolLat}
        schoolLng={schoolLng}
        returnCordinates={setMapLatLng} />}  
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleSubmitGoogleMap()}>
            Submit
          </Button>
          <Button variant="secondary" onClick={() => handleCloseGoogleMapBox()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
         <HeaderSubTitle title='Update User'/>
        <div className="Cutso-Form">
        <Modal show={showCBox} onHide={handleCloseCBox} className='alert-box'>
                <Modal.Header closeButton>
                    <Modal.Title className='alert-box-title-error'>Are you Sure!</Modal.Title>
                </Modal.Header>
                <Modal.Body className='alert-box-body'>Are you wure want to unsync {schoolTitleName} role {roleTitleSelect}</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => handleDeleteData()}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={() => handleCloseCBox()}>
                        No
                    </Button>
                    {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={handleClose} className='alert-box'>
                <Modal.Header closeButton>
                    <Modal.Title className={resSuccess == false ? 'alert-box-title-error' : 'alert-box-title'}>Response</Modal.Title>
                </Modal.Header>
                <Modal.Body className='alert-box-body'>{resMsg}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
                </Modal.Footer>
            </Modal>
            <div className='form-section-update'>    
            <Card>
                <Card.Header>Credentials</Card.Header>
                <Card.Body>

                    <Form onSubmit={(e) => handleSubmitCredit(e)}>
                    <Form.Group className="mb-3">
                            <Form.Label>
                                Phone
                            </Form.Label>
                     
                                <Form.Control required onChange={(e) => handleChange(e)} name='phone' id='phone' value={data.phone} type="phone" placeholder="1234566789" className='form-control' />
                       
                        </Form.Group>
                 
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Email
                            </Form.Label>
                          
                                <Form.Control required onChange={(e) => handleChange(e)} name='email' id='email' value={data.email} type="email" placeholder="email@example.com" className='form-control' />
                    
                        </Form.Group>
                        {isLoading == true ? <LoadingCompinentCommon /> :
                            isDataNull == true ? <Button  size="sm"   disabled variant="primary" type="submit">
                                Update
                            </Button
                            > : <Button 
                            disabled={id==userID?false:roleId==3&&(isAdminRole==true||isManagerRole==true)? true:false}
                            size="sm" variant="primary" type="submit">
                                Update
                            </Button>}
                    </Form>

                </Card.Body>
            </Card>
            <br/>
             <Card>
                <Card.Header>Basic Information</Card.Header>
                <Card.Body>
                    <Form onSubmit={(e) => handleSubmit(e)}>
                    <Form.Group className="mb-3">
                    <Form.Check 
                    onChange={(e) => handleChangeSwitch(e)}
                    checked={data.active}
                       name='active'
                        type="switch"
                        id="active"
                        label="Active/Deactive"
                    />
                         </Form.Group>
                        
                        <Form.Group  className="mb-3">
                    <Form.Label >
                        First Name*
                    </Form.Label>
                
                        <Form.Control 
                           isInvalid={!!errorsFName}
                        required onChange={(e) => handleChange(e)} type="text" placeholder="Jhon" name='first_name' id='first_name' value={data.first_name} />
                                        <Form.Control.Feedback type="invalid">{errorsFName}</Form.Control.Feedback>

                </Form.Group>
                <Form.Group  className="mb-3">
                    <Form.Label >
                        Last Name*
                    </Form.Label>
                    
                        <Form.Control
                        isInvalid={!!errorsLName}
                        required onChange={(e) => handleChange(e)} type="text" placeholder="Alexender" name='last_name' id='last_name' value={data.last_name} />
                        <Form.Control.Feedback type="invalid">{errorsLName}</Form.Control.Feedback>

                </Form.Group>
    
                <Form.Group  className="mb-3">
                    <Form.Label>
                        Secondary Phone*
                    </Form.Label>
                   
                        <Form.Control
                         isInvalid={!!errorPhone}
                        onChange={(e) => handleChange(e)} type="text" placeholder="Alexender" name='phone_secondary' id='phone_secondary' value={data.phone_secondary} />
                        <Form.Control.Feedback type="invalid">{errorPhone}</Form.Control.Feedback>

                </Form.Group>
                <Form.Group  className="mb-3">
                    <Form.Label>
                        Address
                    </Form.Label>
                  
                        <Form.Control as="textarea" rows={3} onChange={(e) => handleChange(e)} placeholder='Address' name='address' id='address' value={data.address} />
                   
                </Form.Group>
                        {isLoading == true ? <LoadingCompinentCommon /> :
                            isDataNull == true ? <Button  size="sm"   disabled variant="primary" type="submit">
                                Update
                            </Button
                            > : <Button 
                            disabled={id==userID?false:roleId==3&&(isAdminRole==true||isManagerRole==true)? true:false}
                            size="sm" variant="primary" type="submit">
                                Update
                            </Button>}
                    </Form>
                  
                </Card.Body>
            </Card>
            <br/>
        
            </div>
            <div className='image-box'>
           {
           
           data.image!=null&&data.image!=""?
           <img 
           src={'https://schoob.net/busappapifiles/public/uploads/images/'+data.image}
           alt="new"
           />
            :image==null?
            <div className='image-box-add'> <i className="fa fa-image" aria-hidden="true"></i></div>:
                 <img id="image" src={image}/>} 
             
             {
                isLoading==true?
                <></>:
             isImageUploading==true?
             <>
             <br/>
             <LoadingCompinentCommon />
             </>
             :
            
            image==null&&data.image==null?
             <div className="form-group">
             <label htmlFor="myInput" className='image-operation-btn'>Select Image</label>
              <input id="myInput"type="file" name="file" className="mb-4" 

              style={{display:'none'}}
              onChange={(e) => handleChangeImage(e)}  />
             </div>:
             <>
               <button className='image-operation-btn' onClick={handleRemoveImage}>
                 Remove
              </button>
              {/* <button className='image-operation-btn' onClick={handleUploadImage}>
                 Upload Image
              </button> */}
              </>
              }
           </div>
        </div>
        <div>
                <Card>
            <Card.Header>Address</Card.Header>
                <Card.Body>

             <Form className="Modal-Form" onSubmit={(e) => handleSubmitModelForm(e)}>
            <Row>
              <Col>
              <Form.Group className="mb-3">
              <Form.Check
                checked={formData.is_pick_up}
                onChange={(e) => handleChangeModelForm(e)}
                key="is_pick_up"
                type="checkbox"
                id="is_pick_up"
                label={"Pick Up"}
              />
            </Form.Group>
            <Button className="map-btn" disabled={!formData.is_pick_up} onClick={()=>handleShowGoogleMapBox(false)}>Pick From Map</Button>
            <br/>
            <br/>
            <Row className="mb-3">
                <Form.Group  as={Col}>
                  <Form.Label>Latitude*</Form.Label>
                  <Form.Control
                       isInvalid={!!pErrorsLat}
                    required
                    disabled={formData.is_pick_up ? false : true}
                    onChange={(e) => handleChangeModelForm(e) }
                    type="text"
                    placeholder=""
                    name="pick_up_lat"
                    id="pick_up_lat"
                    value={formData.pick_up_lat}
                  />
                    <Form.Control.Feedback type="invalid">{pErrorsLat}</Form.Control.Feedback>
                </Form.Group>{" "}
     
              <Form.Group  as={Col}>
              <Form.Label>Longitude *</Form.Label>
              <Form.Control
                isInvalid={!!pErrorsLng}
                required
                disabled={formData.is_pick_up ? false : true}
                onChange={(e) => handleChangeModelForm(e)}
                type="text"
                placeholder=""
                name="pick_up_lng"
                id="pick_up_lng"
                value={formData.pick_up_lng}
              />
              <Form.Control.Feedback type="invalid">{pErrorsLng}</Form.Control.Feedback>

            </Form.Group >
           
            </Row >
            <Row className="mb-3">
                <Form.Group  as={Col}>
                  <Form.Label>Building Number*</Form.Label>
                  <Form.Control
                   isInvalid={!!pErrorsBN}
                    required
                    disabled={formData.is_pick_up ? false : true}
                    onChange={(e) => handleChangeModelForm(e)}
                    type="text"
                    placeholder="Building Number"
                    name="pick_up_building_number"
                    id="pick_up_building_number"
                    value={formData.pick_up_building_number}
                  />
             <Form.Control.Feedback type="invalid">{pErrorsBN}</Form.Control.Feedback>

                </Form.Group>{" "}
     
              <Form.Group  as={Col}>
              <Form.Label>Streent Name*</Form.Label>
              <Form.Control
                isInvalid={!!pErrorsSN}
                required
                disabled={formData.is_pick_up ? false : true}
                onChange={(e) => handleChangeModelForm(e)}
                type="text"
                placeholder="Street Name"
                name="pick_up_street_name"
                id="pick_up_street_name"
                value={formData.pick_up_street_name}
              />
               <Form.Control.Feedback type="invalid">{pErrorsSN}</Form.Control.Feedback>

            </Form.Group >
           
            </Row >
            <Row className="mb-3">
        
            <Form.Group  as={Col} >
              <Form.Label>Area Name*</Form.Label>
              <Form.Control
                isInvalid={!!pErrorsAN}
                required
                disabled={formData.is_pick_up ? false : true}
                onChange={(e) => handleChangeModelForm(e)}
                type="text"
                placeholder="Area"
                name="pick_up_area_name"
                id="pick_up_area_name"
                value={formData.pick_up_area_name}
                />
              <Form.Control.Feedback type="invalid">{pErrorsAN}</Form.Control.Feedback>
            </Form.Group> 
            <Form.Group as={Col}>
              <Form.Label>City*</Form.Label>
              <Form.Control
               isInvalid={!!pErrorsCity}
                required
                disabled={formData.is_pick_up ? false : true}
                onChange={(e) => handleChangeModelForm(e)}
                type="text"
                placeholder="City"
                name="pick_up_city"
                id="pick_up_city"
                value={formData.pick_up_city}
              />
               <Form.Control.Feedback type="invalid">{pErrorsCity}</Form.Control.Feedback>
            </Form.Group>
            </Row>
            <Row className="mb-3">
            <Form.Group  as={Col} >
              <Form.Label>Pin Code*</Form.Label>
              <Form.Control
                isInvalid={!!pErrorsPicCode}
                required
                disabled={formData.is_pick_up ? false : true}
                onChange={(e) => handleChangeModelForm(e)       }
                type="text"
                placeholder="Pin Code"
                name="pick_up_pin_code"
                id="pick_up_pin_code"
                value={formData.pick_up_pin_code}
                />
                 <Form.Control.Feedback type="invalid">{pErrorsPicCode}</Form.Control.Feedback>
            </Form.Group>
      
            </Row>
              </Col>
              <Col sm={1}>
              <div className="verical-divider">
                <div className="line"></div>
              </div>
              </Col>
              <Col>
                <Row>
              <Form.Group  as={Col}>
              <Form.Check
                checked={formData.is_drop}
                onChange={(e) => handleChangeModelForm(e)}
                key="is_drop"
                type="checkbox"
                id="is_drop"
                label="Drop"
                />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Check
                  disabled={!formData.is_drop}
                checked={sameAddFlag}
                 onChange={(e) => handleChangeSAFlag(e)}
                key="same_add_flag"
                type="checkbox"
                id="same_add_flag"
                label="Same As Pick Up"
                />
            </Form.Group>
                </Row>
            
            <Button className="map-btn"  disabled={!formData.is_drop} onClick={()=>handleShowGoogleMapBox(true)}>Pick From Map</Button>
            <br/>
            <br/>
              <Row className="mb-3">
          <Form.Group  as={Col}>
            <Form.Label>Latitude*</Form.Label>
            <Form.Control
                isInvalid={!!dErrorsLat}
              required
              disabled={formData.is_drop ? false : true}
              onChange={(e) => handleChangeModelForm(e)}
              type="text"
              placeholder=""
              name="drop_lat"
              id="drop_lat"
              value={formData.drop_lat}
            />
            <Form.Control.Feedback type="invalid">{dErrorsLat}</Form.Control.Feedback>

          </Form.Group>{" "}

        <Form.Group  as={Col}>
        <Form.Label>Longitude *</Form.Label>
        <Form.Control
                isInvalid={!!dErrorsLng}
          required
          disabled={formData.is_drop ? false : true}
          onChange={(e) => handleChangeModelForm(e)}
          type="text"
          placeholder=""
          name="drop_lng"
          id="drop_lng"
          value={formData.drop_lng}
        />
          <Form.Control.Feedback type="invalid">{dErrorsLng}</Form.Control.Feedback>
      </Form.Group >
     
      </Row >
            <Row className="mb-3">
                <Form.Group  as={Col}>
                  <Form.Label>Building Number*</Form.Label>
                  <Form.Control
                          isInvalid={!!dErrorsBN}
                    required
                    disabled={formData.is_drop ? false : true}
                    onChange={(e) => handleChangeModelForm(e)}
                    type="text"
                    placeholder="Building Number"
                    name="drop_building_number"
                    id="drop_building_number"
                    value={formData.drop_building_number}
                  />
                            <Form.Control.Feedback type="invalid">{dErrorsBN}</Form.Control.Feedback>

                </Form.Group>
     
              <Form.Group  as={Col}>
              <Form.Label>Streent Name*</Form.Label>
              <Form.Control
                   isInvalid={!!dErrorsSN}
                required
                disabled={formData.is_drop ? false : true}
                onChange={(e) => handleChangeModelForm(e)}
                type="text"
                placeholder="Street Name"
                name="drop_street_name"
                id="drop_street_name"
                value={formData.drop_street_name}
              />
            <Form.Control.Feedback type="invalid">{dErrorsSN}</Form.Control.Feedback>

            </Form.Group >
           
            </Row >
            <Row className="mb-3">
            <Form.Group  as={Col} >
              <Form.Label>Area Name*</Form.Label>
              <Form.Control
                  isInvalid={!!dErrorsAN}
                required
                disabled={formData.is_drop ? false : true}
                onChange={(e) => handleChangeModelForm(e)}
                type="text"
                placeholder="Area"
                name="drop_area_name"
                id="drop_area_name"
                value={formData.drop_area_name}
                />
                  <Form.Control.Feedback type="invalid">{dErrorsAN}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>City*</Form.Label>
              <Form.Control
                isInvalid={!!dErrorsCity}
                required
                disabled={formData.is_drop ? false : true}
                onChange={(e) => handleChangeModelForm(e)}
                type="text"
                placeholder="City"
                name="drop_city"
                id="drop_city"
                value={formData.drop_city}
              />
              <Form.Control.Feedback type="invalid">{dErrorsCity}</Form.Control.Feedback>
            </Form.Group>
            </Row>

            <Row className="mb-3">
            <Form.Group  as={Col} >
              <Form.Label>Pin Code*</Form.Label>
              <Form.Control
                 isInvalid={!!dErrorsPicCode}
                required
                disabled={formData.is_drop ? false : true}
                onChange={(e) => handleChangeModelForm(e)       }
                type="text"
                placeholder="Area"
                name="drop_pin_code"
                id="drop_pin_code"
                value={formData.drop_pin_code}
                />
                <Form.Control.Feedback type="invalid">{dErrorsPicCode}</Form.Control.Feedback>

            </Form.Group>
      
            </Row>
              </Col>
            </Row>
            <br/>
            <Button variant="primary" size="sm" type="submit">
              Save Changes
            </Button>
          </Form>
          </Card.Body>
                </Card>
                <br/>
              <Card>
                <Card.Header className='sync-card'>Sync With School And Role</Card.Header>
                <Card.Body>
                <Form>
                <Form.Group className="mb-3">
                    <Form.Label className='form-label' >
                        School*
                    </Form.Label>
                    <Select
                   isDisabled={roleId==1?false:true}
                    defaultValue={schoolId=="null"||schoolTitle=="null"||schoolId==null||schoolTitle==null?null:{"value":schoolId,"label":schoolTitle}}
                    onChange={setselectedSchoolIdForU}
                    options={schoolOPData}
                    />
                          
                 
                </Form.Group>
                <Form.Group className="mb-3">
                            <Form.Label className='form-label'>
                                Role
                            </Form.Label>
                            <Select
                    defaultValue={selectedRoolId}
                    onChange={setselectedRoolId}
                    options={userTypes}
                    />
                        
                        </Form.Group>
                {isLoading == true ? <LoadingCompinentCommon /> :
                            <Button  size="sm" onClick={()=>handleSubmitSync()} disabled={selectedSchoolIdForU==null||selectedRoolId==null?true:false} variant="primary">
                                Update
                            </Button>
                             }
              
    
            </Form>

                </Card.Body>
            </Card>
            <Table striped className='body-table'>
        <thead>
          <tr>
            <th>#</th>
            <th>School Name</th>
            <th>Role</th>
            <th>Sync TimeStamp</th>
            <th>Option</th>
          </tr>
        </thead>
        <tbody>
          {syncData.length==0?'No Data Found':
          syncData&&
          syncData.map((p,index) => {
              
              let dateAndTimeC='';
              let dateAndTimeU='';
  
                 dateAndTimeC=moment(p.created_at).utc().format('YYYY-MM-DD hh:mm:ss')
                 dateAndTimeU=moment(p.updated_at).utc().format('YYYY-MM-DD hh:mm:ss')
          
                  return (
                    <tr key={p.id}>
                    <td>{index+1}</td>
                    <td>{p.school_title}</td>
                    <td>{p.role_title}</td>
                    <td>{dateAndTimeC}</td>
                    <td>
                      <button onClick={()=>{
                        if(roleId==1){
                            if(p.role_id==2)
                            handleDeleteClick(p.assign_id,p.title,0,p.role_title,p.role_title)
                            else  alert("Can't update")
                        }
                        if(roleId==2){
                            if(p.role_id!=2)
                            handleDeleteClick(p.assign_id,p.title,0,p.role_title,p.role_title)
                            else  alert("Can't update")
                        }
                        if(roleId==3){
                            if(p.role_id!=2&&p.role_id!=3)
                            handleDeleteClick(p.assign_id,p.title,0,p.role_title,p.role_title)
                            else  alert("Can't update")
                        }
            
         
                      }
                        
                        
                        } className='btn btn-delete'> <i className="fa fa-trash fa-sm" aria-hidden="true"></i></button >{' '}
                    </td> 
                  </tr>
                  );
                  })}
                  

        </tbody>
      </Table>
            <br/>
            {roleId!=1&&isShowParentSync?
            <Card>
                <Card.Header className='sync-card'>Sync With Student</Card.Header>
                <Card.Body>
                
                <Form>
                <Form.Group className="mb-3">
                    <Form.Label className='form-label' >
                        School*
                    </Form.Label>
                   
                    <Select
                 isDisabled={roleId==1?false:true}
      
                 defaultValue={schoolId=="null"||schoolTitle=="null"||schoolId==null||schoolTitle==null?null:{"value":schoolId,"label":schoolTitle}}

                    onChange={(e) => handleChangePOP(e)}
                    options={schoolOPData}
                    />
                    <br/>
                       <Form.Label className='form-label' >
                        Students*
                    </Form.Label>
                         
                          <Select
                    value={selectedUsersOPData}
                    defaultValue={selectedUsersOPData}
                    onChange={setselectedUsersOPData}
                    options={schoolUsersOPData}
                    />
                
                 
                 
                </Form.Group>
                {isLoading == true ? <LoadingCompinentCommon /> :
                            <Button  size="sm" onClick={()=>handleSubmitSyncP()} disabled={selectedUsersOPData==null?true:false} variant="primary">
                                Update
                            </Button> }
              
    
            </Form>

                </Card.Body>
            </Card>:
            <></>}
         {roleId!=1&&isShowParentSync==true?  
           <Table striped className='body-table'>
        <thead>
          <tr>
            <th>#</th>
            <th>School Name</th>
            <th>Student Name</th>
            <th>Sync TimeStamp</th>
            <th>Option</th>
          </tr>
        </thead>
        <tbody>
          {syncParentData.length==0?'No Data Found':
          syncParentData&&
          syncParentData.map((p,index) => {
              
              let dateAndTimeC='';
              let dateAndTimeU='';
  
                 dateAndTimeC=moment(p.created_at).utc().format('YYYY-MM-DD hh:mm:ss')
                 dateAndTimeU=moment(p.updated_at).utc().format('YYYY-MM-DD hh:mm:ss')
          
                  return (
                    <tr key={p.id}>
                    <td>{index+1}</td>
                    <td>{p.title}</td>
                    <td>{p.first_name} {p.last_name}</td>
                    <td>{dateAndTimeC}</td>
                    <td>
                      <button onClick={()=>{
                        if(roleId==2||roleId==3){
                            handleDeleteClick(p.id,p.first_name +" "+ p.first_name,1,"")
                        }else{alert("can't update")}
                        
                        }} className='btn btn-delete'> <i className="fa fa-trash fa-sm" aria-hidden="true"></i></button >{' '}
                    </td> 
                  </tr>
                  );
                  })}
                  
        </tbody>
      </Table>:<>
     { isShowStudentSync==true? <Card>
                <Card.Header className='sync-card'>Sync With Parent</Card.Header>
                </Card>:<></>}
    { isShowStudentSync==true?<Table striped className='body-table'>
        <thead>
          <tr>
            <th>#</th>
            <th>School Name</th>
            <th>Parent Name</th>
            <th>Sync TimeStamp</th>
            <th>Option</th>
          </tr>
        </thead>
        <tbody>
          {syncStudentData.length==0?'No Data Found':
          syncStudentData&&
          syncStudentData.map((p,index) => {
              
              let dateAndTimeC='';
              let dateAndTimeU='';
  
                 dateAndTimeC=moment(p.created_at).utc().format('YYYY-MM-DD hh:mm:ss')
                 dateAndTimeU=moment(p.updated_at).utc().format('YYYY-MM-DD hh:mm:ss')
          
                  return (
                    <tr key={p.id}>
                    <td>{index+1}</td>
                    <td>{p.title}</td>
                    <td>{p.first_name} {p.last_name}</td>
                    <td>{dateAndTimeC}</td>
                    {/* <td>
                      <button onClick={()=>{
                        if(roleId==2||roleId==3){
                            handleDeleteClick(p.id,p.first_name +" "+ p.first_name,1,"")
                        }else{alert("can't update")}
                        
                        }} className='btn btn-delete'> <i className="fa fa-trash fa-sm" aria-hidden="true"></i></button >{' '}
                    </td>  */}
                  </tr>
                  );
                  })}
                  
        </tbody>
      </Table>:<></>}
      </>}
      <br/>
 
        </div>
        </div>
    );

}

export default UpdateUsers;