import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./tableOperation.css"

function TabelOperation({navigation,handleSearchChanges,exportDataAsCSC,handlePerPageSelect}){
  const roleId=localStorage.getItem('roleId');
  const onChanges=(e)=>{
    handleSearchChanges(e);
  }
  const onChangesPerPage=(e)=>{
    handlePerPageSelect(e);
  }
  
    return(
        <div className='tabel-operation'>
            <section className="left-section">
          <input
          onChange={onChanges}
          type='text' placeholder='Search.... '>
          </input>
          <button className='btn-search'>
            <i className="fa fa-search fa-sm" aria-hidden="true" />
          </button>
      
        <section className="export">
      <Button 
      onClick={exportDataAsCSC}
      variant="outline-secondary">Export</Button>{' '}
      {/* <Button variant="outline-secondary">PDF</Button>{' '}
      <Button variant="outline-secondary">Word</Button>{' '} */}
</section>
<select className="slection-box"
onChange={onChangesPerPage}
>
  <option value={'10'}>10</option>
  <option value={'12'}>12</option>
  <option value={'25'}>25</option>
  <option value={'50'}>50</option>
  <option value={'100'}>100</option>
</select>
</section>

  <Link 
to={navigation} style={{ textDecoration: 'none' }}>
<button className='button-add' >+ Add</button>
</Link>



      </div>
    );
}
export default TabelOperation;