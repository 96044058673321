import "../header/header.css";
function Header({handleLOgOut}) {
    return (
      <div className="Header">
        <span className="title">
          Admin Panel
        </span>
        <ul>
                <li
                onClick={() => handleLOgOut()}
                
                >
                  <i className="fa fa-sign-out" aria-hidden="true"></i>
                </li>
                <li>
                  <i className="fa fa-bell"></i>
                </li>
                <li>
                  <i className="fa fa-user"></i>
                </li>
              </ul>
  
      </div>
    );
  }
  
  export default Header;
  