import { Row, Col, Form, Button, Dropdown, Modal, Card, Table } from "react-bootstrap";
import api_url from "../../my_config";
import TimePicker from 'react-time-picker';
import "../../common/custoForm.css";
import { useEffect, useState } from "react";
import LoadingCompinentCommon from "../../common/commonLoading";
import schoolServices from "../../services/schoolServices";
import { useNavigate, useParams } from "react-router-dom";
import cityService from "../../services/cityService";
import { useLocation } from "react-router-dom";
import UploadFileService from "../../services/uploadFile";
import HeaderSubTitle from "../../header/headerSubTitle";
import busesService from "../../services/busesService";
import AssignTripBusesServices from "../../services/assignBusTripService";

import TripService from "../../services/tripService";
import { getAllByDisplayValue } from "@testing-library/react";
import TripTraveler from "./tripTraveler";
import TripEmp from "./tripEmp";
import TripDetails from "./tripDetails";
import TripDetailsDrop from "./tripDeatilsDrop";
import Select from 'react-select';
import moment from "moment";
import validation from "../../helper/validation";

function UpdateTrip() {
  const [errorsTtile, setErrorsTtile] = useState(null);
  const [errorsBus, setErrorsBus] = useState(null);
  const [errorsPickUpTime, setErrorsPickUpTime] = useState(null);
  const [errorsDropTime, setErrorsDropTime] = useState(null);
  
    const roleId=localStorage.getItem('roleId');
    const schoolId=localStorage.getItem('schoolId');
  const { ID } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({
    school_id: "",
    pick_up_bus_id: "",
    image_id: null,
    image: null,
  });
  const [selctedPickUpDays, setSelectedPickUpDays] = useState([]);
  const [selctedDropDays, setSelectedDropDays] = useState([]);
  const [image, setImage] = useState(null);
  const [imageId, setImageId] = useState(null);
  const [isImageUploading, setFlagUloadImage] = useState(false);
  const [isDropDownL, setIsDropDownL] = useState(true);
  
  const [selectedFile, setSelectedFile] = useState(null);
  const [allDay, setAllDay] = useState([
    {
      id: "1",
      title: "Monday",
    },
    {
      id: "2",
      title: "Tuesday",
    },
    {
      id: "3",
      title: "Wednesday",
    },
    {
      id: "4",
      title: "Thrusday",
    },
    {
      id: "5",
      title: "Friday",
    },
    {
      id: "6",
      title: "Saturday",
    },
    {
      id: "7",
      title: "Sunday",
    },
  ]);
  const [isLoading, setLoading] = useState(false);
  const [resSuccess, setResSuccess] = useState(false);
  const [resMsg, setResMsg] = useState("");
  const [show, setShow] = useState(false);
  const [school, setSchool] = useState([]);
  const [buses, setBuses] = useState([]);
  const [isDataNull, setisDataNull] = useState(false);
  const [selectedBusOPData,setselectedBusOPData] = useState(null);
  const [syncBusData,setSyncBusData] = useState([]);
  const [defualtBusId,setDefualtBusId] = useState(null);
  const [defualtBusTtitle,setDefualtBusTitle] = useState(null);
 
  const [busOPData,setBusOPData] = useState([]);
  const [showCBox, setShowCBox] = useState(false);
  const [selectedSyncId,setSelectedSycnId]=useState(null);
  const [schoolTitleName,setSchoolTitleName]=useState(null);
  const [valueTime, onChangeTime] = useState('10:00');
  function handleClose() {
    setShow(false);
    // if (resSuccess == true)
    //     navigate(-1)
  }
  const handleShow = () => setShow(true);
  function getAndSetBuses(school_id) {
    setLoading(true);
    busesService.getDataBySchooId(school_id).then((data) => {
      setBuses(data);
      setLoading(false);
    });
  }
  function getSChool() {
    setLoading(true);
    schoolServices.getData().then((data) => {
      setSchool(data);
    });

   busesService.getDataBySchooId(schoolId).then((data) => {
    const dataArr=[];
    data.map((e) => {
     
        dataArr.push({value: e.id, label: e.title});

    });
    setBusOPData([...dataArr])
    });
    busesService.getDataById(data.pick_up_bus_id).then((data) => {
      setBuses(data);
    });

    setTripData();
    
    setLoading(false);
  }
  function setTripData(){
    TripService.getDataById(ID).then((data) => {
      // setLoading(true);
      if (data == null) setisDataNull(true);
      else {
        setData({
          title: data["title"],
          school_id: data["school_id"],
          school_title: data["school_title"],
          lat: data["lat"],
          lng: data["lng"],
          is_pick_up: data["is_pick_up"],
          pick_up_start_time: data["pick_up_start_time"],
          pick_up_days: data["pick_up_days"],
          pick_up_bus_id: data["pick_up_bus_id"],
          is_drop: data["is_drop"],
          drop_start_time: data["drop_start_time"],
          drop_days: data["drop_days"],
          drop_bus_id: data["drop_bus_id"],
          image_id: data["image_id"],
          image: data["image"],
          bus_id:data["bus"].length>0?data["bus"][0]['bus_id']:null,
          bus_title:data["bus"].length>0?data["bus"][0]['title']:null,
          assign_id:data["bus"].length>0?data["bus"][0]['id']:null,

        });
        const busId=data["bus"].length>0?data["bus"][0]['bus_id']:null;
        const busTitle=data["bus"].length>0?data["bus"][0]['title']:null
         setIsDropDownL(true);
        setDefualtBusId(busId);
        setDefualtBusTitle(busTitle)
        setIsDropDownL(false);
        setSelectedPickUpDays(data["pick_up_days"].split(","));
        setSelectedDropDays(data["drop_days"].split(","));
      }
    });
  }
  function getAssignedBuses() {
    setLoading(true);
    AssignTripBusesServices.getDataByTripId(ID).then((data) => {
        setSyncBusData(data);
    });
    setLoading(false);
  }
  useEffect(() => {
    getSChool();
    getAssignedBuses();
  }, []);
  function handleSubmit(e) {

    let isValidTite=false;
    
    let isValidBus=false;
    // let isValidPickUpTime=false;
    // let isValidDropGTime=false;
     e.preventDefault();
     const titleValidate=validation.checkNameValidation(data.title);
     if(titleValidate){
      isValidTite=true;
      setErrorsTtile(null);
  }
      else{
          isValidTite=false
          setErrorsTtile("Length must be greater than 3 letters");

      }
      if(data.bus_id=="null"||data.bus_id==null){
        isValidBus=false;
        setErrorsBus("Select Bus")
      }else{
        isValidBus=true;
        setErrorsBus(null)
      }
      console.log("--------=================",data.bus_id)
  
  //     const isValidPickUpTimeValid=validation.checkValidateHhMm(data.pick_up_start_time);
  //     if(isValidPickUpTimeValid){
  //       isValidPickUpTime=true;
  //      setErrorsPickUpTime(null);
  //  }
  //      else{
  //       isValidPickUpTime=false
  //       setErrorsPickUpTime("Time Format Should Be HH:MM Ex 08:00");
 
  //      }
  //      const isValidDropTimeValid=validation.checkValidateHhMm(data.drop_start_time);
  //      if(isValidDropTimeValid){
  //        isValidDropGTime=true;
  //       setErrorsDropTime(null);
  //   }
  //       else{
  //         isValidDropGTime=false
  //         setErrorsDropTime("Time Format Should Be HH:MM Ex 08:00");
  
  //       }
     if(isValidTite==true&&isValidBus==true){
     setLoading(true);
    setResMsg("");
    setResSuccess(false);


    if (selectedFile != null) {
      console.log("case 1");
      TripService.updateData(
        ID,
        data.school_id,
        data.title,
        selectedFile,
        data.image_id,
        data.pick_up_start_time,
        data.drop_start_time,
        data.bus_id,
        data.assign_id
  
      ).then((res) => {
       
        if (res == "error") {
          setResMsg("Something went wrong");
        } else {
          if (res["status"] == true) {
            
    
            setTripData()
            setResSuccess(true);
          }
          setResMsg(res["message"]);
        }
        handleShow();

        setLoading(false);
      });
    } else if (selectedFile == null) {
      if (data.image_id == null) {
        console.log("case 2");
        TripService.updateData(
          ID,
          data.school_id,
          data.title,
          selectedFile,
          data.image_id,
          data.pick_up_start_time,
          data.drop_start_time,
          data.bus_id,
        data.assign_id
        ).then((res) => {
          if (res == "error") {
            setResMsg("Something went wrong");
          } else {
            if (res["status"] == true) {
              setTripData();
              setResSuccess(true);
            }
            setResMsg(res["message"]);
          }
          handleShow();

          setLoading(false);
        });
      } else if (data.image_id != null && data.image != null) {
        console.log("case 3");
        TripService.updateData(
          ID,
          data.school_id,
          data.title,
          selectedFile,
          null,
          data.pick_up_start_time,
          data.drop_start_time,
          data.bus_id,
          data.assign_id
        ).then((res) => {
          if (res == "error") {
            setResMsg("Something went wrong");
          } else {
            if (res["status"] == true) {
              setTripData();
              setResSuccess(true);
            }
            setResMsg(res["message"]);
          }
          handleShow();

          setLoading(false);
        });
      } else if (data.image_id != null && data.image == null) {
        console.log("case 4");

        TripService.updateData(
          ID,
          data.school_id,
          data.title,
          selectedFile,
          data.image_id,
          data.pick_up_start_time,
          data.drop_start_time,
          data.bus_id,
          data.assign_id
        ).then((res) => {
          if (res == "error") {
            setResMsg("Something went wrong");
          } else {
            if (res["status"] == true) {
              setTripData();
              setResSuccess(true);
            }
            setResMsg(res["message"]);
          }
          handleShow();

          setLoading(false);
        });
      }
    }
     }
     
   
  }

  function handleRemoveImage() {
    setImage(null);
    setImageId(null);
    setSelectedFile(null);
    const newData = { ...data };
    newData["image"] = null;
    setData(newData);
    console.log(newData);
  }

  function handleChangeImage(event) {
    if (event.target.files && event.target.files[0]) {
      if (!event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i))
        alert("not an image");
      else {
        let file_size = event.target.files[0].size;
        if (file_size > 0 && file_size < 512000) {
          const newData = { ...data };
          newData["image"] = null;
          setData(newData);
          setSelectedFile(event.target.files[0]);
          setImage(URL.createObjectURL(event.target.files[0]));
        } else {
          alert("File Size must be less then 500kb");
          // alert(file_size);
        }
      }
    }
  }
  function handleChange(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  }
  function handleChangeDays(e) {
    if (selctedPickUpDays.includes(e.target.id) == false) {
      setSelectedPickUpDays((selctedPickUpDays) => [
        ...selctedPickUpDays,
        e.target.id,
      ]);
    } else {
      setSelectedPickUpDays((selctedPickUpDays) =>
        selctedPickUpDays.filter((id) => {
          return id !== e.target.id;
        })
      );
    }
  }
  function handleChangeDropDays(e) {
    if (selctedDropDays.includes(e.target.id) == false) {
      setSelectedDropDays((selctedDropDays) => [
        ...selctedDropDays,
        e.target.id,
      ]);
    } else {
      setSelectedDropDays((selctedDropDays) =>
        selctedDropDays.filter((id) => {
          return id !== e.target.id;
        })
      );
    }
  }
  function handleChangeSChool(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;

    setData(newData);
    getAndSetBuses(e.target.value);
  }
  function  handleSubmitSyncP() {
    setLoading(true);
    setResMsg('')
    setResSuccess(false);
    AssignTripBusesServices.addData(ID, selectedBusOPData.value).then((res) => {
        if (res == 'error') {
            setResMsg('Something went wrong')
        } else {
            if (res['status'] == true) { setResSuccess(true); 
                getAssignedBuses();
            }
            setResMsg(res['message'])
        }
        handleShow();

        setLoading(false);
    });
}
function handleCloseCBox() {
    setShowCBox(false);
    // if (resSuccess == true)
    //     navigate(-1)
}
const handleShowCBox = () => setShowCBox(true);

function handleDeleteClick(id,title,deleteFlag,roleTitle){
    setSelectedSycnId(id);
    setSchoolTitleName(title);
    handleShowCBox();
}
function handleDeleteData(){
    handleCloseCBox();
    setLoading(true);
    setResMsg('')
    setResSuccess(false);
    
            AssignTripBusesServices.delete(selectedSyncId).then((res) => {
                if (res == 'esrror') {
                    setResMsg('Something went wrong')
                } else {
                    if (res['status'] == true) { setResSuccess(true); 
                        getAssignedBuses();
                    }
                    setResMsg(res['message'])
                }
                handleShow();
    
                setLoading(false);
            });
        

}
    
function handleChangeBus(e) {
  const newData = { ...data }
  newData["bus_id"] = e.value
  setData(newData);
  console.log(data);
}

  return (
    <div className="UpdateTrip">
      <HeaderSubTitle title="Update Trip" />
      <br />
      <br />
      <div className="Cutso-Form">
      <Modal show={showCBox} onHide={handleCloseCBox} className='alert-box'>
                <Modal.Header closeButton>
                    <Modal.Title className='alert-box-title-error'>Are you Sure!</Modal.Title>
                </Modal.Header>
                <Modal.Body className='alert-box-body'>Are you wure want to delete {schoolTitleName}</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => handleDeleteData()}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={() => handleCloseCBox()}>
                        No
                    </Button>
                    {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
                </Modal.Footer>
            </Modal>
        <Modal show={show} onHide={handleClose} className="alert-box">
          <Modal.Header closeButton>
            <Modal.Title
              className={
                resSuccess == false
                  ? "alert-box-title-error"
                  : "alert-box-title"
              }
            >
              Response
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="alert-box-body">{resMsg}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleClose()}>
              Close
            </Button>
            {/* <Button variant="primary" onClick={handleClose}>
Save Changes
</Button> */}
          </Modal.Footer>
        </Modal>
        <div className="form-section-update">
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Card>
              <Card.Header>Basic</Card.Header>
              <Card.Body>
                {/* <Form.Group className="mb-3">
                  <Form.Label className="form-label">School*</Form.Label>
                  <Form.Control
                    disabled={true}
                    required
                    as="select"
                    onChange={(e) => handleChangeSChool(e)}
                    value={data.school_id}
                    id="school_id"
                    name="school_id"
                  >
                    <option key={"empty"} value={""}>
                      ...
                    </option>
                    {school &&
                      school.map((p) => {
                        return (
                          <option key={p.id} value={p.id}>
                            {p.title} - {p.city_name}
                          </option>
                        );
                      })}
                  </Form.Control>
                </Form.Group> */}

                <Form.Group className="mb-3">
                  <Form.Label>Title*</Form.Label>

                  <Form.Control
                     isInvalid={!!errorsTtile}
                    required
                    onChange={(e) => handleChange(e)}
                    type="text"
                    placeholder="School Name"
                    name="title"
                    id="title"
                    value={data.title}
                  />
              <Form.Control.Feedback type="invalid">{errorsTtile}</Form.Control.Feedback>

                </Form.Group>
                {/* <Form.Group className="mb-3">
                  <Form.Label>Pick Up Start Time*</Form.Label>

                  <Form.Control
                    isInvalid={!!errorsPickUpTime}
                    required
                    onChange={(e) => handleChange(e)}
                    type="text"
                    placeholder="07:00"
                    name="pick_up_start_time"
                    id="pick_up_start_time"
                    value={data.pick_up_start_time}
                  />
                <Form.Control.Feedback type="invalid">{errorsPickUpTime}</Form.Control.Feedback>

                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Drop Start Time*</Form.Label>
                  <Form.Control
                    isInvalid={!!errorsDropTime}
                    required
                    onChange={(e) => handleChange(e)}
                    type="text"
                    placeholder="13:00"
                    name="drop_start_time"
                    id="drop_start_time"
                    value={data.drop_start_time}
                  />
                 <Form.Control.Feedback type="invalid">{errorsDropTime}</Form.Control.Feedback>
         
              
                </Form.Group> */}
                {isDropDownL == true ?<></>:<Form.Group className="mb-3">
                    <Form.Label className='form-label' >
                        Bus*
                    </Form.Label>
                    <Select
        
                   defaultValue={defualtBusId==null||defualtBusId=="null"||defualtBusTtitle==null||defualtBusTtitle=="null"?null:{"value":defualtBusId,"label":defualtBusTtitle}}
                   onChange={handleChangeBus}
                    options={busOPData}
                    />
                    <div className="error-text">
                    {errorsBus}
                    </div>
                
    
                <Form.Control.Feedback type="invalid">{errorsBus}</Form.Control.Feedback>  
                </Form.Group>}
                

                <br />

                {isLoading == true ? (
                  <LoadingCompinentCommon />
                ) : isDataNull == true ? (
                  <Button size="sm" disabled variant="primary" type="submit">
                    Update
                  </Button>
                ) : (
                  <Button size="sm" variant="primary" type="submit">
                    Update
                  </Button>
                )}
              </Card.Body>
            </Card>
          </Form>
        </div>
        <div className="image-box">
          {data.image != null && data.image != "" ? (
            <img
              src={
                "https://schoob.net/busappapifiles/public/uploads/images/" +
                data.image
              }
              alt="new"
            />
          ) : image == null ? (
            <div className="image-box-add">
              {" "}
              <i className="fa fa-image" aria-hidden="true"></i>
            </div>
          ) : (
            <img id="image" src={image} />
          )}

          {isLoading == true ? 
          (
            <></>
          ) : isImageUploading == true ? (
            <>
              <br />
              <LoadingCompinentCommon />
            </>
          ) : image == null && data.image == null ? (
            <div className="form-group">
              <label htmlFor="myInput" className="image-operation-btn">
                Select Image
              </label>
              <input
                id="myInput"
                type="file"
                name="file"
                className="mb-4"
                style={{ display: "none" }}
                onChange={(e) => handleChangeImage(e)}
              />
            </div>
          ) : (
            <>
              <button
                className="image-operation-btn"
                onClick={handleRemoveImage}
              >
                Remove
              </button>
              {/* <button className='image-operation-btn' onClick={handleUploadImage}>
     Upload Image
  </button> */}
            </>
          )}
        </div>
      </div>
         {/* <Card>
                <Card.Header className='sync-card'>Buses</Card.Header>
                <Card.Body>
                <Form>
                <Form.Group className="mb-3">
                          <Select
                    value={selectedBusOPData}
                    defaultValue={selectedBusOPData}
                    onChange={setselectedBusOPData}
                    options={busOPData}
                    />
                 
                </Form.Group>
                {isLoading == true ? <LoadingCompinentCommon /> :
                            <Button  size="sm" onClick={()=>handleSubmitSyncP()} disabled={selectedBusOPData==null?true:false} variant="primary">
                                Add Bus
                            </Button> }
              
            </Form>
                </Card.Body>
            </Card> */}
            {/* <Table striped className='body-table'>
        <thead>
          <tr>
            <th>#</th>
            <th>Bus</th>
            <th>Created Date</th>
            <th>Option</th>
          </tr>
        </thead>
        <tbody>
          {syncBusData.length==0?'No Data Found':
          syncBusData&&
          syncBusData.map((p,index) => {
              
              let dateAndTimeC='';
              let dateAndTimeU='';
  
                 dateAndTimeC=moment(p.created_at).utc().format('YYYY-MM-DD hh:mm:ss')
                 dateAndTimeU=moment(p.updated_at).utc().format('YYYY-MM-DD hh:mm:ss')
          
                  return (
                    <tr key={p.id}>
                    <td>{index+1}</td>
                    <td>{p.title}</td>

                    <td>{dateAndTimeC}</td>
                    <td>
                      <button onClick={()=>{
                        if(roleId==1){
                            if(p.role_id==2)
                            handleDeleteClick(p.id,p.title,0,p.role_title)
                            else  alert("Can't update")
                        }
                        if(roleId==2){
                            if(p.role_id!=2)
                            handleDeleteClick(p.id,p.title,0,p.role_title)
                            else  alert("Can't update")
                        }
                        if(roleId==3){
                          handleDeleteClick(p.id,p.title,0,p.role_title)
                          
                        }
            
         
                      }
                        
                        
                        } className='btn btn-delete'> <i className="fa fa-trash fa-sm" aria-hidden="true"></i></button >{' '}
                    </td> 
                  </tr>
                  );
                  })}
                  

        </tbody>
      </Table> */}
      {data.school_id == null || data.school_id == "" ? (
        <></>
      ) : (
        <TripTraveler
          tripId={ID}
          schooTitle={data.school_title}
          schoolId={data.school_id}
          schoolLng={data.lng}
          schoolLat={data.lat}
        />
      )}
      <br></br>

      {/* {data.school_id==null||data.school_id==""?<></>:  <TripEmp tripId={ID}
    schoolId={data.school_id}  
    />} */}
      {/* {data.school_id == null || data.school_id == "" ? (
        <></>
      ) : (
        <TripDetails tripId={ID} schoolId={data.school_id} />
      )} */}
      {/* {data.school_id == null || data.school_id == "" ? (
        <></>
      ) : (
        <TripDetailsDrop tripId={ID} schoolId={data.school_id} />
      )} */}
    </div>
  );
}

export default UpdateTrip;
