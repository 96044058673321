import Table from 'react-bootstrap/Table';
import React, { useEffect, useMemo, useState } from "react";
import LoadingCompinent from '../../components/loading/loadingComponent';
import TabelOperation from '../../components/tableOperation/tableOpeartion';
import "../../common/bodyTable.css"
import RoundedImageTable from"../../components/roundedImages/roundedImage"
import SchoolServices from '../../services/schoolServices';
import moment from "moment";
import { Link } from 'react-router-dom';
import HeaderSubTitle from "../../header/headerSubTitle";
import schoolServices from '../../services/schoolServices';
import { Button, Modal } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import api_url from "../../my_config"; 
function SchoolTable(){
  const roleId=localStorage.getItem('roleId');
  const [selectedTitle,setSelectedTitle]=useState("");
  const [selectedItemId,setSelectedItemId]=useState();
  const [show, setShow] = useState(false);
  const [resMsg,setResMsg]=useState("");
  const [items,setData]=useState([]);
  const [isLoading,setIsLoading]=useState(false);
  const [resSuccess, setResSuccess] = useState(false);
  const [showCBox, setShowCBox] = useState(false);
  const [gridApi,setGridApi]=useState(null);
  const [gridCollomApi,setGridCollomApi]=useState(null);
  const[columnDefs,setColumnDefs]=useState([
     
    {headerName: "#",valueGetter:'node.rowIndex+1',width: 70,suppressSizeToFit: false},
     {headerName: "Image", field: "title",
     width: 100,suppressSizeToFit: false,
     cellRendererFramework:(params)=>
     params.data.image!=""&& params.data.image!=null?
     <RoundedImageTable imageUrl={'https://schoob.net/busappapifiles/public/uploads/images/'+params.data.image}/>:
    
     <> <i className="fa fa-image fa-sm" aria-hidden="true"></i></>    },
     {headerName: "Email", field: "email",
      minWidth: 50, maxWidth: 100,suppressSizeToFit: false,
   },
     {headerName: "Title", field: "title",  minWidth: 50, maxWidth: 200,suppressSizeToFit: false,},
     {headerName: "Description", field: "description",  minWidth: 50, maxWidth: 200,suppressSizeToFit: false,},
     {headerName: "Created", field: 'created_at',
     minWidth: 50, maxWidth: 200,suppressSizeToFit: false,
     cellRendererFramework:(params)=><>{moment(params).utc().format('YYYY-MM-DD hh:mm:ss')}</>  
   },

   {headerName: "Action", field: 'id' ,
   minWidth: 50, maxWidth: 200,suppressSizeToFit: false,
   cellRendererFramework:(params)=><div className='body-table-action'>
   <Link to={'/UpdateSchool/'+params.data.id}>
                   <button className='btn btn-edit'> <i className="fa fa-edit fa-sm" aria-hidden="true"></i></button >{' '}
                 </Link>
                   <button className='btn btn-delete'
                                    onClick={() =>{ 
                                     setSelectedItemId(params.data.id);
                                     setSelectedTitle(params.data.title);
                                     handleShowCBox();}}
                   > <i className="fa fa-trash fa-sm" aria-hidden="true"></i></button >{' '}</div>
 },
 
    
     
 ])

 const defaultColDef = useMemo(()=>({

     enableRowGroup:true ,
     sortable:true,
     filter:true,
     //floatingFilter:true
   }))
   function handleSearchChanges(e){
     // console.log(e.target.value);
      gridApi.setQuickFilter(e.target.value)
    }
    function handlePerPageSelect(e){
      gridApi.paginationSetPageSize(e.target.value)
    }
    
    function onGridReady(params){
      setGridApi(params.api);
      params.api.sizeColumnsToFit(); 
      getSchoolData(params);
    }
    function exportDataAsCSC(){
      gridApi.exportDataAsCsv()
    }

  
//   useEffect(() => {
// getSchoolData();
   
// }, []);
function getSchoolData(params){
  if(roleId==1)

  { 
    //setIsLoading(true);
    
    SchoolServices.getData()
    .then((data) => {
      if(!data.length){
        params.api.showNoRowsOverlay();
      }else{
        params.api.hideOverlay();
      }
      // setData(data);
      params.api.applyTransaction({add:data})
      // setIsLoading(false);
        })
  //  SchoolServices.getData()
  //  .then((data) => {
  //          setData(data);
  //      //    setIsLoading(false);
          
  //      })
      }
}
function handleClose() {
  setShow(false);
}
const handleShow = () => setShow(true);
function handleCloseCBox() {
  setShowCBox(false);
  // if (resSuccess == true)
  //     navigate(-1)
}
const handleShowCBox = () => setShowCBox(true);
function handleDeleteData(){
  handleCloseCBox();
  setIsLoading(true);
  setResMsg('')
  setResSuccess(false);

          schoolServices.delete(selectedItemId).then((res) => {


              if (res == 'error') {
                  setResMsg('Something went wrong')
              } else {
                  if (res['status'] == true) { setResSuccess(true); 
                //      getSchoolData();
                  }
                  setResMsg(res['message'])
              }
              handleShow();
  
              setIsLoading(false);
          });
      

}
  return (
    <>
      <Modal show={show} onHide={handleClose} className='alert-box'>
                <Modal.Header closeButton>
                    <Modal.Title className={resSuccess == false ? 'alert-box-title-error' : 'alert-box-title'}>Response</Modal.Title>
                </Modal.Header>
                <Modal.Body className='alert-box-body'>{resMsg}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
                </Modal.Footer>
            </Modal>
        <Modal show={showCBox} onHide={handleCloseCBox} className='alert-box'>
                <Modal.Header closeButton>
                    <Modal.Title className='alert-box-title-error'>Are you Sure!</Modal.Title>
                </Modal.Header>
                <Modal.Body className='alert-box-body'>Are you wure want to delete {selectedTitle}</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => handleDeleteData()}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={() => handleCloseCBox()}>
                        No
                    </Button>
                    {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
                </Modal.Footer>
            </Modal>
{isLoading? <LoadingCompinent/>:
<>

<HeaderSubTitle title='School' />
    <TabelOperation navigation='/AddSchool' titleId='1'
       handleSearchChanges={handleSearchChanges}
       exportDataAsCSC={exportDataAsCSC}
       handlePerPageSelect={handlePerPageSelect}
    />
     <div className="ag-theme-alpine"
           style={{
             height: '100%',
           }}>
           <AgGridReact 
           
           onGridReady={onGridReady}
           columnDefs={columnDefs}
           rowGroupPanelShow="always"
          // rowData={rowData}
           rowSelection="multiple"
           animateRows={true}
           defaultColDef={defaultColDef}
           pagination={true}
           paginationPageSize={10}
           rowHeight={60}
           // valueGetter={inverseRowCount}
           // paginationAutoPageSize={true}
           />
         </div>
      {/* <Table striped className='body-table'>
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            {/* <th>Email</th> 
            <th>Title</th>
            <th>Description</th>
            <th>Created</th>
            <th>Updated</th>
            <th>Option</th>
          </tr>
        </thead>
        <tbody>
          {items.length==0?'No Data Found':
          items&&
            items.map((p,index) => {
           
              let dateAndTimeC='';
              let dateAndTimeU='';
       
                 dateAndTimeC=moment(p.created_at).utc().format('YYYY-MM-DD hh:mm:ss')
                 dateAndTimeU=moment(p.updated_at).utc().format('YYYY-MM-DD hh:mm:ss')
          
                  return (
                    <tr key={p.id}>
                    <td>{index+1}</td>
                    <td><RoundedImageTable imageUrl='https://upload.wikimedia.org/wikipedia/commons/e/ee/Camberwell_High_School.jpg'/></td>
                    {/* <td>{p.email}</td> *
                    <td>{p.title}</td>
                    <td>{p.description}</td>
                    <td>{dateAndTimeC}</td>
                    <td>{dateAndTimeU}</td>
                    <td>
                    <Link to={'/UpdateSchool/'+p.id}>
                      <button className='btn btn-edit'> <i className="fa fa-edit fa-sm" aria-hidden="true"></i></button >{' '}
                    </Link>
                    {roleId==1?
                     <button 
                     onClick={() =>{ 
                      setSelectedItemId(p.id);
                      setSelectedTitle(p.title);
                    
                      console.log("=====================");
                      handleShowCBox();}}
                     className='btn btn-delete'> <i className="fa fa-trash fa-sm" aria-hidden="true"></i></button >
                    :<></>}

                     
                    </td>
                  </tr>
                  );
                  })}
                  

        </tbody>
      </Table> */}
    </>
}
</>
    
  );
}
  


export default SchoolTable;