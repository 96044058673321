import logo from './logo.svg';
import './App.css';
import SideNavBar from "./sideNav/sideNavBar";
import Header from "./header/header";

import  {Outlet} from "react-router-dom";
import LoginPage from './pages/login/loginPage';
import { Button, Toast } from 'react-bootstrap';
import axios from "axios";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { async } from '@firebase/util';
import { useEffect } from 'react';

import userService from './services/userService';

import LoadingCompinent from './components/loading/loadingComponent';
import {  getToken } from "firebase/messaging";
import { messaging, onMessageListener } from './config/firebae';
function App() {

const [isLoading,setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [loggedInUser,setLoginUser] = useState(localStorage.getItem("login"));

 const   handleLOgOut =async (callback) => {
 const userId= localStorage.getItem('id');
  await userService.updateWebFcm(userId,null);
  localStorage.clear();
  setLoginUser(false);
};
const [notification, setNotification] = useState({title: '', body: ''});
const [show, setShow] = useState(false);
const [isTokenFound, setTokenFound] = useState(false);
getToken(setTokenFound);
onMessageListener().then(payload => {
  console.log("nnnnnnnnnooooooooooooo in forground");
  setShow(true);
  setNotification({title: payload.notification.title, body: payload.notification.body})
  console.log(payload);
}).catch(err => console.log('failed: ', err));

function mainContent(){
  // if ('serviceWorker' in navigator) {
  //   navigator.serviceWorker.register('firebase-messaging-sw.js')
  //   .then(function(registration) {
  //     console.log('Service Worker registered with scope:', registration.scope);

  //   }).catch(function(error) {
  //     console.error('Service Worker registration failed:', error);
  //   });
  // }

  return     <div className="App">
      <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide animation style={{
          position: 'absolute',
          top: 20,
          right: 20,
          minWidth: 200
        }}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">{notification.title}</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body>{notification.body}</Toast.Body>
        </Toast>
  <div  className='left-body'>
  <SideNavBar/>
  </div>
  <div className='right-body'>
  <Header handleLOgOut={handleLOgOut} />
  <Outlet />
  </div>
</div>
}
const handleLogin = async(callback) => {
  // setIsLoading(true);

  // setIsLoading(false);
     navigate("/SchoolDashBoard")

  setLoginUser(true);

};

  return (
    <>
       <ToastContainer 
       colo
       autoClose={3000}
       hideProgressBar={true}
       theme="colored"
       />
     {
      
      loggedInUser?
      mainContent()
    :

   isLoading?<LoadingCompinent/>: <LoginPage handleLogin={handleLogin} />
    }
    </>
   
    
  
  );
}

export default App;
