import { Row, Col, Form, Button, Dropdown, Modal } from 'react-bootstrap'
import "../../common/custoForm.css" 

import { useEffect, useState } from 'react';
import LoadingCompinentCommon from '../../common/commonLoading';
import schoolServices from '../../services/schoolServices';
import { useNavigate } from 'react-router-dom';
import cityService from '../../services/cityService';
import UploadFileService from '../../services/uploadFile';
import HeaderSubTitle from "../../header/headerSubTitle";
import validation from '../../helper/validation';

function AddSchool() {
    const navigate = useNavigate();
    const [data, setData] = useState({
        email: "",
        password: "",
        title: "",
        description: "",
        cityId: ""
    });
    const [isLoading, setLoading] = useState(false);
    const [resSuccess, setResSuccess] = useState(false);
    const [resMsg, setResMsg] = useState('')
    const [show, setShow] = useState(false);
    const [cities, setcities] = useState([]);
    const [image, setImage] = useState(null)
    const [imageId, setImageId] = useState(null)
    const [isImageUploading, setFlagUloadImage] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null);
    const [errorTtile, setErrorsTitle] = useState(null);
    const [errorsCity, setErrorsCity] = useState(null);
    function handleClose() {
        setShow(false);
        if(resSuccess==true)
        navigate(-1)
    }
    function handleShow  () {
        setShow(true)
    };
    function getCity() {
        cityService.getData()
            .then((data) => {
                setcities(data);
            })
    }
    useEffect(() => {
        getCity();
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        let isValidTitle=false;
        let isValidCity=false;
        const titleValidate=validation.checkNameValidation(data.title);
        if(titleValidate){
            isValidTitle=true;
            setErrorsTitle(null);
        }
            else{
                isValidTitle=false
                setErrorsTitle("Length must be greater than 3 letters");           
            }
            if(isValidTitle==true){
                setLoading(true);
                setResMsg('')
                setResSuccess(false);
              
                schoolServices.addData(data.title, data.description, data.cityId,selectedFile).then((res) => {
                    if (res == 'error') {
                        setResMsg('Something went wrong')
                    } else {
                        if (res['status'] == true) { setResSuccess(true); }
                        setResMsg(res['message'])
                    }
                    handleShow(true);
        
                    setLoading(false);
                });
            }
     
    }
 
    function handleChange(e) {
        const newData = { ...data }
        newData[e.target.id] = e.target.value
        setData(newData);

    }

    function handleRemoveImage(){
        setImage(null);
        setImageId(null);
        setSelectedFile(null);
        
    }
    
      function handleChangeImage(event) {
    
        if (event.target.files && event.target.files[0]) {
            if (!event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i))
                 alert('not an image');
    
    else{
        let file_size = event.target.files[0].size;
        if(file_size>0&&file_size<512000)
    {     setSelectedFile(event.target.files[0])
        setImage(URL.createObjectURL(event.target.files[0]));
    }
        else{
            alert("File Size must be less then 500kb");
            // alert(file_size);
        }
    }
           
        }
    }
    return (
    <>  
    <HeaderSubTitle title='Add New School'/>
        <div className="Cutso-Form">
            <Modal show={show} onHide={handleClose} className='alert-box'>
                <Modal.Header closeButton>
                    <Modal.Title className={resSuccess == false ? 'alert-box-title-error' : 'alert-box-title'}>Response</Modal.Title>
                </Modal.Header>
                <Modal.Body className='alert-box-body'>{resMsg}</Modal.Body>
                <Modal.Footer>
                    <Button   variant="secondary" onClick={() => handleClose()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className='form-section-update'>
            <Form onSubmit={(e) => handleSubmit(e)}> 
                <Form.Group className="mb-3">
                    <Form.Label className='form-label' >
                        City*
                    </Form.Label>
                 
                        <Form.Control
                          isInvalid={!!errorsCity}
                        required as="select" onChange={(e) => handleChange(e)} value={data.cityId} id='cityId' name='cityId'>
                            <option key={'empty'} value={''}>...</option>
                            {
                                cities &&
                                cities.map((p) => {

                                    return (
                                        <option key={p.id} value={p.id}>{p.title}</option>
                                    );
                                })}

                        </Form.Control>
                        <Form.Control.Feedback type="invalid">{errorsCity}</Form.Control.Feedback>

                </Form.Group>
                {/* <Form.Group  className="mb-3">
                    <Form.Label column sm={2}>
                        Email*
                    </Form.Label>
               
                        <Form.Control required onChange={(e) => handleChange(e)} name='email' id='email' value={data.email} type="email" placeholder="email@example.com" className='form-control' />
                
                </Form.Group> */}
                {/* <Form.Group  className="mb-3">
                    <Form.Label column sm={2}>
                        Password*
                    </Form.Label>
           
                        <Form.Control required onChange={(e) => handleChange(e)} type="password" placeholder="Password" name='password' id='password' value={data.password} />
                
                </Form.Group> */}
                <Form.Group  className="mb-3">
                    <Form.Label column sm={2}>
                        Title*
                    </Form.Label>
              
                        <Form.Control 
                         isInvalid={!!errorTtile}
                        required onChange={(e) => handleChange(e)} type="text" placeholder="School Name" name='title' id='title' value={data.title} />
                    <Form.Control.Feedback type="invalid">{errorTtile}</Form.Control.Feedback>

                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label column sm={2}>
                        Description
                    </Form.Label>
                
                        <Form.Control as="textarea" rows={3} onChange={(e) => handleChange(e)} name='description' id='description' placeholder="About School" value={data.description} />
               
                </Form.Group>
                {isLoading == true ? <LoadingCompinentCommon /> : 
           <Button size="sm"  variant="primary" type="submit">
           Submit
       </Button>
                }
            </Form>
            </div>
            
<div className='image-box'>
<div>
</div>
{image==null? <div className='image-box-add'> <i className="fa fa-image" aria-hidden="true"></i></div>:
      <img id="image" src={image}/>} 
  {
     isLoading==true?
     <></>:
  isImageUploading==true?
  <>
  <br/>
  <LoadingCompinentCommon />
  </>
  :
  image==null?
  <div className="form-group">
  <label htmlFor="myInput" className='image-operation-btn'>Select Image</label>
   <input id="myInput"type="file" name="file" className="mb-4" 
    accept="image/*"
   style={{display:'none'}}
   onClick={(event)=> { 
     event.target.value = null
}}
   onChange={(e) => handleChangeImage(e)}  />
  </div>:
  <>
    <button className='image-operation-btn' onClick={handleRemoveImage}>
      Remove
   </button>

   {/* <button className='image-operation-btn' onClick={handleUploadImage}>
      Upload Image
   </button> */}
   </>
   }
</div>
        </div>
        </>
    );

}

export default AddSchool;