import { db } from './../config/firebae.js'; 
import { doc, getDoc,setDoc } from "firebase/firestore";
import "firebase/firestore"
class FirebaseService{
async getData(collectionName, docID) {
    const docRef = doc(db, collectionName, docID);
    const targetDoc = await getDoc(docRef);
    console.log("targetDoc.data() : ", targetDoc.data());
    return { data: targetDoc.data() 

    // const fetchedDataList = "";
    // await db
    //   .collection(collectionName)
    //   .doc(docID)
    //   .get()
    //   .then((doc) => {
    //     console.log("Data fetched ");
    //     return doc.data();
    //   })
    //   .catch((e) => {
    //     console.log("Error during fetching data" + e);
    //     return "error";
    //   });
    // return fetchedDataList;
  }
}
async addData(collectionName, body,doId) {
  try {
    const docRef = await setDoc(doc(db, collectionName,doId), body);
    console.log("sucesss addedd in firebas ")
    //console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }


  // const docRef = doc(db, collectionName, docID);
  // const targetDoc = await getDoc(docRef);
  // console.log("targetDoc.data() : ", targetDoc.data());
  // return { data: targetDoc.data() /

//}
}
}
export default new FirebaseService();