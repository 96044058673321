import { Row, Col, Form, Button, Dropdown, Modal } from 'react-bootstrap'
import "../../common/custoForm.css" 

import { useEffect, useState } from 'react';
import LoadingCompinentCommon from '../../common/commonLoading';
import { useNavigate } from 'react-router-dom';
import EmpService from '../../services/empService';
import HeaderSubTitle from '../../header/headerSubTitle';
import schoolServices from '../../services/schoolServices';
function AddEmp() {
    const navigate = useNavigate();
    const [data, setData] = useState({
        email: "",
        password:"",
        address: "",
        first_name: "",
        last_name: "",
        school_id:""
      
    });

    const [isLoading, setLoading] = useState(false);
    const [resSuccess, setResSuccess] = useState(false);
    const [resMsg, setResMsg] = useState('')
    const [show, setShow] = useState(false);
    const [userTypes, setUserTypes] = useState([]);
    const [school, setSchool] = useState([]);

    function getSChool() {
        schoolServices.getData()
            .then((data) => {
                setSchool(data);
            })
    }
    useEffect(() => {
        getSChool();
    }, []);
    function handleClose() {
        setShow(false);
            navigate(-1)
    }
    function handleShow  (isNavigateBack) {
   
        setShow(true)
    };
    function getCity() {
        setUserTypes([
            {
                'id':"7",
                "title":"Manager"
            },
            {
                'id':"8",
                "title":"Operational Manager"
            },
            {
                'id':"9",
                "title":"Bus Driver"
            },
            {
                'id':"10",
                "title":"Bus Assistent"
            }
        
        ]);
    }
    useEffect(() => {
        getCity();
    }, []);

  
    function handleSubmit(e) {
        setLoading(true);
        setResMsg('')
        setResSuccess(false);
        e.preventDefault();
        EmpService.addData(data.user_type_id, data.first_name, data.last_name, data.email,data.password, data.address,data.school_id).then((res) => {
            if (res == 'error') {
                setResMsg('Something went wrong')
            } else {
                if (res['status'] == true) { setResSuccess(true); }
                setResMsg(res['message'])
            }
            handleShow(true);

            setLoading(false);
        });
    }
 
    function handleChange(e) {
        const newData = { ...data }
        newData[e.target.id] = e.target.value
        setData(newData);
        console.log(data);
    }

    return (
        <> <HeaderSubTitle title='Add New Employee'/>
        <div className="Cutso-Form">

            <Modal show={show} onHide={handleClose} className='alert-box'>
                <Modal.Header closeButton>
                    <Modal.Title className={resSuccess == false ? 'alert-box-title-error' : 'alert-box-title'}>Response</Modal.Title>
                </Modal.Header>
                <Modal.Body className='alert-box-body'>{resMsg}</Modal.Body>
                <Modal.Footer>
                    <Button   variant="secondary" onClick={() => handleClose()}>
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
                </Modal.Footer>
            </Modal>
            <div className='form-section'>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Form.Group className="mb-3" >
                    <Form.Label className='form-label' >
                        User Type*
                    </Form.Label>
                  
                        <Form.Control required as="select" onChange={(e) => handleChange(e)} value={data.user_type_id} id='user_type_id' name='user_type_id'>
                            <option key={'empty'} value={''}>...</option>
                            {
                                userTypes &&
                                userTypes.map((p) => {

                                    return (
                                        <option key={p.id} value={p.id}>{p.title}</option>
                                    );
                                })}

                        </Form.Control>
                  
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label className='form-label' >
                        School*
                    </Form.Label>
                 
                        <Form.Control required as="select" onChange={(e) => handleChange(e)} value={data.school_id} id='school_id' name='school_id'>
                            <option key={'empty'} value={''}>...</option>
                            {
                                school &&
                                school.map((p) => {

                                    return (
                                        <option key={p.id} value={p.id}>{p.title} - {p.city_name}</option>
                                    );
                                })}

                        </Form.Control>
                 
                </Form.Group>
                <Form.Group  className="mb-3">
                    <Form.Label >
                        Email*
                    </Form.Label>
               
                        <Form.Control required onChange={(e) => handleChange(e)} name='email' id='email' value={data.email} type="email" placeholder="email@example.com" className='form-control' />
                
                </Form.Group>
                <Form.Group  className="mb-3">
                    <Form.Label >
                        Password*
                    </Form.Label>
           
                        <Form.Control required onChange={(e) => handleChange(e)} type="password" placeholder="Password" name='password' id='password' value={data.password} />
                
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label >
                        First Name*
                    </Form.Label>
                
                        <Form.Control required onChange={(e) => handleChange(e)} type="text" placeholder="Jhon" name='first_name' id='first_name' value={data.first_name} />
                  
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label >
                        Last Name*
                    </Form.Label>
                 
                        <Form.Control required onChange={(e) => handleChange(e)} type="text" placeholder="Alexender" name='last_name' id='last_name' value={data.last_name} />
              
                </Form.Group>
                <Form.Group  className="mb-3">
                    <Form.Label >
                        Address
                    </Form.Label>
                 
                        <Form.Control as="textarea" rows={3} onChange={(e) => handleChange(e)} placeholder='Address' name='address' id='address' value={data.address} />
                    
                </Form.Group>
                {isLoading == true ? <LoadingCompinentCommon /> : 
        
                <Button size="sm"  variant="primary" type="submit">
                    Submit
                </Button>
                }
            </Form>
            </div>
           <div className='image-box'>
           <div>

  </div>
        
           </div>
        
             
        
        </div>
        </>
    );

}

export default AddEmp;