import api_url from "../my_config"; 
class AssignTripBusesServices{
    getByTripIdUrl=api_url+'/api/assign_bus_trip/trip';
    addUrl=api_url+'/api/add_assign_bus_trip';
    deleteUrl=api_url+'/api/delete_assign_bus_trip';
    

     async getDataByTripId(id) {
       try{
       // Default options are marked with *
       const response = await fetch(this.getByTripIdUrl+'/'+id, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
           'Content-Type': 'application/json',
           'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
           // 'Content-Type': 'application/x-www-form-urlencoded',
         },
         redirect: 'follow', // manual, *follow, error
         referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
         // body: JSON.stringify(data) // body data type must match "Content-Type" header
       });
       if(response.status==200){
        const data = await response.json();
        if(data['response']==200){
          if( data['data']==null)
          return []; 
       else return data['data']; 
        }
        else return [];
      }else return[];
    }catch(e){
      return[];
    }
     
      // parses JSON response into native JavaScript objects
     }
   
     async addData(trip_id,bus_id) {
    
        try{
        // Default options are marked with *
        const response = await fetch(this.addUrl, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(
            {
             "bus_id":bus_id,
             "trip_id":trip_id
            }
          
          ) // body data type must match "Content-Type" header
        });
  
            if(response.status==200){
              const data = await response.json();
              if(data['response']==201||data['response']==200)
              return data; 
              else return "error";
            }else return"error";
          }catch(e){
         
            return"error";
          }
      
       // parses JSON response into native JavaScript objects
      }
     async delete(id) {
      try{
      // Default options are marked with *
      const response = await fetch(this.deleteUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(
          {
           "id":id
          }
        
        ) // body data type must match "Content-Type" header
      });

          if(response.status==200){
            const data = await response.json();
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }
   

}
   
export default new AssignTripBusesServices();