import api_url from "../my_config"; 
class SyncServices{
  syncUserUrl=api_url+'/api/sync_user';
    syncUrl=api_url+'/api/sync_student';
    syncBySchoolParentUrl=api_url+'/api/sync_student/parent_id/school_id';
    syncBySchoolStudentUrl=api_url+'/api/sync_student/student_id/school_id';
    syncBusEmpUrl=api_url+'/api/assign_bus_emp';
    busEmpUrl=api_url+'/api/assign_bus_emp/bus';
    busEmpDeleteUrl=api_url+'/api/delete_assign_bus_emp';

    unSyncUrl=api_url+'/api/sync_student/delete';
    async getBusEmp(bus_id) {

     
      try{
      // Default options are marked with *
      const response = await fetch(this.busEmpUrl+'/'+bus_id, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
              if( data['data']==null)
              return []; 
           else return data['data']; 
            }
            else return [];
          }else return[];
        }catch(e){
          return[];
        }
    
     // parses JSON response into native JavaScript objects
    }
    async getParentSyncUsersByStudentSchool(school_id,student_id) {

     
      try{
      // Default options are marked with *
      const response = await fetch(this.syncBySchoolStudentUrl+'/'+school_id+'/'+student_id, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
              if( data['data']==null)
              return []; 
           else return data['data']; 
            }
            else return [];
          }else return[];
        }catch(e){
          return[];
        }
    
     // parses JSON response into native JavaScript objects
    }
    async getParentSyncUsersByParentSchool(school_id,parent_id) {
      console.log(this.syncBySchoolParentUrl+'/'+school_id+'/'+parent_id);
     
      try{
      // Default options are marked with *
      const response = await fetch(this.syncBySchoolParentUrl+'/'+school_id+'/'+parent_id, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
              if( data['data']==null)
              return []; 
           else return data['data']; 
            }
            else return [];
          }else return[];
        }catch(e){
          return[];
        }
    
     // parses JSON response into native JavaScript objects
    }
    async getParentSyncUsers(parent_id) {
      console.log(this.syncUrl+'/parent_id/'+parent_id);
     
      try{
      // Default options are marked with *
      const response = await fetch(this.syncUrl+'/parent_id/'+parent_id, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
              if( data['data']==null)
              return []; 
           else return data['data']; 
            }
            else return [];
          }else return[];
        }catch(e){
          return[];
        }
    
     // parses JSON response into native JavaScript objects
    }
    async getSchoolUsers(school_id) {
      console.log(this.syncUserUrl+'/school_id/'+school_id);
     
      try{
      // Default options are marked with *
      const response = await fetch(this.syncUserUrl+'/school_id/'+school_id, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
              if( data['data']==null)
              return []; 
           else return data['data']; 
            }
            else return [];
          }else return[];
        }catch(e){
          return[];
        }
    
     // parses JSON response into native JavaScript objects
    }
    async deleteBusEmp(bus_assign_emp_id) {
    
      try{
      // Default options are marked with *
      const response = await fetch(this.busEmpDeleteUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(
          {
            "id":bus_assign_emp_id,
      
          
      
        }
        
        ) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }
    async syncBusEmp(bus_id,user_id) {
    
      try{
      // Default options are marked with *
      const response = await fetch(this.syncBusEmpUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(
          {
            "bus_id":bus_id,
              "user_id":user_id,
          
      
        }
        
        ) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }
    async syncUserP(parent_id,school_id,student_id) {
    
      try{
      // Default options are marked with *
      const response = await fetch(this.syncUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(
          {
            "parent_id":parent_id,
              "student_id":student_id,
                "school_id":school_id
        
        }
        
        ) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }
    async unSyncUser(id) {
     
    
      try{
      // Default options are marked with *
      const response = await fetch(this.unSyncUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(
          {
            "id":id,
        
    
          }
        
        ) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }

}
   
export default new SyncServices();