import api_url from "../my_config"; 
class CityServices{
    getUrl=api_url+'/api/get_city';
    addUrl='';
   async getData() {
   
       try{
       // Default options are marked with *
       const response = await fetch(this.getUrl, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
           'Content-Type': 'application/json',
           'Authorization':"Bearer Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
           // 'Content-Type': 'application/x-www-form-urlencoded',
         },
         redirect: 'follow', // manual, *follow, error
         referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
         // body: JSON.stringify(data) // body data type must match "Content-Type" header
       });
       console.log("-------------------------");
       console.log(response);
           if(response.status==200){
             const data = await response.json();
             if(data['response']==200){
              if(data['data']==null)
              return [];
             else return data['data']; 
             }
     
             else return [];
           }else return[];
         }catch(e){
           return[];
         }
     
      // parses JSON response into native JavaScript objects
     }

     async addData(email,pass,title,description,cityId,imageUrl) {
       try{
       // Default options are marked with *
       const response = await fetch(this.addUrl, {
         method: 'POST', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
           'Content-Type': 'application/json',
           'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
           // 'Content-Type': 'application/x-www-form-urlencoded',
         },
         redirect: 'follow', // manual, *follow, error
         referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
         body: JSON.stringify(
           {
             "email":email,
             "password":pass,
             "title":title,
             "description":description,
             "city_id":cityId,
             "image_url":imageUrl
           }
         
         ) // body data type must match "Content-Type" header
       });
           if(response.status==200){
             const data = await response.json();
             if(data['response']==201||data['response']==200)
             return data; 
             else return "error";
           }else return"error";
         }catch(e){
        
           return"error";
         }
     
      // parses JSON response into native JavaScript objects
     }

}
   
export default new CityServices();