import Table from 'react-bootstrap/Table';
import React, { useEffect, useMemo, useState } from "react";
import LoadingCompinent from '../../components/loading/loadingComponent';
import TabelOperation from '../../components/tableOperation/tableOpeartion';
import "../../common/bodyTable.css"
import RoundedImageTable from"../../components/roundedImages/roundedImage"
import UserServices from '../../services/userService';
import moment from "moment";
import { Link } from 'react-router-dom';
import HeaderSubTitle from "../../header/headerSubTitle";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import api_url from "../../my_config"; 
function UserTable(){
  const [items,setItems]=useState([]);
  const [isLoading,setIsLoading]=useState(false);
  const roleId=localStorage.getItem('roleId');
  const schoolId=localStorage.getItem('schoolId');
  const [gridApi,setGridApi]=useState(null);
const [gridCollomApi,setGridCollomApi]=useState(null);
   
  const[columnDefs,setColumnDefs]=useState([
   //{getRoleName(p.role)}</td>
   //    <td>{p.active==1?'Active':"Inactive"}</td>
     {headerName: "#",valueGetter:'node.rowIndex+1',width: 70,suppressSizeToFit: false},
     {headerName: "Image", field: "title",
     width: 100,suppressSizeToFit: false,
     cellRendererFramework:(params)=>
     params.data.image!=""&& params.data.image!=null?
     <RoundedImageTable imageUrl={'https://schoob.net/busappapifiles/public/uploads/images/'+params.data.image}/>:
  
     <> <i className="fa fa-image fa-sm" aria-hidden="true"></i></> 
    },
     {headerName: "First Name", field: "first_name", minWidth: 50, maxWidth: 100,suppressSizeToFit: false },
     {headerName: "Last Name", field: "last_name",  minWidth: 50, maxWidth: 200,suppressSizeToFit: false,},
     {headerName: "Role", field: "role",  minWidth: 50, maxWidth: 200,suppressSizeToFit: false,
     cellRendererFramework:(params)=><div> {getRoleName(params.data.role)}</div>
    },
     {headerName: "Phone", field: "phone",  minWidth: 50, maxWidth: 100,suppressSizeToFit: false,},
     {headerName: "Email", field: "email",  minWidth: 50, maxWidth: 100,suppressSizeToFit: false,},
     {headerName: "Address", field: "address",  minWidth: 50, maxWidth: 100,suppressSizeToFit: false,},
     {headerName: "Status", field: "active",  minWidth: 50, maxWidth: 100,suppressSizeToFit: false,},
     {headerName: "Created", field: 'created_at',
     minWidth: 50, maxWidth: 200,suppressSizeToFit: false,
     cellRendererFramework:(params)=><>{moment(params).utc().format('YYYY-MM-DD hh:mm:ss')}</>  
   },
   {headerName: "Action", field: 'id' ,
   minWidth: 50, maxWidth: 200,suppressSizeToFit: false,
   cellRendererFramework:(params)=><div className='body-table-action'>
   <Link to={'/UpdateUsers/'+params.data.id}>
                   <button className='btn btn-edit'> <i className="fa fa-edit fa-sm" aria-hidden="true"></i></button >{' '}
                 </Link>
                   {/* <button className='btn btn-delete'
                                    onClick={() =>{ 
                                     setSelectedItemId(params.data.id);
                                     setSelectedTitle(params.data.title);
                                     handleShowCBox();}}
                   > <i className="fa fa-trash fa-sm" aria-hidden="true"></i></button >{' '} */}
                   </div>
 },
     
 ])
 const defaultColDef = useMemo(()=>({

  enableRowGroup:true ,
  sortable:true,
  filter:true,
  //floatingFilter:true
}))
function handleSearchChanges(e){
  // console.log(e.target.value);
   gridApi.setQuickFilter(e.target.value)
 }
 function handlePerPageSelect(e){
   gridApi.paginationSetPageSize(e.target.value)
 }
 
 function onGridReady(params){
   setGridApi(params.api);
   params.api.sizeColumnsToFit(); 
   getAllTableData(params);
 }
 function exportDataAsCSC(){
   gridApi.exportDataAsCsv()
 }
 function getAllTableData(params){
  if(roleId==1){
      
    UserServices.getData()
    .then((data) => {
      if(!data.length){
        params.api.showNoRowsOverlay();
      }else{
        params.api.hideOverlay();
      }
 
      params.api.applyTransaction({add:data})

        })

  }
  if(roleId==2||roleId==3){
    UserServices.getAllDataBySchoolId(schoolId)
    .then((data) => {
      if(!data.length){
        params.api.showNoRowsOverlay();
      }else{
        params.api.hideOverlay();
      }

      params.api.applyTransaction({add:data})
        })
  }
 }

  

function getRoleName(roleArr){
  var roleName="--";
  for(var i=0;i<roleArr.length;i++){
    if(i==0){
      roleName=roleArr[i]['role_title'];
    }else{
      roleName= roleName+", "+roleArr[i]['role_title'];
  }  
}
return roleName;
}
return (
  <>
  {isLoading? <LoadingCompinent/>:
  <>
<HeaderSubTitle title='Users'/>
  <TabelOperation navigation='/AddUser'
  
  handleSearchChanges={handleSearchChanges}
  exportDataAsCSC={exportDataAsCSC}
  handlePerPageSelect={handlePerPageSelect}
  />
  
  <div className="ag-theme-alpine"
           style={{
             height: '100%',
           }}>
           <AgGridReact 
           
           onGridReady={onGridReady}
           columnDefs={columnDefs}
           rowGroupPanelShow="always"
          // rowData={rowData}
           rowSelection="multiple"
           animateRows={true}
           defaultColDef={defaultColDef}
           pagination={true}
           paginationPageSize={10}
           rowHeight={60}
           // valueGetter={inverseRowCount}
           // paginationAutoPageSize={true}
           />
         </div>
   {/* <Table striped className='body-table'>
      <thead>
        <tr>
          <th>#</th>
          <th>Image</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Role</th>
          <th>Phone</th>
          <th>Email</th>
          <th>Address</th>
          <th>Status</th>
          <th>Created</th>
          <th>Updated</th>
          <th>Option</th>
        </tr>
      </thead>
      <tbody>
        {items.length==0?'No Data Found':
        items&&
          items.map((p,index) => {
      
            let dateAndTimeC='';
            let dateAndTimeU='';

               dateAndTimeC=moment(p.created_at).utc().format('YYYY-MM-DD hh:mm:ss')
               dateAndTimeU=moment(p.updated_at).utc().format('YYYY-MM-DD hh:mm:ss')
        
                return (
                  <tr key={p.id}>
                  <td>{index+1}</td>
                  <td><RoundedImageTable imageUrl='https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80'/></td>
                  <td>{p.first_name}</td>
                  <td>{p.last_name}</td>
                  <td>{getRoleName(p.role)}</td>
                  <td>{p.phone}</td>
                  <td>{p.email}</td>
                  <td>{p.address}</td>
                  <td>{p.active==1?'Active':"Inactive"}</td>
                  <td>{dateAndTimeC}</td>
                  <td>{dateAndTimeU}</td>
                  <td>
                  <Link to={'/UpdateUsers/'+p.id}>
                    <button className='btn btn-edit'> <i className="fa fa-edit fa-sm" aria-hidden="true"></i></button >{' '}
                  </Link>
                    {/* <button className='btn btn-delete'> <i className="fa fa-trash fa-sm" aria-hidden="true"></i></button >{' '} 
                  </td>
                </tr>
                );
                })}
                

      </tbody>
    </Table> */}
  </>
              }
               </>
);



}



export default UserTable;