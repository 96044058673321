import moment from "moment";
import { useEffect, useState } from "react";
import { Badge, Button, Col, Form, ListGroup, Modal, Row, Table } from "react-bootstrap";
import UserServices from "../../services/userService";
import RoundedImageTable from "../../components/roundedImages/roundedImage";
import "./trip.css";
import TripService from "../../services/tripService";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import GoogleMapComponent from "../../components/googleMap/googleMap";
import GoogleMapRoute from "../../components/googleMap/googleMapRoute";
import GoogleMapRouteDrop from "../../components/googleMap/googleMapRouteDrop";
import Geocode from "react-geocode";
import validation from '../../helper/validation';

function TripTraveler({ tripId, schooTitle, schoolId ,schoolLat,schoolLng}) {
  const [isLoading, setLoading] = useState(false);
  const [isAssignUserLoading, setAsigneUserLoading] = useState(false);
  const [pickMapForDrop,setPickMapForDrop]=useState(false);
  const [usersData, setUsersData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [usersDataAssign, setUsersDataAssign] = useState([]);
  const [usersDataPickUpAssign, setUsersDataPickUpAssign] = useState([]);
  const [usersDataDripAssign, setUsersDataDropAssign] = useState([]);
  const [count, setCount] = useState(1);
  const [resSuccess, setResSuccess] = useState(false);
  const [resMsg, setResMsg] = useState("");
  const [fetchOrderFlag, setFetchOrderFlag] = useState("pick_up_order");
  const [show, setShow] = useState(false);
  const [showStatusBox, setShowStatusBox] = useState(false);
  const [showGoogleMapBox, setShowGoogleMapBox] = useState(false);
  const [selectedFilter, setSlectedFilter] = useState({student: true, emp: false});
  const [searchTextInput] = useState({text:""});
  const [mapLatLng,setMapLatLng]=useState(null);

  const [pErrorsBN, setPErrorsBN] = useState(null);
  const [pErrorsSN, setPErrorsSN] = useState(null);
  const [pErrorsAN, setPErrorsAN] = useState(null);
  const [pErrorsCity, setPErrorsCity] = useState(null);
  const [pErrorsPicCode, setPErrorsPicCode] = useState(null);
  const [pErrorsLat, setErrorsLat] = useState(null);
  const [pErrorsLng, setErrorsLng] = useState(null);

  const [dErrorsBN, setDErrorsBN] = useState(null);
  const [dErrorsSN, setDErrorsSN] = useState(null);
  const [dErrorsAN, setDErrorsAN] = useState(null);
  const [dErrorsCity, setDErrorsCity] = useState(null);
  const [dErrorsPicCode, setDErrorsPicCode] = useState(null);
  const [dErrorsLat, setErrorsDLat] = useState(null);
  const [dErrorsLng, setErrorsDLng] = useState(null);
  
  const [selectedTraveller, setSelectedTravellerData] = useState({
    trip_traveller_id:"",
    user_id: "",
    first_name: "",
    last_name: "",
  });
  const [formData, setFormData] = useState({});
  const [sameAddFlag, setSameAddFlag] = useState(false);
  function handleClose() {
    setShow(false);
  }
  function handleShowStatusBox() {
    setShowStatusBox(true);
  }
  function handleShowGoogleMapBox(pickMapForDropStatus) {
    setPickMapForDrop(pickMapForDropStatus)
    setShowGoogleMapBox(true);
   
  }
  function handleCloseStatusBox() {
    setShowStatusBox(false);
  }
  function handleCloseGoogleMapBox() {
    setShowGoogleMapBox(false);
  }
  function handleSubmitGoogleMap() {
    if(mapLatLng!=null)
{    if(pickMapForDrop){
  setCordinatesDropLatLng(mapLatLng)

}else{
  setCordinatesPickUpLatLng(mapLatLng);
}
 }
    setShowGoogleMapBox(false);
  }
  function handleShow() {
    setShow(true);
  }
  function getUsers() {
    setUsersData([]);
    UserServices.getAllDataBySchoolId(schoolId).then((data) => {
      setUsersData(data);
   //     setFilterData(data);
      initFilterSelectedData(data);
      setLoading(false);
    });
  }
  function getUsersSeach(searchText) {

    // UserServices.getAllDataBySchoolIdSearch(schoolId,searchText).then((data) => {
    //   setUsersData(data);
    // });
    
  }
  function setCordinatesPickUpLatLng(cordinates){
    Geocode.setApiKey("AIzaSyA3GkUvfEQeMptos3NG_yufZ7tvZCenvJk");

// set response language. Defaults to english.
Geocode.setLanguage("en");
Geocode.setLocationType("ROOFTOP");

Geocode.fromLatLng(cordinates.lat, cordinates.lng).then(
  (response) => {
    const address = response.results[0].formatted_address;
    let city, state, country, postalCode,streetName,areaName, buildingNumber;
    for (let i = 0; i < response.results[0].address_components.length; i++) {
      for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
        switch (response.results[0].address_components[i].types[j]) {
          case "locality":
            city = response.results[0].address_components[i].long_name;
            break;
          case "administrative_area_level_1":
            state = response.results[0].address_components[i].long_name;
            break;
          case "country":
            country = response.results[0].address_components[i].long_name;
            break;
            case "postal_code":
              postalCode = response.results[0].address_components[i].long_name;
              break;
              case "route":
                streetName = response.results[0].address_components[i].long_name;
                break;
                case "sublocality_level_1":
                  areaName = response.results[0].address_components[i].long_name;
                  break;
                  case "premise":
                    buildingNumber = response.results[0].address_components[i].long_name;
                    break;
              
              
        }
      }
    }
    // console.log(city, state, country,postalCode, streetName,areaName,buildingNumber);
    // console.log(address);
    // console.log(cordinates.lat)
    // console.log(cordinates.lng)
    const newData = { ...formData };
    newData['pick_up_building_number']= buildingNumber??"";
    newData['pick_up_street_name']= streetName??"";
    newData['pick_up_area_name']= areaName??"";
    newData['pick_up_city']= city??"";
    newData['pick_up_pin_code']= postalCode??"";
    newData["pick_up_lat"] = cordinates.lat??"";
    newData["pick_up_lng"] = cordinates.lng??"";
    setFormData(newData);
  },
  (error) => {
    console.error(error);
  }
);

    const newData = { ...formData };
    newData["pick_up_lat"] = cordinates.lat;
    newData["pick_up_lng"] = cordinates.lng;
    setFormData(newData);
  }
  function setCordinatesDropLatLng(cordinates){
    Geocode.setApiKey("AIzaSyA3GkUvfEQeMptos3NG_yufZ7tvZCenvJk");
    // set response language. Defaults to english.
    Geocode.setLanguage("en");
    Geocode.setLocationType("ROOFTOP");
    
    Geocode.fromLatLng(cordinates.lat, cordinates.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        let city, state, country, postalCode,streetName,areaName, buildingNumber;
        for (let i = 0; i < response.results[0].address_components.length; i++) {
          for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
                case "postal_code":
                  postalCode = response.results[0].address_components[i].long_name;
                  break;
                  case "route":
                    streetName = response.results[0].address_components[i].long_name;
                    break;
                    case "sublocality_level_1":
                      areaName = response.results[0].address_components[i].long_name;
                      break;
                      case "premise":
                        buildingNumber = response.results[0].address_components[i].long_name;
                        break;
                                   
            }
          }
        }
        // console.log(city, state, country,postalCode, streetName,areaName,buildingNumber);
       console.log(address);
        // console.log(cordinates.lat)
        // console.log(cordinates.lng)
        const newData = { ...formData };
        newData['drop_building_number']= buildingNumber??"";
        newData['drop_street_name']= streetName??"";
        newData['drop_area_name']= areaName??"";
        newData['drop_city']= city??"";
        newData['drop_pin_code']= postalCode??"";
        newData["drop_lat"] = cordinates.lat??"";
        newData["drop_lng"] = cordinates.lng??"";
        setFormData(newData);
        console.log("giiiiiiiiiiiii",newData);
      },
      (error) => {
        console.error(error);
      }
    );
    const newData = { ...formData };
    newData["drop_lat"] = cordinates.lat;
    newData["drop_lng"] = cordinates.lng;
    setFormData(newData);
  }

  useEffect(() => {
    getUsers();
  }, []);

  function getUsersAssign(OrderFlag) {
    setLoading(true);
    setAsigneUserLoading(true);
    TripService.getTripeTravellerByTripIdData(tripId, OrderFlag).then(
      (data) => {
        setUsersDataAssign(data);
        if(OrderFlag=="pick_up_order"){
          setUsersDataPickUpAssign(data)

        }else{
          setUsersDataDropAssign(data)
        }
        setAsigneUserLoading(false);
      }
   
    );
    setLoading(false);
    setPickupAndDropUser();
  }
  function setPickupAndDropUser(){
    setLoading(true);
    setAsigneUserLoading(true);
    TripService.getTripeTravellerByTripIdData(tripId, "pick_up_order").then(
      (data) => {
    
          setUsersDataPickUpAssign(data)
      
      }
   
    );
    TripService.getTripeTravellerByTripIdData(tripId, "drop_order").then(
      (data) => {
   
          setUsersDataDropAssign(data)
      
        setAsigneUserLoading(false);
      }
   
    );
    setLoading(false);
  }
  useEffect(() => {
    getUsersAssign("pick_up_order");
  }, []);

  function checkUserExistsInTrip(userId) {
    const isExists = usersDataAssign.some(
      (travellers) => travellers.user_id === userId
    );
    if (isExists) return true;
    else return false;
  }

  function handleDeleteUsersInTrip(tripTravellerId) {
    setLoading(true);
    setResMsg("");
    setResSuccess(false);
    TripService.deleteDataUserTrip(tripTravellerId).then((res) => {
      if (res == "error") {
        setResMsg("Something went wrong");
      } else {
        if (res["status"] == true) {
          setResSuccess(true);
          getUsersAssign(fetchOrderFlag);
        }
        setResMsg(res["message"]);
      }
      handleShowStatusBox(true);
      setLoading(false);
    });
  }
  function handleChange(e) {
    const newData = { ...formData };
    if (e.target.id == "is_pick_up")
      newData[e.target.id] = formData[e.target.id] ? false : true;
    else if (e.target.id == "is_drop")
      newData[e.target.id] = formData[e.target.id] ? false : true;
    else newData[e.target.id] = e.target.value;
    setFormData(newData);
    console.log(newData);
  }
  function handleChangeForFilterBox(e) {
    const newData = { ...selectedFilter };
      newData[e.target.id] = selectedFilter[e.target.id] ? false : true;
      selectedFilter[e.target.id] = selectedFilter[e.target.id] ? false : true;
      setSlectedFilter(newData);
    //  console.log(newData);
    filterSelectedData();
    
    
  }
  function handleChangeSAFlag(e) {
    const newValue=!sameAddFlag
    setSameAddFlag(newValue);
    if(newValue){
      const newData = { ...formData };
      //formData['drop_lat']= formData['pick_up_lat'];
      newData['drop_lng']= formData.pick_up_lng;
      newData['drop_lat']= formData.pick_up_lat;
      newData['drop_building_number']= formData.pick_up_building_number;
      newData['drop_street_name']= formData.pick_up_street_name;
      newData['drop_area_name']= formData.pick_up_area_name;
      newData['drop_city']= formData.pick_up_city;
      newData['drop_pin_code']= formData.pick_up_pin_code;
  
      setFormData(newData);
    }else{
      const newData = { ...formData };
      //formData['drop_lat']= formData['pick_up_lat'];
      newData['drop_lng']= '';
      newData['drop_lat']= '';
      newData['drop_building_number']= '';
      newData['drop_street_name']= '';
      newData['drop_area_name']= '';
      newData['drop_city']= '';
      newData['drop_pin_code']= '';
  
      setFormData(newData);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    let isValidPBN=true;
    let isValidPSN=true;
    let isValidPAN=true;
    let isValidPCity=true;
    let isValidPinCode=true;
    let isValidPLat=true;
    let isValidPLng=true;

    let isValidDBN=true;
    let isValidDSN=true;
    let isValidDAN=true;
    let isValidDCity=true;
    let isValidDPinCode=true;
    let isValidDLat=true;
    let isValidDLng=true;
 


    if(formData.is_pick_up){
      const bNValidate=validation.checkTwoLengthValidation(formData.pick_up_building_number);
      if(bNValidate){
        isValidPBN=true;
          setPErrorsBN(null);
      }
          else{
            isValidPBN=false
            setPErrorsBN("Length must be greater than 1 letters");
          
          }
          const snValidate=validation.checkTwoLengthValidation(formData.pick_up_street_name);
          if(snValidate){
            isValidPSN=true;
              setPErrorsSN(null);
          }
              else{
                isValidPSN=false
                setPErrorsSN("Length must be greater than 2 letters");
              
              }
          const anValidate=validation.checkFourLengthValidation(formData.pick_up_area_name);
          if(anValidate){
            isValidPAN=true;
              setPErrorsAN(null);
          }
              else{
                isValidPAN=false
                setPErrorsAN("Length must be greater than 3 letters");
              }
              const cityValidate=validation.checkFourLengthValidation(formData.pick_up_city);
              if(cityValidate){
                isValidPCity=true;
                  setPErrorsCity(null);
              }
                  else{
                    isValidPCity=false
                    setPErrorsCity("Length must be greater than 3 letters");
                  }
              
                  const pcValidate=validation.checkPinCodeValidation(formData.pick_up_pin_code);
                  if(pcValidate){
                    isValidPinCode=true;
                      setPErrorsPicCode(null);
                  }
                      else{
                        isValidPinCode=false
                        setPErrorsPicCode("Input must be interger and Length must be 6 letters");
                      }
                      const latValidate=validation.checkLatValidation(formData.pick_up_lat);
                      if(latValidate){
                          isValidPLat=true;
                          setErrorsLat(null);
                      }
                          else{
                            isValidPLat=false
                              setErrorsLat("Enter valid latitude");
                          }
                          const lngValidate=validation.checkLngValidation(formData.pick_up_lng);
                          if(lngValidate){
                            isValidPLng=true;
                            setErrorsLng(null);
                          }
                              else{
                                  isValidPLng=false
                                  setErrorsLng("Enter valid longitude");
                              
                              }
                  
    }

     if(formData.is_drop){
      const bNValidate=validation.checkTwoLengthValidation(formData.drop_building_number);
      if(bNValidate){
        isValidDBN=true;
          setDErrorsBN(null);
      }
          else{
            isValidDBN=false
            setDErrorsBN("Length must be greater than 1 letters");
          
          }
     
          const snValidate=validation.checkFourLengthValidation(formData.drop_street_name);
          if(snValidate){
            isValidDSN=true;
            setDErrorsSN(null);
          }
              else{
                isValidDSN=false
                setDErrorsSN("Length must be greater than 3 letters");
              
              }
          const anValidate=validation.checkFourLengthValidation(formData.drop_area_name);
          if(anValidate){
            isValidDAN=true;
              setDErrorsAN(null);
          }
              else{
                isValidDAN=false
                setDErrorsAN("Length must be greater than 3 letters");
              }
              const cityValidate=validation.checkFourLengthValidation(formData.drop_city);
              if(cityValidate){
                isValidDCity=true;
                  setDErrorsCity(null);
              }  else{
                    isValidDCity=false
                    setDErrorsCity("Length must be greater than 3 letters");
                  }

                  const pcValidate=validation.checkPinCodeValidation(formData.drop_pin_code);
                  if(pcValidate){
                    isValidDPinCode=true;
                    setDErrorsPicCode(null);
                  }
                      else{
                        isValidDPinCode=false
                        setDErrorsPicCode("Input must be interger and Length must be 6 letters");
                      }
                      const latValidate=validation.checkLatValidation(formData.drop_lat);
                      if(latValidate){
                          isValidDLat=true;
                          setErrorsDLat(null);
                      }
                          else{
                            isValidDLat=false
                            setErrorsDLat("Enter valid latitude");
                          }
                          const lngValidate=validation.checkLngValidation(formData.drop_lng);
                          if(lngValidate){
                            isValidDLng=true;
                            setErrorsDLng(null);
                          }
                              else{
                                isValidDLng=false
                                  setErrorsDLng("Enter valid longitude");
                              
                              }
                  
    }
    if(isValidPBN==true&&isValidPSN==true&&isValidPAN==true&& isValidPCity==true&& isValidPinCode==true&& isValidPLat==true&& isValidPLng==true
      && isValidDBN==true
      && isValidDSN==true
      && isValidDAN==true
      && isValidDCity==true
      && isValidDPinCode==true
      && isValidDLat==true
      && isValidDLng==true
      ){
          setLoading(true);
    setResMsg("");
    setResSuccess(false);


    TripService.addDataUserTrip(
      selectedTraveller.trip_traveller_id,
      selectedTraveller.user_id,
      tripId,
      formData.is_pick_up,
      formData.is_drop,
      formData.pick_up_building_number,
      formData.pick_up_street_name,
      formData.pick_up_area_name,
      formData.pick_up_pin_code,
      formData.pick_up_city,
      formData.pick_up_lat,
      formData.pick_up_lng,
      formData.drop_building_number,
      formData.drop_street_name,
      formData.drop_area_name,
      formData.drop_pin_code,
      formData.drop_city,
      formData.drop_lat,
      formData.drop_lng
    ).then((res) => {
      if (res == "error") {
        setResMsg("Something went wrong");
      } else {
        if (res["status"] == true) {
          handleClose();
          setResSuccess(true);
        }
        setResMsg(res["message"]);
        getUsersAssign(fetchOrderFlag);
      }

      handleShowStatusBox();
      setLoading(false);
    });
      }


  
  }
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    if (fetchOrderFlag == "pick_up_order") {  
      let destinationPickUpOrder =
        usersDataAssign[result.destination.index].pick_up_order;
        let sourcePickUpOrder =
        usersDataAssign[result.source.index].pick_up_order;
      if (destinationPickUpOrder == null) return;
      usersDataAssign[result.source.index].pick_up_order =
        destinationPickUpOrder;

      let tempData = [...usersDataAssign];
      let [selectedRow] = tempData.splice(result.source.index, 1);
      tempData.splice(result.destination.index, 0, selectedRow);
    
      if(destinationPickUpOrder<sourcePickUpOrder)
    {
      for (var i = result.destination.index + 1; i < tempData.length; i++) {
    
        destinationPickUpOrder = destinationPickUpOrder + 1;
    
         console.log(destinationPickUpOrder);
         tempData[i].pick_up_order = destinationPickUpOrder;
       }
    }
    else if(destinationPickUpOrder>sourcePickUpOrder){
  
      for (var i = result.destination.index - 1; i >= 0; i--) {
        console.log("index",i);
        destinationPickUpOrder = destinationPickUpOrder -1;
  
         console.log(destinationPickUpOrder);
         tempData[i].pick_up_order = destinationPickUpOrder;
       }

    }
    
      setUsersDataAssign(tempData);
    } else if (fetchOrderFlag == "drop_order") {
      let destinationPickUpOrder =
        usersDataAssign[result.destination.index].drop_order;
        let sourcePickUpOrder =
        usersDataAssign[result.source.index].drop_order;
      if (destinationPickUpOrder == null) return;
      usersDataAssign[result.source.index].drop_order = destinationPickUpOrder;
      let tempData = [...usersDataAssign];
      let [selectedRow] = tempData.splice(result.source.index, 1);
      tempData.splice(result.destination.index, 0, selectedRow);
      if(destinationPickUpOrder<sourcePickUpOrder)
      {
        for (var i = result.destination.index + 1; i < tempData.length; i++) {
      
          destinationPickUpOrder = destinationPickUpOrder + 1;
      
           console.log(destinationPickUpOrder);
           tempData[i].drop_order = destinationPickUpOrder;
         }
      }
      else if(destinationPickUpOrder>sourcePickUpOrder){
    
        for (var i = result.destination.index - 1; i >= 0; i--) {
          console.log("index",i);
          destinationPickUpOrder = destinationPickUpOrder -1;
    
           console.log(destinationPickUpOrder);
           tempData[i].drop_order = destinationPickUpOrder;
         }
  
      }
    
      setUsersDataAssign(tempData);
    }

    // console.log( usersDataAssign);
  };
  function handleOrderBtn(order) {
    setFetchOrderFlag(order);
    getUsersAssign(order);
  }
  function handleUpdateOrder() {
    setLoading(true);
    setResMsg("");
    setResSuccess(false);
    TripService.updateOrder(usersDataAssign).then((res) => {
      if (res == "error") {
        setResMsg("Something went wrong");
      } else {
        if (res["status"] == true) {
          setResSuccess(true);
        }
        setResMsg(res["message"]);
        getUsersAssign(fetchOrderFlag);
      }

      handleShowStatusBox();
      setLoading(false);
    });
  }
  function initFilterSelectedData(data) {
      setFilterData(data.filter((item)=>
      {
        for(var i=0;i<item.role.length;i++){
        if(item.role[i].role_id==6)
        return true;
        }
      }   
      ));
  
  }
  
 function  handleEditeAssign(p){
 

  setSelectedTravellerData({
    trip_traveller_id: p.id,
    user_id: p.user_id,
    first_name:  p.first_name,
    last_name: p.last_name,
  });

  setShow(true);
  const newData = { ...formData };
  newData['pick_up_lat']= p.pick_up_lat??"";
  newData['pick_up_lng']= p.pick_up_lng??"";
  newData['pick_up_building_number']= p.pick_up_building_number??"";
  newData['pick_up_street_name']= p.pick_up_street_name??"";
  newData['pick_up_area_name']= p.pick_up_area_name??"";
  newData['pick_up_city']= p.pick_up_city??"";
  newData['pick_up_pin_code']= p.pick_up_pin_code??"";
  newData['drop_building_number']= p.drop_building_number??"";
  newData['drop_street_name']= p.drop_street_name??"";
  newData['drop_area_name']= p.drop_area_name??"";
  newData['drop_city']= p.drop_city??"";
  newData['drop_pin_code']= p.drop_pin_code??"";
  newData['drop_lat']= p.drop_lat??"";
  newData['drop_lng']= p.drop_lng??"";
  newData['is_pick_up']= p.is_pick_up??"";
  newData['is_drop']= p.is_drop??"";
  setFormData(newData);
  setPErrorsBN(null);
  setPErrorsSN(null);
  setPErrorsAN(null);
  setPErrorsCity(null);
  setPErrorsPicCode(null);
  setErrorsLat(null);
  setErrorsLng(null);

  setDErrorsBN(null);
  setDErrorsSN(null);
  setDErrorsAN(null);
  setDErrorsCity(null);
  setDErrorsPicCode(null);
  setErrorsDLat(null);
  setErrorsDLng(null);
  //xxx
  // {p.first_name} {p.last_name}
  // </td>
  // <td>{p.pick_up_area_name}</td>
  // {/* <td>{p.pick_up_city}</td> */}
  // <td>{p.drop_area_name}</td>
  // {/* <td>{p.drop_city}</td> */}
  // <td>{p.pick_up_order}</td>
  // <td>{p.drop_order}</td>

  }
  function filterSelectedData() {
    
    var searchText=searchTextInput.text;
     console.log(searchText);
      setFilterData(usersData.filter((item)=>
      {
        for(var i=0;i<item.role.length;i++){
          if(selectedFilter.student==true&&selectedFilter.emp==true)
        { console.log("case 1")
        if(searchText==""){
             if(item.role[i].role_id!=1&&item.role[i].role_id!=8&&item.role[i].role_id!=7)
            return true;}
           else if(searchText!=""){
            const query =searchText.toLowerCase().replace(/ /g,'');
           var searchItem=(item.first_name+item.last_name).toLowerCase().replace(/ /g,'');
              if(item.role[i].role_id!=1&&item.role[i].role_id!=8&&item.role[i].role_id!=7&& searchItem.indexOf(query) >= 0)
             return true;}
          }

            else if(selectedFilter.student==true&&selectedFilter.emp==false)
            {
              console.log("case 2")
              if(searchText==""){
                if(item.role[i].role_id==6)
               return true;}
              else if(searchText!=""){
               const query =searchText.toLowerCase().replace(/ /g,'');
              var searchItem=(item.first_name+item.last_name).toLowerCase().replace(/ /g,'');
                 if(item.role[i].role_id==6&& searchItem.indexOf(query) >= 0)
                return true;}
            }
             else if(selectedFilter.student==false&&selectedFilter.emp==true)
             {
              console.log("case 3")

              if(searchText==""){
                if(item.role[i].role_id!=1&&item.role[i].role_id!=8&&item.role[i].role_id!=7&&item.role[i].role_id!=6)
               return true;}
              else if(searchText!=""){
               const query =searchText.toLowerCase().replace(/ /g,'');
              var searchItem=(item.first_name+item.last_name).toLowerCase().replace(/ /g,'');
                 if(item.role[i].role_id!=1&&item.role[i].role_id!=8&&item.role[i].role_id!=7&&item.role[i].role_id!=6&& searchItem.indexOf(query) >= 0)
                return true;}
            
            }
            
           
        }
  
    
      }   
      ));
    
  
  }
  function handlSearcheChange(e) {
    var newValue=e.target.value
    // setSearchTextInput({text:newValue});
    searchTextInput['text']=newValue;
      //setSlectedFilter({student: false, emp: false});
     filterSelectedData()
   

    // if (e.target.value != ""){
    //   setFilterData(usersData.filter((item)=>
    //   {
    //     const query =e.target.value.toLowerCase().replace(/ /g,'');
    //     var searchItem=(item.first_name+item.last_name).toLowerCase().replace(/ /g,'');
    
    //     for(var i=0;i<item.role.length;i++){

    //          if(item.role[i].role_id!=1&&item.role[i].role_id!=8&&item.role[i].role_id!=7&& searchItem.indexOf(query) >= 0)
    //         return true;
          
    //     }
        

    //   }   
    //   ));
     
    // }
  
    // else getUsers();
  }
  
  return (

    <div className="TripTraveler">
      <Modal
        dialogClassName="map-modal"
        show={showGoogleMapBox}
        onHide={handleCloseGoogleMapBox}
        className="alert-box"
      >
        <Modal.Header closeButton>
          <Modal.Title>Mark Location</Modal.Title>
        </Modal.Header>
        <Modal.Body className="alert-box-body">
        {pickMapForDrop?<GoogleMapComponent 
        schoolLat={schoolLat}
        schoolLng={schoolLng}
        returnCordinates={setMapLatLng} />:<GoogleMapComponent
        schoolLat={schoolLat}
        schoolLng={schoolLng}
        returnCordinates={setMapLatLng} />}  
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleSubmitGoogleMap()}>
            Submit
          </Button>
          <Button variant="secondary" onClick={() => handleCloseGoogleMapBox()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
       
        show={showStatusBox}
        onHide={handleCloseStatusBox}
        className="alert-box"
      >
        <Modal.Header closeButton>
          <Modal.Title
            className={
              resSuccess == false ? "alert-box-title-error" : "alert-box-title"
            }
          >
            Response
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="alert-box-body">{resMsg}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCloseStatusBox()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal 
       dialogClassName="form-modal"
      show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedTraveller.first_name} {selectedTraveller.last_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="Modal-Form" onSubmit={(e) => handleSubmit(e)}>
            <Row>
              <Col>
              <Form.Group className="mb-3">
              <Form.Check
                checked={formData.is_pick_up}
                onChange={(e) => handleChange(e)}
                key="is_pick_up"
                type="checkbox"
                id="is_pick_up"
                label={"Pick Up"}
              />
            </Form.Group>
            <Button className="map-btn" disabled={!formData.is_pick_up} onClick={()=>handleShowGoogleMapBox(false)}>Pick From Map</Button>
            <br/>
            <br/>
            <Row className="mb-3">
                <Form.Group  as={Col}>
                  <Form.Label>Latitude*</Form.Label>
                  <Form.Control
                       isInvalid={!!pErrorsLat}
                    required
                    disabled={formData.is_pick_up ? false : true}
                    onChange={(e) => handleChange(e) }
                    type="text"
                    placeholder=""
                    name="pick_up_lat"
                    id="pick_up_lat"
                    value={formData.pick_up_lat}
                  />
                    <Form.Control.Feedback type="invalid">{pErrorsLat}</Form.Control.Feedback>
                </Form.Group>{" "}
     
              <Form.Group  as={Col}>
              <Form.Label>Longitude *</Form.Label>
              <Form.Control
                isInvalid={!!pErrorsLng}
                required
                disabled={formData.is_pick_up ? false : true}
                onChange={(e) => handleChange(e)}
                type="text"
                placeholder=""
                name="pick_up_lng"
                id="pick_up_lng"
                value={formData.pick_up_lng}
              />
              <Form.Control.Feedback type="invalid">{pErrorsLng}</Form.Control.Feedback>

            </Form.Group >
           
            </Row >
            <Row className="mb-3">
                <Form.Group  as={Col}>
                  <Form.Label>Building Number*</Form.Label>
                  <Form.Control
                   isInvalid={!!pErrorsBN}
                    required
                    disabled={formData.is_pick_up ? false : true}
                    onChange={(e) => handleChange(e)}
                    type="text"
                    placeholder="Building Number"
                    name="pick_up_building_number"
                    id="pick_up_building_number"
                    value={formData.pick_up_building_number}
                  />
             <Form.Control.Feedback type="invalid">{pErrorsBN}</Form.Control.Feedback>

                </Form.Group>{" "}
     
              <Form.Group  as={Col}>
              <Form.Label>Streent Name*</Form.Label>
              <Form.Control
                isInvalid={!!pErrorsSN}
                required
                disabled={formData.is_pick_up ? false : true}
                onChange={(e) => handleChange(e)}
                type="text"
                placeholder="Street Name"
                name="pick_up_street_name"
                id="pick_up_street_name"
                value={formData.pick_up_street_name}
              />
               <Form.Control.Feedback type="invalid">{pErrorsSN}</Form.Control.Feedback>

            </Form.Group >
           
            </Row >
            <Row className="mb-3">
        
            <Form.Group  as={Col} >
              <Form.Label>Area Name*</Form.Label>
              <Form.Control
                isInvalid={!!pErrorsAN}
                required
                disabled={formData.is_pick_up ? false : true}
                onChange={(e) => handleChange(e)}
                type="text"
                placeholder="Area"
                name="pick_up_area_name"
                id="pick_up_area_name"
                value={formData.pick_up_area_name}
                />
              <Form.Control.Feedback type="invalid">{pErrorsAN}</Form.Control.Feedback>
            </Form.Group> 
            <Form.Group as={Col}>
              <Form.Label>City*</Form.Label>
              <Form.Control
               isInvalid={!!pErrorsCity}
                required
                disabled={formData.is_pick_up ? false : true}
                onChange={(e) => handleChange(e)}
                type="text"
                placeholder="City"
                name="pick_up_city"
                id="pick_up_city"
                value={formData.pick_up_city}
              />
               <Form.Control.Feedback type="invalid">{pErrorsCity}</Form.Control.Feedback>
            </Form.Group>
            </Row>
            <Row className="mb-3">
            <Form.Group  as={Col} >
              <Form.Label>Pin Code*</Form.Label>
              <Form.Control
                isInvalid={!!pErrorsPicCode}
                required
                disabled={formData.is_pick_up ? false : true}
                onChange={(e) => handleChange(e)       }
                type="text"
                placeholder="Pin Code"
                name="pick_up_pin_code"
                id="pick_up_pin_code"
                value={formData.pick_up_pin_code}
                />
                 <Form.Control.Feedback type="invalid">{pErrorsPicCode}</Form.Control.Feedback>
            </Form.Group>
      
            </Row>
              </Col>
              <Col sm={1}>
              <div className="verical-divider">
                <div className="line"></div>
              </div>
              </Col>
              <Col>
                <Row>
              <Form.Group  as={Col}>
              <Form.Check
                checked={formData.is_drop}
                onChange={(e) => handleChange(e)}
                key="is_drop"
                type="checkbox"
                id="is_drop"
                label="Drop"
                />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Check
                  disabled={!formData.is_drop}
                checked={sameAddFlag}
                onChange={(e) => handleChangeSAFlag(e)}
                key="same_add_flag"
                type="checkbox"
                id="same_add_flag"
                label="Same As Pick Up"
                />
            </Form.Group>
                </Row>
            
            <Button className="map-btn"  disabled={!formData.is_drop} onClick={()=>handleShowGoogleMapBox(true)}>Pick From Map</Button>
            <br/>
            <br/>
              <Row className="mb-3">
          <Form.Group  as={Col}>
            <Form.Label>Latitude*</Form.Label>
            <Form.Control
                isInvalid={!!dErrorsLat}
              required
              disabled={formData.is_drop ? false : true}
              onChange={(e) => handleChange(e)}
              type="text"
              placeholder=""
              name="drop_lat"
              id="drop_lat"
              value={formData.drop_lat}
            />
            <Form.Control.Feedback type="invalid">{dErrorsLat}</Form.Control.Feedback>

          </Form.Group>{" "}

        <Form.Group  as={Col}>
        <Form.Label>Longitude *</Form.Label>
        <Form.Control
                isInvalid={!!dErrorsLng}
          required
          disabled={formData.is_drop ? false : true}
          onChange={(e) => handleChange(e)}
          type="text"
          placeholder=""
          name="drop_lng"
          id="drop_lng"
          value={formData.drop_lng}
        />
          <Form.Control.Feedback type="invalid">{dErrorsLng}</Form.Control.Feedback>
      </Form.Group >
     
      </Row >
            <Row className="mb-3">
                <Form.Group  as={Col}>
                  <Form.Label>Building Number*</Form.Label>
                  <Form.Control
                          isInvalid={!!dErrorsBN}
                    required
                    disabled={formData.is_drop ? false : true}
                    onChange={(e) => handleChange(e)}
                    type="text"
                    placeholder="Building Number"
                    name="drop_building_number"
                    id="drop_building_number"
                    value={formData.drop_building_number}
                  />
                            <Form.Control.Feedback type="invalid">{dErrorsBN}</Form.Control.Feedback>

                </Form.Group>
     
              <Form.Group  as={Col}>
              <Form.Label>Streent Name*</Form.Label>
              <Form.Control
                   isInvalid={!!dErrorsSN}
                required
                disabled={formData.is_drop ? false : true}
                onChange={(e) => handleChange(e)}
                type="text"
                placeholder="Street Name"
                name="drop_street_name"
                id="drop_street_name"
                value={formData.drop_street_name}
              />
            <Form.Control.Feedback type="invalid">{dErrorsSN}</Form.Control.Feedback>

            </Form.Group >
           
            </Row >
            <Row className="mb-3">
            <Form.Group  as={Col} >
              <Form.Label>Area Name*</Form.Label>
              <Form.Control
                  isInvalid={!!dErrorsAN}
                required
                disabled={formData.is_drop ? false : true}
                onChange={(e) => handleChange(e)}
                type="text"
                placeholder="Area"
                name="drop_area_name"
                id="drop_area_name"
                value={formData.drop_area_name}
                />
                  <Form.Control.Feedback type="invalid">{dErrorsAN}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>City*</Form.Label>
              <Form.Control
                isInvalid={!!dErrorsCity}
                required
                disabled={formData.is_drop ? false : true}
                onChange={(e) => handleChange(e)}
                type="text"
                placeholder="City"
                name="drop_city"
                id="drop_city"
                value={formData.drop_city}
              />
              <Form.Control.Feedback type="invalid">{dErrorsCity}</Form.Control.Feedback>
            </Form.Group>
            </Row>

            <Row className="mb-3">
            <Form.Group  as={Col} >
              <Form.Label>Pin Code*</Form.Label>
              <Form.Control
                 isInvalid={!!dErrorsPicCode}
                required
                disabled={formData.is_drop ? false : true}
                onChange={(e) => handleChange(e)       }
                type="text"
                placeholder="Area"
                name="drop_pin_code"
                id="drop_pin_code"
                value={formData.drop_pin_code}
                />
                <Form.Control.Feedback type="invalid">{dErrorsPicCode}</Form.Control.Feedback>

            </Form.Group>
      
            </Row>
              </Col>
            </Row>
            <br/>
            <Button
              className="btn"
              variant="secondary"
              size="sm"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button variant="primary" size="sm" type="submit">
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
  
      <div className="second-section">
      <div className="add-traveler">
        <div className="traveler-header">
          Assigned Users
          <div className="op-section">
            <Button
              className={
                fetchOrderFlag == "pick_up_order"
                  ? "header-btn selected-btn"
                  : "header-btn"
              }
              onClick={() => handleOrderBtn("pick_up_order")}
              bg="primary"
              size="sm"
            >
              Pick Up
            </Button>
            <Button
              className={
                fetchOrderFlag == "drop_order"
                  ? "header-btn selected-btn"
                  : "header-btn"
              }
              onClick={() => handleOrderBtn("drop_order")}
              bg="primary"
              size="sm"
            >
              Drop
            </Button>
            <Button
              className="update-btn"
              onClick={() => handleUpdateOrder()}
              bg="primary"
              size="sm"
            >
              Update Order
            </Button>
          </div>
        </div>
        <div className="filter-section">
          </div>

        <div className="list-group">
          <DragDropContext onDragEnd={(result) => handleDragEnd(result)}>
            <Table striped className="body-table">
              <thead>
                <tr>
                  <th>Drag-Drop</th>
                  <th>#</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Pick Area</th>
                  {/* <th>Pick City</th> */}
                  <th>Drop Area</th>
                  {/* <th>Drop City</th> */}
                  <th>Pick Up Order</th>
                  <th>Drop Order</th>
                  {/* <th>Assign Date</th> */}
                  <th>Option</th>
                </tr>
              </thead>
              <Droppable droppableId="dropable-0">
                {(provided) => (
                  <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {   usersDataAssign.length == 0
                      ? "No Data Found"
                      : usersDataAssign &&
                        usersDataAssign.map((p, index) => {
                          let dateAndTimeC = "";
                          let dateAndTimeU = "";

                          dateAndTimeC = moment(p.created_at)
                            .utc()
                            .format("YYYY-MM-DD hh:mm:ss");
                          dateAndTimeU = moment(p.updated_at)
                            .utc()
                            .format("YYYY-MM-DD hh:mm:ss");
                          return (
                            <Draggable
                              key={p.user_id}
                              index={index}
                              draggableId={p.id.toString()}
                            >
                              {(provided) => (
                                <tr
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <td {...provided.dragHandleProps}>=</td>
                                  <td>{index + 1}</td>
                                  <td>
                                {  p.image!=""&& p.image!=null?
             <RoundedImageTable imageUrl={'https://schoob.net/busappapifiles/public/uploads/images/'+p.image}/>:
  
     <      > <i className="fa fa-image fa-sm" aria-hidden="true"></i></> }
                                 
                                    {/* <RoundedImageTable imageUrl="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" /> */}
                                  </td>
                                  <td>
                                    {p.first_name} {p.last_name}
                                  </td>
                                  <td>{p.pick_up_area_name}</td>
                                  {/* <td>{p.pick_up_city}</td> */}
                                  <td>{p.drop_area_name}</td>
                                  {/* <td>{p.drop_city}</td> */}
                                  <td>{p.pick_up_order}</td>
                                  <td>{p.drop_order}</td>
                                  {/* <td>{dateAndTimeU}</td> */}
                                  <td>
                                  <button
                         onClick={() => handleEditeAssign(p)}
                        className="btn btn-edit"
                      >
                        {" "}
                        <i className="fa fa-edit fa-sm" aria-hidden="true"></i>
                      </button>{" "}
                                    <button
                                      onClick={() =>
                                        handleDeleteUsersInTrip(p.id)
                                      }
                                      className="btn btn-delete"
                                    >
                                      {" "}
                                      <i
                                        className="fa fa-trash fa-sm"
                                        aria-hidden="true"
                                      ></i>
                                    </button>{" "}
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          );
                        })}

                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        </div>
      </div>
      <div className="user-list">
        <div className="traveler-header">
          {schooTitle} Students
          <div className="op-section">
            <Form.Control
              required
              name="search"
              id="search"
              onChange={(e) => handlSearcheChange(e)}
              type="text"
              placeholder="Search..."
              className="form-control"
            />
          </div>
          <div className="filter-section">
          <Form.Group className="check-box">
              <Form.Check
                checked={selectedFilter.student}
                onChange={(e) => handleChangeForFilterBox(e)}
                key="student"
                type="checkbox"
                id="student"
                label={"Student"}
              />
            </Form.Group>

            <Form.Group className="check-box">
              <Form.Check
                checked={selectedFilter.emp}
                onChange={(e) => handleChangeForFilterBox(e)}
                key="emp"
                type="checkbox"
                id="emp"
                label={"Employee"}
              />
            </Form.Group>
            

          </div>
        </div>
        <div className="list-group">
          <ListGroup as="ol" numbered>
            {usersData.length == 0
              ? "No Data Found"
              : filterData &&
              filterData.map((p) => {
                  return (
                    <div key={p.id}>
                      {checkUserExistsInTrip(p.id) ? (
                        <></>
                      ) : (
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-start"
                        >
                          <div className="ms-2 me-auto">
                            <div className="fw-bold">
                              {/* {usersDataAssign.con} */}
                              {p.first_name} {p.last_name}
                            </div>
                            {p.address}
                          </div>
                          <Button
                            onClick={() =>
                              setSelectedTraveller(
                                p.id,
                                p.first_name,
                                p.last_name,
                                p.pick_up_building_number,
                                p.pick_up_street_name,
                                p.pick_up_area_name,
                                p.pick_up_city,
                                p.pick_up_pin_code,
                                p.pick_up_lat,
                                p.pick_up_lng,
                                p.drop_building_number,
                                p.drop_street_name,
                                p.drop_area_name,
                                p.drop_city,
                                p.drop_pin_code,
                                p.drop_lat,
                                p.drop_lng,

                              )
                            }
                            bg="primary"
                            size="sm"
                          >
                            +
                          </Button>
                        </ListGroup.Item>
                      )}
                    </div>
                  );
                })}
          </ListGroup>
        </div>
      </div>
      </div>
     <div className="first-section"> 
     {schoolLat==null||schoolLng==null?
     <>Update School Latitude and  Longitude to show google map</>:
      !isAssignUserLoading&&usersDataPickUpAssign.length>0? 
      <GoogleMapRoute 
      allLocation={usersDataPickUpAssign} 
      schoolLat={schoolLat}
      schoolLng={schoolLng}
      schoolName={schooTitle}
            />
      :<></>}
        <div className='center-box'></div>
      
      {schoolLat==null||schoolLng==null?
      <></>
      :!isAssignUserLoading&&usersDataDripAssign.length>0? 
      <GoogleMapRouteDrop 
      allLocation={usersDataDripAssign} 
      schoolLat={schoolLat}
      schoolLng={schoolLng}
      schoolName={schooTitle}/>
      :<></>}
      </div> 
    
    </div>
  );

  function setSelectedTraveller(user_id, first_name, last_name,
    pick_up_building_number,
    pick_up_street_name,
    pick_up_area_name,
    pick_up_city,
    pick_up_pin_code,
    pick_up_lat,
    pick_up_lng,
    drop_building_number,
    drop_street_name,
    drop_area_name,
    drop_city,
    drop_pin_code,
    drop_lat,
    drop_lng
  
    ) {
    setSelectedTravellerData({
      trip_traveller_id:"",
      user_id: user_id,
      first_name: first_name,
      last_name: last_name,
    });
    const newData = { ...formData };
    
    newData['pick_up_lat']= pick_up_lat??"";
    newData['pick_up_lng']= pick_up_lng??"";
    newData['pick_up_building_number']= pick_up_building_number??"";
    newData['pick_up_street_name']=pick_up_street_name??"";
    newData['pick_up_area_name']=pick_up_area_name??"";
    newData['pick_up_city']=pick_up_city??"";
    newData['pick_up_pin_code']= pick_up_pin_code??"";

    
    newData['drop_building_number']= drop_building_number??"";
    newData['drop_street_name']= drop_street_name??"";
    newData['drop_area_name']= drop_area_name??"";
    newData['drop_city']= drop_city??"";
    newData['drop_pin_code']= drop_pin_code??"";
    newData['drop_lat']=drop_lat??"";
    newData['drop_lng']= drop_lng??"";
    newData['is_pick_up']= 1;
    newData['is_drop']= 0;
    setPErrorsBN(null);
    setPErrorsSN(null);
    setPErrorsAN(null);
    setPErrorsCity(null);
    setPErrorsPicCode(null);
    setErrorsLat(null);
    setErrorsLng(null);

    setDErrorsBN(null);
    setDErrorsSN(null);
    setDErrorsAN(null);
    setDErrorsCity(null);
    setDErrorsPicCode(null);
    setErrorsDLat(null);
    setErrorsDLng(null);


  
    setFormData(newData);
    setShow(true);
  }
  function urlPatternValidation (value) {
    const regex = new RegExp('/^[0-9\b]+$/');    
    return regex.test(value);
  };
}
export default TripTraveler;
