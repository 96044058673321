
import "./headerSubTitle.css";
function HeaderSubTitle({title}) {
    return (
      <div className="HeaderSubTitle">
         <span className="icon">
            <i className="fa fa-arrow-circle-o-right fa-2x" aria-hidden="true"></i>
          </span>
          <span className="title">{title}</span>
                 
      </div>
    ); 
  }
  export default HeaderSubTitle;