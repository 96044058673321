import "./roundedImage.css"

function RoundedImage({imageUrl}){
    return(
        <section className="RoundedImage">
      <img  src={imageUrl==null||imageUrl==''?'':imageUrl}
      alt="No Image"/>
      </section>
    );
}
export default RoundedImage;