import "./adminProfile.css"
import axios from "axios";
function AdminProfile(){
    const firstName=localStorage.getItem('firstName');
    const lastName=localStorage.getItem('lastName');

    return(
        <section className="AdminProfile">
     <img alt="Avatar" src="https://media.istockphoto.com/photos/headshot-portrait-of-smiling-male-employee-in-office-picture-id1309328823?b=1&k=20&m=1309328823&s=170667a&w=0&h=a-f8vR5TDFnkMY5poQXfQhDSnK1iImIfgVTVpFZi_KU="></img>
        <section className="admin-name">
        <p>{firstName} {lastName}</p>
        <p className="small-text">Last Seen</p>
        </section>
          </section>
    );
}
export default AdminProfile;