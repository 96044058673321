import Table from 'react-bootstrap/Table';
import React from "react";
import LoadingCompinent from '../../components/loading/loadingComponent';
import TabelOperation from '../../components/tableOperation/tableOpeartion';
import "../../common/bodyTable.css"
import RoundedImageTable from"../../components/roundedImages/roundedImage"
import SchoolServices from '../../services/schoolServices';
import moment from "moment";
import { Link } from 'react-router-dom';
import HeaderSubTitle from "../../header/headerSubTitle";
import BusesService from '../../services/busesService';
import TripService from '../../services/tripService';
import { useState,useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import { useMemo } from 'react';

function TripHistory(){
  const schoolId=localStorage.getItem('schoolId');
  const [items,setData]=useState([]);
  const [isLoading,setIsLoading]=useState(false);
  const [showCBox, setShowCBox] = useState(false);
  const [show, setShow] = useState(false);
  const [resMsg,setResMsg]=useState("");
  const [selectedTitle,setSelectedTitle]=useState("");
  const [selectedItemId,setSelectedItemId]=useState();
  const [resSuccess, setResSuccess] = useState(false);
  const [gridApi,setGridApi]=useState(null);
  const [gridCollomApi,setGridCollomApi]=useState(null);
  const[columnDefs,setColumnDefs]=useState([


    {headerName: "#",valueGetter:'node.rowIndex+1',width: 70,suppressSizeToFit: false},
    {headerName: "Trip Name", field: "trip_title",  minWidth: 50, maxWidth: 200,suppressSizeToFit: false,},
    {headerName: "Trip", field: "is_pick_up",  minWidth: 50, maxWidth: 200,suppressSizeToFit: false,
    cellRendererFramework:(params)=><div>{getStatusTrip(params.data.is_pick_up)}
    </div>
  },

     {headerName: "Status", field: "status",
      minWidth: 50, maxWidth: 100,suppressSizeToFit: false,
      cellRendererFramework:(params)=><div>{getStatusTitle(params.data.status)}
    </div>
      //getStatusTitle
   },
     {headerName: "Start Date", field: "start_date",  minWidth: 70, maxWidth: 200,suppressSizeToFit: false,},
     {headerName: "Start Times", field: "start_time",  minWidth: 50, maxWidth: 200,suppressSizeToFit: false,},
     {headerName: "End Date", field: "end_date",  minWidth: 50, maxWidth: 100,suppressSizeToFit: false,},
     {headerName: "End Time", field: "end_time",  minWidth: 50, maxWidth: 100,suppressSizeToFit: false,},
     {headerName: "Assign Date", field: 'created_at',
   minWidth: 50, maxWidth: 200,suppressSizeToFit: false,
   cellRendererFramework:(params)=><>{moment(params).utc().format('YYYY-MM-DD hh:mm:ss')}</>  
 },
   {headerName: "Action", field: 'id' ,
   minWidth: 50, maxWidth: 200,suppressSizeToFit: false,
   cellRendererFramework:(params)=><div className='body-table-action'>
   <Link to={'/UpdateTripHistory/'+params.data.id}>
                   <button className='btn btn-edit'> <i className="fa fa-edit fa-sm" aria-hidden="true"></i></button >{' '}
                 </Link>
                   <button className='btn btn-delete'
                                    onClick={() =>{ 
                                     setSelectedItemId(params.data.id);
                                     setSelectedTitle(params.data.title);
                                     handleShowCBox();}}
                   > <i className="fa fa-trash fa-sm" aria-hidden="true"></i></button >{' '}</div>
 },
 
    
     
 ])

 const defaultColDef = useMemo(()=>({

     enableRowGroup:true ,
     sortable:true,
     filter:true,
     //floatingFilter:true
   }))
   function handleSearchChanges(e){
     // console.log(e.target.value);
      gridApi.setQuickFilter(e.target.value)
    }
    function handlePerPageSelect(e){
      gridApi.paginationSetPageSize(e.target.value)
    }
    
    function onGridReady(params){
      setGridApi(params.api);
      params.api.sizeColumnsToFit(); 
      getTripData(params);
    }
    function exportDataAsCSC(){
      gridApi.exportDataAsCsv()
    }

  // useEffect(() => {
  //   getTripData();
       
  //   }, []);
    function handleClose() {
      setShow(false);
    }
    const handleShow = () => setShow(true);
    function handleCloseCBox() {
      setShowCBox(false);
      // if (resSuccess == true)
      //     navigate(-1)
    }
    const handleShowCBox = () => setShowCBox(true);
    function getTripData(params){
      // setIsLoading(true);
      
      TripService.getHistoryBySchoolId(schoolId)
      .then((data) => {
        if(!data.length){
          params.api.showNoRowsOverlay();
        }else{
          params.api.hideOverlay();
        }
        // setData(data);
        params.api.applyTransaction({add:data})
        // setIsLoading(false);
          })
      // TripService.getHistoryBySchoolId(schoolId)
      // .then((data) => {
      //   setData(data);
      //   // setIsLoading(false);
      //     })
    }
    function getStatusTitle(status){
        switch(status){
          case 1: return "Preparing";
          case 2: return "Started";
          case 3: return "Finished";
          default: return "--";
        }
      
      }
      function getStatusTrip(status){
        switch(status){
          case 1: return "Pick Up";
          case 0: return "Drop";
          default: return "--";
        }
      
      }
    function handleDeleteData(){
      handleCloseCBox();
      setIsLoading(true);
      setResMsg('')
      setResSuccess(false);
    
      TripService.delete(selectedItemId).then((res) => {
    
                  if (res == 'error') {
                      setResMsg('Something went wrong')
                  } else {
                      if (res['status'] == true) { setResSuccess(true); 
                      //    getTripData();
                      }
                      setResMsg(res['message'])
                  }
                  handleShow();
      
                  setIsLoading(false);
              });
          
    
    }

    return (
      <>
      <Modal show={show} onHide={handleClose} className='alert-box'>
                <Modal.Header closeButton>
                    <Modal.Title className={resSuccess == false ? 'alert-box-title-error' : 'alert-box-title'}>Response</Modal.Title>
                </Modal.Header>
                <Modal.Body className='alert-box-body'>{resMsg}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
                </Modal.Footer>
            </Modal>
        <Modal show={showCBox} onHide={handleCloseCBox} className='alert-box'>
                <Modal.Header closeButton>
                    <Modal.Title className='alert-box-title-error'>Are you Sure!</Modal.Title>
                </Modal.Header>
                <Modal.Body className='alert-box-body'>Are you wure want to delete {selectedTitle}</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => handleDeleteData()}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={() => handleCloseCBox()}>
                        No
                    </Button>
                    {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
                </Modal.Footer>
            </Modal>
      {isLoading? <LoadingCompinent/>:
      <>
     <HeaderSubTitle title='Trip History'/>
      <TabelOperation navigation=''
              handleSearchChanges={handleSearchChanges}
              exportDataAsCSC={exportDataAsCSC}
              handlePerPageSelect={handlePerPageSelect}
      />
        <div className="ag-theme-alpine"
           style={{
             height: '100%',
           }}>
           <AgGridReact 
           
           onGridReady={onGridReady}
           columnDefs={columnDefs}
           rowGroupPanelShow="always"
          // rowData={rowData}
           rowSelection="multiple"
           animateRows={true}
           defaultColDef={defaultColDef}
           pagination={true}
           paginationPageSize={10}
           rowHeight={60}
           // valueGetter={inverseRowCount}
           // paginationAutoPageSize={true}
           />
         </div>
        {/* <Table striped className='body-table'>
        <thead>
          <tr>
            <th>#</th>
            <th>Status</th>
            <th>Start Date</th>
            <th>Start Times</th>
            <th>End Date</th>
            <th>End Time</th>
            <th>Assign Date</th>
            <th>Option</th>
          </tr>
        </thead>
          <tbody>
            {items.length==0?'No Data Found':
            items&&
              items.map((p,index) => {
               
                let dateAndTimeC='';
                let dateAndTimeU='';
         
                   dateAndTimeC=moment(p.created_at).utc().format('YYYY-MM-DD hh:mm:ss')
                   dateAndTimeU=moment(p.updated_at).utc().format('YYYY-MM-DD hh:mm:ss')
            
                    return (
                        <tr key={p.id}>
                        <td>{index+1}</td>
                        <td>{getStatusTitle(p.status)}</td>
                        <td>{p.start_date}</td>
                        <td>{p.start_time == null ? "--" : p.start_time}</td>
                        <td>{p.end_date == null ? "--" : p.end_date}</td>
                        <td>{p.end_time == null ? "--" : p.end_time}</td>
                        <td>{dateAndTimeU}</td>
                        <td>
                      <Link to={'/UpdateTripHistory/'+p.id}>
                        <button className='btn btn-edit'> <i className="fa fa-eye fa-sm" aria-hidden="true"></i></button >{' '}
                      </Link>
                        {/* <button className='btn btn-delete'
                             onClick={() =>{ 
                              setSelectedItemId(p.id);
                              setSelectedTitle(p.title);
                            
                              handleShowCBox();}}
                        > <i className="fa fa-trash fa-sm" aria-hidden="true"></i></button >{' '} 
                      </td>
                    </tr>
                    );
                    })}
                    
  
          </tbody>
        </Table> */}
            <br></br>
      <br></br>
      </>
       }
             </>
    );
}

export default TripHistory;



