import React, { useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker,Autocomplete, DirectionsRenderer,InfoWindow, OverlayView} from '@react-google-maps/api';
import "./map.css"

import { Button, Form } from 'react-bootstrap';
import { useRef } from 'react';
import useOnclickOutside from "react-cool-onclickoutside";
import usePlacesAutocomplete, {
 geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import PlacesAutocomplete from 'react-places-autocomplete';



const containerStyle =  {
  width: '100%',
  height: '500px' 
};

// const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
//   return {
//       x: offsetWidth + labelAnchor.x,
//       y: offsetHeight + labelAnchor.y,
//   };
// };

function GoogleMapRoute({allLocation, schoolLat,schoolLng,schoolName}) {
  const[isLoading,setIsLoading]=useState(false);
    const [map, setMap] = React.useState( /**  @type*/ null);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const[ childWaypoints,setChildWayPoint]=useState([]);
    const [zoom,setZoom]=useState(14);
    const center = {
        lat: schoolLat,
        lng: schoolLng
      };
    useEffect(() => {
      const docData = []; 
        for (var i = 0; i < allLocation.length; i++) {
          var p = allLocation[i];
          var latLng = {
            location: { lat: p.pick_up_lat, lng: p.pick_up_lng },
            stopover: true,
         
          };
    
          docData.push(latLng);
     
        }
        setChildWayPoint(docData);
        makeDirection(docData);
    
      
    }, []);
  //  async function makeDirection() {
        
  //       const directionsService = new window.google.maps.DirectionsService()
  //     await directionsService.route(
  //           {
  //           origin:{lat:schoolLat, lng:schoolLng},
  //           waypoints:childWaypoints,
  //           destination:{lat:schoolLat, lng:schoolLng},
  //           travelMode:  window.google.maps.TravelMode.DRIVING,
            
           
  //         },
          
  //       (result, status) => {
  //           if (status === window.google.maps.DirectionsStatus.OK) {
  //               setDirectionsResponse(result)
        
  //           } else {
  //             console.error(`error fetching directions ${result}`);
  //           }
  //         }
  //         )
       
  //   }
    async function makeDirection(childWaypoints) {
        
        const directionsService = new window.google.maps.DirectionsService()
      await directionsService.route(
            {
            origin:{lat:schoolLat, lng:schoolLng},
            waypoints:childWaypoints,
            
            destination:{lat:schoolLat, lng:schoolLng},
            travelMode:  window.google.maps.TravelMode.DRIVING,
            
          },
          
        (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
                setDirectionsResponse(result)
        
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
          )
       
    }

  
  
    
 /**  @type*/
   // const originRef=useRef();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyA3GkUvfEQeMptos3NG_yufZ7tvZCenvJk",
    libraries:['places']
  })


  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
  //  map.fitBounds(bounds);
    setMap(map)
  

  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  


  return  isLoaded ? (
    <div className='route-map'>
    {/* <Button onClick={makeDirection}>Pick Up Route</Button>
    <br/> */}
    <h6>Pcik Up Route</h6>

        <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}    
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}        
      >
  
{    directionsResponse?  
<DirectionsRenderer 
  directions={directionsResponse}
  options={{ suppressMarkers: true }}
  />:<></>}
  
     <Marker
    position={{ lat: schoolLat, lng: schoolLng }}
     icon={{url:'https://schoob.net/busapp/images/school_map.png',
      anchor: new window.google.maps.Point(17, 46),
      scaledSize: new window.google.maps.Size(37, 37),
      fillColor: '#EB00FF'
    }}
    //label="ashish"

  
  />
  <OverlayView
    key='mwl'
    position={{ lat: schoolLat, lng: schoolLng }}
    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    //  getPixelPositionOffset={(x, y) => getPixelPositionOffset(x, y, {     x: -130, y: -100 })}
    >
    <div
        style={{
            background: `#203254`,
            padding: `7px 12px`,
            fontSize: '11px',
            color: `white`,
            borderRadius: '4px',
        }}
    >
        {schoolName}
    </div>
</OverlayView>
    {childWaypoints.map((waypt, index)=> (
      <>
  <Marker
    position={{ lat: waypt.location.lat, lng: waypt.location.lng }}
     icon={{url:'https://schoob.net/busapp/images/student_map.png',
      anchor: new window.google.maps.Point(17, 46),
      scaledSize: new window.google.maps.Size(37, 37),
      fillColor: '#EB00FF'
    }}
    // label={waypt.student_name}
  />
  <OverlayView
  key={waypt.location.lat}
  position={{ lat: waypt.location.lat, lng: waypt.location.lng }}
  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
  //  getPixelPositionOffset={(x, y) => getPixelPositionOffset(x, y, {     x: -130, y: -100 })}
  >
  <div
      style={{
          background: `#203254`,
          padding: `7px 12px`,
          fontSize: '11px',
          color: `white`,
          borderRadius: '4px',
      }}
  >   
      {allLocation[index].first_name +" "+ allLocation[index].last_name+" "+allLocation[index].pick_up_order}
  </div>
</OverlayView>
</>
))}
      </GoogleMap>
      
    </div>
    
  ) : <></>
  
  
 
}


export default React.memo(GoogleMapRoute)