import { validationLatitudeLongitude } from "validation-latitude-longitude";
import { latitude, longitude, coordinates } from "check-geographic-coordinates";


class Validation{
    checkFourLengthValidation(input) {
       
        let validate;
        if(input.length>3){
            validate=true
   
        }else{
            validate=false
        }
        return validate;
    }
      // parses JSON response into native JavaScript objects
     
    checkThreeLengthValidation(input) {
        let validate;
        if(input.length>2){
            validate=true
   
        }else{
            validate=false
        }
        return validate;
     
      // parses JSON response into native JavaScript objects
     }
     checkTwoLengthValidation(input) {
        let validate;
        if(input.length>1){
            validate=true
   
        }else{
            validate=false
        }
        return validate;
     
      // parses JSON response into native JavaScript objects
     }
    checkNameValidation(input) {
        let validate;
        if(input.length>3){
            validate=true
   
        }else{
            validate=false
        }
        return validate;
     
      // parses JSON response into native JavaScript objects
     }
     checkPhoneValidation(input) {
        let validate;
        if(input.length==10&&input.match('[0-9]{10}')){
            validate=true
   
        }else{
            validate=false
        }
        return validate;
     
      // parses JSON response into native JavaScript objects
     }
      checkPinCodeValidation(input) {

        let validate;
        if(input.toString().length==6&&input.toString().match('[0-9]{6}')){
            validate=true
   
        }else{
            validate=false
        }
    
    
        return validate;
      // parses JSON response into native JavaScript objects
     }
     checkPassValidation(input) {
        let validate;
        if(input.length>=8){
            validate=true
   
        }else{
            validate=false
        }
        return validate;
     
      // parses JSON response into native JavaScript objects
     }
     
    checkLatValidation(input) {
        let validate;
     if(isFinite(input) && Math.abs(input) <= 90){
        validate=true

     }
        return  validate;
     }
    
    checkLngValidation(input) {
        let validate;
     if(isFinite(input) && Math.abs(input) <= 180){
        validate=true
     }
        return  validate;
     }
     checkVechicleNValidation(input) {
        let validate;
        if(input.length>5){
            validate=true
   
        }else{
            validate=false
        }
        return validate;
    
      // parses JSON response into native JavaScript objects
     }
     checkDesceNValidation(input) {
        let validate;
        if(input.length>5){
            validate=true
   
        }else{
            validate=false
        }
        return validate;
    
      // parses JSON response into native JavaScript objects
     }
     checkColorValidation(input) {
        let validate;
        if(input.length>3){
            validate=true
   
        }else{
            validate=false
        }
        return validate;
    
      // parses JSON response into native JavaScript objects
     }
     checkValidateHhMm(inputField) {
        var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(inputField);

        return isValid;
      }
    }


   
export default new Validation();