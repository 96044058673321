import api_url from "../my_config"; 
class RoleServices{
    getSchoolUrl=api_url+'/api/get_role/school';
    getManagerUrl=api_url+'/api/get_role/manager';

 
     async getDataSchool() {
    
      try{
        console.log(this.getUrl);
      // Default options are marked with *
      const response = await fetch(this.getSchoolUrl, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
            if(data['data']==null)
            return null;
           else return data['data']; 
            }
            else return null;
          }else return null ;
        }catch(e){
          return null;
        }
    
     // parses JSON response into native JavaScript objects
    }

    async getDataManager() {
    
      try{
        console.log(this.getUrl);
      // Default options are marked with *
      const response = await fetch(this.getManagerUrl, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
            if(data['data']==null)
            return null;
           else return data['data']; 
            }
            else return null;
          }else return null ;
        }catch(e){
          return null;
        }
    
     // parses JSON response into native JavaScript objects
    }


}
   
export default new RoleServices();