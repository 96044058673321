import Table from 'react-bootstrap/Table';
import React from "react";
import LoadingCompinent from '../../components/loading/loadingComponent';
import TabelOperation from '../../components/tableOperation/tableOpeartion';
import "../../common/bodyTable.css"
import RoundedImageTable from"../../components/roundedImages/roundedImage"
import EmpServices from '../../services/empService';
import moment from "moment";
import { Link } from 'react-router-dom';
import HeaderSubTitle from "../../header/headerSubTitle";
class EmpTable extends React.Component{
  componentDidMount() {
    EmpServices.getData()
    .then((data) => {
            this.setState({
              items: data,
             DataisLoaded: true
            });
        })
}
  constructor(props) {
    super(props);
    this.state = {
        items: [],
        DataisLoaded: false,
        count:0
    };
}

  render() {
    let count = 0;
    const { DataisLoaded, items } = this.state;
    if (!DataisLoaded) return <div>
         <LoadingCompinent/> </div> ;

return (
    <>
 <HeaderSubTitle title='Employees'/>
    <TabelOperation navigation='/AddEmp'/>

      <Table striped className='body-table'>
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone</th>
            <th>Address</th>
            <th>User Type</th>
            <th>Created</th>
            <th>Updated</th>
            <th>Option</th>
          </tr>
        </thead>
        <tbody>
          {items.length==0?'No Data Found':
          items&&
            items.map((p) => {
              count++;
              let dateAndTimeC='';
              let dateAndTimeU='';
  
                 dateAndTimeC=moment(p.created_at).utc().format('YYYY-MM-DD hh:mm:ss')
                 dateAndTimeU=moment(p.updated_at).utc().format('YYYY-MM-DD hh:mm:ss')
          
                  return (
                    <tr key={p.id}>
                    <td>{count}</td>
                    <td><RoundedImageTable imageUrl='https://upload.wikimedia.org/wikipedia/commons/e/ee/Camberwell_High_School.jpg'/></td>
                    <td>{p.first_name}</td>
                    <td>{p.last_name}</td>
                    <td>{p.phone}</td>
                    <td>{p.address}</td>
                    <td>{p.user_type==3?'User':p.user_type==7?'Manager':p.user_type==8?'Operational Manager':p.user_type==9?'Bus Driver':p.user_type==10?'Bus Asistent':'--'}</td>
                    <td>{dateAndTimeC}</td>
                    <td>{dateAndTimeU}</td>
                    <td>
                    <Link to={'/UpdateEmp/'+p.user_id}>
                      <button className='btn btn-edit'> <i className="fa fa-edit fa-sm" aria-hidden="true"></i></button >{' '}
                    </Link>
                      <button className='btn btn-delete'> <i className="fa fa-trash fa-sm" aria-hidden="true"></i></button >{' '}
                    </td> 
                  </tr>
                  );
                  })}
                  

        </tbody>
      </Table>
    </>
  );
      
      }
      
}


export default EmpTable;