import api_url from "../my_config"; 
class DataCountServices{
    getSchoolRole=api_url+'/api/get_user/count/school';
    getBusSchool=api_url+'/api/get_user/count/buses';
    getTripSchool=api_url+'/api/get_user/count/trip';
    getTripHistorySchool=api_url+'/api/get_user/count/trip_history';
    getActiveTripSchool=api_url+'/api/get_trip_history/active_trip/school';
    getTripSchoolStatusUrl=api_url+'/api/get_trip_history/school/status';
    async getTripBySchoolAndStatus(school_id,status) {
      // console.log(this.getSyncUrl+'/'+user_id);
      try{
      // Default options are marked with *
      const response = await fetch(this.getTripSchoolStatusUrl+'/'+school_id+'/'+status, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
              if( data['data']==null)
              return []; 
           else return data['data']; 
            }
            else return [];
          }else return[];
        }catch(e){
          return[];
        }
    
     // parses JSON response into native JavaScript objects
    }
    async getActiveTripBySchool(school_id) {
      // console.log(this.getSyncUrl+'/'+user_id);
      try{
      // Default options are marked with *
      const response = await fetch(this.getActiveTripSchool+'/'+school_id, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
              if( data['data']==null)
              return []; 
           else return data['data']; 
            }
            else return [];
          }else return[];
        }catch(e){
          return[];
        }
    
     // parses JSON response into native JavaScript objects
    }
    async getTripHistoryBySchool(school_id) {
        // console.log(this.getSyncUrl+'/'+user_id);
        try{
        // Default options are marked with *
        const response = await fetch(this.getTripHistorySchool+'/'+school_id, {
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          // body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
            if(response.status==200){
              const data = await response.json();
              if(data['response']==200){
                if( data['data']==null)
                return []; 
             else return data['data']; 
              }
              else return [];
            }else return[];
          }catch(e){
            return[];
          }
      
       // parses JSON response into native JavaScript objects
      }
    async getBusesBySchool(school_id) {
        // console.log(this.getSyncUrl+'/'+user_id);
        try{
        // Default options are marked with *
        const response = await fetch(this.getBusSchool+'/'+school_id, {
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          // body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
            if(response.status==200){
              const data = await response.json();
              if(data['response']==200){
                if( data['data']==null)
                return []; 
             else return data['data']; 
              }
              else return [];
            }else return[];
          }catch(e){
            return[];
          }
      
       // parses JSON response into native JavaScript objects
      }
      async getTripBySchool(school_id) {
        // console.log(this.getSyncUrl+'/'+user_id);
        try{
        // Default options are marked with *
        const response = await fetch(this.getTripSchool+'/'+school_id, {
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          // body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
            if(response.status==200){
              const data = await response.json();
              if(data['response']==200){
                if( data['data']==null)
                return []; 
             else return data['data']; 
              }
              else return [];
            }else return[];
          }catch(e){
            return[];
          }
      
       // parses JSON response into native JavaScript objects
      }
    async getUserBySchoolAndRole(school_id,role_id) {
        // console.log(this.getSyncUrl+'/'+user_id);
        try{
        // Default options are marked with *
        const response = await fetch(this.getSchoolRole+'/'+school_id+'/'+role_id, {
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          // body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
            if(response.status==200){
              const data = await response.json();
              if(data['response']==200){
                if( data['data']==null)
                return []; 
             else return data['data']; 
              }
              else return [];
            }else return[];
          }catch(e){
            return[];
          }
      
       // parses JSON response into native JavaScript objects
      }

}
export default new DataCountServices();
