import { useEffect, useState } from "react";
import GoogleMapLiveBusTrack from "../components/googleMap/googleMapLiveBusTrack";

import HeaderSubTitle from "../header/headerSubTitle";
import busesService from "../services/busesService";
import schoolServices from "../services/schoolServices";
function TrackAllBuses(){
    const [isLoading,setLoading]=useState(false);
    const schoolId=localStorage.getItem('schoolId');
    const [allBusData,setAllBusData]=useState([]);
  
    
    const [schoolLatng,setSchoolLatng] = useState();
    useEffect(() => {
        getSChoolLatLng();
       
      }, []);

    function getSChoolLatLng() {
        setLoading(true);
   
        schoolServices.getDataById(schoolId).then(
          (data) => {
        
            if(data!=null){
              if(data.lng!=null&&data.lat!=null)
            {
              setSchoolLatng({"lat":data.lat,"lng":data.lng,"title":data.title})
             
            }
            }
    
          }
       
        );
        busesService.getDataBySchooId(schoolId)
        .then((data) => {
          if(!data.length){
        
          }else{
            setAllBusData(data)
            setLoading(false);
        
          }
          // setData(data);
            })
       
      }
     function handleClickOnBus(){};
    return (
    <div className="TrackAllBuses"> 
    <HeaderSubTitle title='Live Track All Buses'/>
    { schoolLatng==null?  <span className="dot"></span>: schoolLatng.lat==null||schoolLatng.lng==null?
     <>Update School Latitude and Longitude to show google map</>:
     allBusData.length>0? <GoogleMapLiveBusTrack 
      allBusData={allBusData}
      schoolLat={schoolLatng.lat}
      schoolLng={schoolLatng.lng}
      schoolName={schoolLatng.title}
      allBuses={allBusData}
      />:<>No bus found</>
      }

    </div>
    );
}
export default TrackAllBuses;
