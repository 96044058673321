import React from 'react';
import Chart from 'react-apexcharts'
// import {Chart, ArcElement,Title,Tooltip,Legend} from 'chart.js'
// Chart.register(ArcElement,Legend,Tooltip,Title);

function StudentParentChart({students,parents,}) {

    return (
      <div>
        <Chart
        type="pie" 
         series={[students, parents]}
        options={
          {
            legend: {
              show: true
            },
            dataLabels: {
              
              formatter: function (val, opts) {
                  return opts.w.config.series[opts.seriesIndex]
              },
            },
            labels: [
              'Students',
              'Parents',
            ],
            title:{
              text:"Students/Parents"
            },
              plotOptions:{
                pie:{
                  donut:{
                    labels:{
                      show:true,
                      total:{
                        showAlways:true,
                        show:true,
                        fontSize:20
                      },
      
                    }
                  }
                }

        }
      }
        }
        />
      </div>
    );
  }
  
  export default StudentParentChart;