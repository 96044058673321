import { Row, Col, Form, Button, Dropdown, Modal } from 'react-bootstrap'
import "../../common/custoForm.css" 
import Select from 'react-select';
import { useEffect, useState } from 'react';
import LoadingCompinentCommon from '../../common/commonLoading';
import schoolServices from '../../services/schoolServices';
import { useNavigate } from 'react-router-dom';
import cityService from '../../services/cityService';
import UploadFileService from '../../services/uploadFile';
import TripService from '../../services/tripService';
import { toast } from 'react-toastify';
import HeaderSubTitle from "../../header/headerSubTitle";
import validation from '../../helper/validation';

function AddTrip() {
    const [errorsTtile, setErrorsTtile] = useState(null);
    const [errorsPickUpTime, setErrorsPickUpTime] = useState(null);
    const [errorsDropTime, setErrorsDropTime] = useState(null);
    const roleId=localStorage.getItem('roleId');
    const schoolId=localStorage.getItem('schoolId');
    const schoolTitle=localStorage.getItem('schoolTitle');
    const [schoolOPData,setschoolOPData] = useState([]);

    const [image, setImage] = useState(null)
    const [imageId, setImageId] = useState(null)
    const [isImageUploading, setFlagUloadImage] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null);
    const navigate = useNavigate();
    const [data, setData] = useState({
        title: "",

        school_id:""
    });
    const [isLoading, setLoading] = useState(false);
    const [resSuccess, setResSuccess] = useState(false);
    const [resMsg, setResMsg] = useState('')
    const [show, setShow] = useState(false);


    function handleClose() {
        setShow(false);
        if(resSuccess==true)
        navigate(-1)
    }
    function handleShow  () {
        setShow(true)
    };
    function getSChool() {
        if(schoolId!="null"&&schoolId!=null)
        {        
   const newData = { ...data }
   newData["school_id"] = schoolId
   setData(newData);}
        schoolServices.getData()
            .then((data) => {
                const dataArr=[];
                data.map((e) => {
                    dataArr.push({
                         value: e.id, label: e.title 
                        
                    });
                });
                setschoolOPData([...dataArr]);

            })
    }
    useEffect(() => {
        getSChool();
    }, []);

    
  
    function handleSubmit(e) {
        
    let isValidTite=false;
    // let isValidPickUpTime=false;
    // let isValidDropGTime=false;
     e.preventDefault();
     const titleValidate=validation.checkNameValidation(data.title);
     if(titleValidate){
      isValidTite=true;
      setErrorsTtile(null);
  }
      else{
          isValidTite=false
          setErrorsTtile("Length must be greater than 3 letters");

      }
  
//       const isValidPickUpTimeValid=validation.checkValidateHhMm(data.pick_up_start_time);
//       if(isValidPickUpTimeValid){
//         isValidPickUpTime=true;
//        setErrorsPickUpTime(null);
//    }
//        else{
//         isValidPickUpTime=false
//         setErrorsPickUpTime("Time Format Should Be HH:MM Ex 08:00");
 
//        }
    //    const isValidDropTimeValid=validation.checkValidateHhMm(data.drop_start_time);
    //    if(isValidDropTimeValid){
    //      isValidDropGTime=true;
    //     setErrorsDropTime(null);
    // }
    //     else{
    //       isValidDropGTime=false
    //       setErrorsDropTime("Time Format Should Be HH:MM Ex 08:00");
  
    //     }
     if(isValidTite==true){
        setLoading(true);
        setResMsg('')
        setResSuccess(false);
        TripService.addData(data.school_id,data.title,selectedFile).then((res) => {
            if (res == 'error') {
                setResMsg('Something went wrong')
            } else {
                if (res['status'] == false) { 
                    setResSuccess(true); 
                    setResMsg(res['message'])
                    handleShow(true);
                }else if(res['status'] == true){
    
                    navigate('/UpdateTrip/'+res['id']);
                    toast.success(res['message'],
                    {
                        className: 'toast-success-container toast-success-container-after',
                    }
                    
                    );
                  
                }
            }
            setLoading(false);
        });
    }
    }
 
    function handleChange(e) {
        const newData = { ...data }
        newData[e.target.id] = e.target.value
        setData(newData);

    }
    function handleChangeSchool(e) {
       
        const newData = { ...data }
        newData["school_id"] = e.value
        setData(newData);
        console.log(data);
    }

    function handleRemoveImage(){
        setImage(null);
        setImageId(null);
        setSelectedFile(null);
        
    }
    
      function handleChangeImage(event) {
    
        if (event.target.files && event.target.files[0]) {
            if (!event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i))
                 alert('not an image');
    
    else{
        let file_size = event.target.files[0].size;
        if(file_size>0&&file_size<512000)
    {     setSelectedFile(event.target.files[0])
        setImage(URL.createObjectURL(event.target.files[0]));
    }
        else{
            alert("File Size must be less then 500kb");
            // alert(file_size);
        }
    }
           
        }
    }
    
    
    
    return (
    <>  
    <HeaderSubTitle title='Add New Trip'/>
        <div className="Cutso-Form">
            <Modal show={show} onHide={handleClose} className='alert-box'>
                <Modal.Header closeButton>
                    <Modal.Title className={resSuccess == false ? 'alert-box-title-error' : 'alert-box-title'}>Response</Modal.Title>
                </Modal.Header>
                <Modal.Body className='alert-box-body'>{resMsg}</Modal.Body>
                <Modal.Footer>
                    <Button   variant="secondary" onClick={() => handleClose()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className='form-section-update'>
            <Form onSubmit={(e) => handleSubmit(e)}> 
            <Form.Group className="mb-3">
                    <Form.Label className='form-label' >
                        School*
                    </Form.Label>
                    <Select
                   isDisabled={roleId==1?false:true}
                    defaultValue={schoolId=="null"||schoolTitle=="null"||schoolId==null||schoolTitle==null?null:{"value":schoolId,"label":schoolTitle}}
                    onChange={(e) => handleChangeSchool(e)}
                    options={schoolOPData}
                    />
                          
                 
                </Form.Group>
          
                
                <Form.Group  className="mb-3">
                    <Form.Label >
                        Title*
                    </Form.Label>
              
                        <Form.Control
                          isInvalid={!!errorsTtile}
                        required onChange={(e) => handleChange(e)} type="text" placeholder="Trip Name" name='title' id='title' value={data.title} />
                         <Form.Control.Feedback type="invalid">{errorsTtile}</Form.Control.Feedback>

                </Form.Group>
          
                {/* <Form.Group className="mb-3">
                  <Form.Label>Pick Up Start Time*</Form.Label>

                  <Form.Control
                    isInvalid={!!errorsPickUpTime}
                    required
                    onChange={(e) => handleChange(e)}
                    type="text"
                    placeholder="07:00"
                    name="pick_up_start_time"
                    id="pick_up_start_time"
                    value={data.pick_up_start_time}
                  />
                <Form.Control.Feedback type="invalid">{errorsPickUpTime}</Form.Control.Feedback>

                </Form.Group> */}
                {/* <Form.Group className="mb-3">
                  <Form.Label>Drop Start Time*</Form.Label>
                  <Form.Control
                    isInvalid={!!errorsDropTime}
                    required
                    onChange={(e) => handleChange(e)}
                    type="text"
                    placeholder="13:00"
                    name="drop_start_time"
                    id="drop_start_time"
                    value={data.drop_start_time}
                  />
                 <Form.Control.Feedback type="invalid">{errorsDropTime}</Form.Control.Feedback>

                </Form.Group> */}
                {isLoading == true ? <LoadingCompinentCommon /> : 
           <Button size="sm"  variant="primary" type="submit">
           Submit
       </Button>
                }
            </Form>
       
            </div>
            <div className='image-box'>
           <div>
     </div>
           {image==null? <div className='image-box-add'> <i className="fa fa-image" aria-hidden="true"></i></div>:
                 <img id="image" src={image}/>} 
             {
                isLoading==true?
                <></>:
             isImageUploading==true?
             <>
             <br/>
             <LoadingCompinentCommon />
             </>
             :
             image==null?
             <div className="form-group">
             <label htmlFor="myInput" className='image-operation-btn'>Select Image</label>
              <input id="myInput"type="file" name="file" className="mb-4" 
               accept="image/*"
              style={{display:'none'}}
              onClick={(event)=> { 
                event.target.value = null
           }}
              onChange={(e) => handleChangeImage(e)}  />
             </div>:
             <>
               <button className='image-operation-btn' onClick={handleRemoveImage}>
                 Remove
              </button>
    
              {/* <button className='image-operation-btn' onClick={handleUploadImage}>
                 Upload Image
              </button> */}
              </>
              }
           </div>
        </div>
        </>
    );

}

export default AddTrip;