import { useState } from "react";
import {  Button, Form,  } from "react-bootstrap";
import LoginServices from '../../services/loginService';
import "./login.css";
import busLogo from '../../assets/logo.png';
import roadLogo from '../../assets/road.png';
import axios from "axios";
import userService from "../../services/userService";

import {  getToken } from "firebase/messaging";
import { messaging } from "../../config/firebae";
function LoginPage({handleLogin}) {
  async function requestNotifcationPermission(){
  //If push subscription wasnt done yet have to do here
  console.log("sw already activated - Do watever needed here");
  console.log("--------ssssssssssssssssssssssssssssssssssssssssss===================");
  const permission=await Notification.requestPermission();
  if(permission=="granted"){
    const fcm=await getToken(messaging,{vapidKey:"BDU0lnccPubHYP48RB3CVQiyLsKR0VkZOuEGaL9ESyjZ1rkqtgha-8bJqaT2P_9A46oQcxrvFQnoc32wMQE8n60"})
    console.log("FCM Token",fcm);
      localStorage.setItem('fcm', fcm);
      if(fcm!=null&&fcm!="null"&&fcm!=""){
         const userId= localStorage.getItem('id');
        await userService.updateWebFcm(userId,fcm);
        console.log("--------ppppppppppppppp===================");
      }
  }else if(permission=="denied"){
    alert("You denied for notification")
  }
//     navigator.serviceWorker.register('firebase-messaging-sw.js', {scope: "/"})
//     .then(
//     async function (reg) {
//         var serviceWorker;
//         if (reg.installing) {
//             serviceWorker = reg.installing;
//              console.log('Service worker installing');
//         } else if (reg.waiting) {
//             serviceWorker = reg.waiting;
//              console.log('Service worker installed & waiting');
//         } else if (reg.active) {
//             serviceWorker = reg.active;
//              console.log('Service worker active');
//         }

//         if (serviceWorker) {
//             console.log("sw current state", serviceWorker.state);
//             if (serviceWorker.state == "activated") {
//                 //If push subscription wasnt done yet have to do here
//                 console.log("sw already activated - Do watever needed here");
//                 console.log("--------ssssssssssssssssssssssssssssssssssssssssss===================");
//                 const permission=await Notification.requestPermission();
//                 if(permission=="granted"){
//                   const fcm=await getToken(messaging,{vapidKey:"BDU0lnccPubHYP48RB3CVQiyLsKR0VkZOuEGaL9ESyjZ1rkqtgha-8bJqaT2P_9A46oQcxrvFQnoc32wMQE8n60"})
//                   console.log("FCM Token",fcm);
//                     localStorage.setItem('fcm', fcm);
//                     if(fcm!=null&&fcm!="null"&&fcm!=""){
//                        const userId= localStorage.getItem('id');
//                       await userService.updateWebFcm(userId,fcm);
//                       console.log("--------ppppppppppppppp===================");
//                     }
//                 }else if(permission=="denied"){
//                   alert("You denied for notification")
//                 }
         
//             }
//             serviceWorker.addEventListener("statechange", async function(e) {
//                 console.log("sw statechange : ", e.target.state);
//                 if (e.target.state == "activated") {
//                     // use pushManger for subscribing here.
//                     console.log("Just now activated. now we can subscribe for push notification")
//                   //  subscribeForPushNotification(reg);
//                   console.log("--------ssssssssssssssssssssssssssssssssssssssssss===================");
//                   const permission=await Notification.requestPermission();
//                   if(permission=="granted"){
//                     const fcm=await getToken(messaging,{vapidKey:"BDU0lnccPubHYP48RB3CVQiyLsKR0VkZOuEGaL9ESyjZ1rkqtgha-8bJqaT2P_9A46oQcxrvFQnoc32wMQE8n60"})
//                     console.log("FCM Token",fcm);
//                       localStorage.setItem('fcm', fcm);
//                       if(fcm!=null&&fcm!="null"&&fcm!=""){
//                          const userId= localStorage.getItem('id');
//                         await userService.updateWebFcm(userId,fcm);
//                         console.log("--------ppppppppppppppp===================");
//                       }
//                   }else if(permission=="denied"){
//                     alert("You denied for notification")
//                   }
//                 }
//             });
//         }
//     },
//     function (err) {
//         console.error('unsuccessful registration with ', 'firebase-messaging-sw.js', err);
//     }
// );
//--

  }
  

    const [isLoading,setIsloading]=useState(false);
    const [data,setData]=useState(false);
    const [responseMSg ,setResponseMsg]=useState("");
    function handleChange(e) {
        const newData = { ...data }
        newData[e.target.id] = e.target.value
        setData(newData);
        // console.log(newData);
    }
    function handleSubmit(e) {
        setIsloading(true);
        setResponseMsg('')
        e.preventDefault();
        LoginServices.addData(data.email,data.password).then((res) => {
                
            if (res == 'error') {
              setResponseMsg('Something went wrong')
            } else {
        
                if (res['status'] == true) {
                      setDataAndNavigate(res)
             
                 }
                setResponseMsg(res['message'])
            }
            setIsloading(false);
        });
    }
    async function setDataAndNavigate(loginRes){

      if((loginRes['data']['role']).length>0){
        localStorage.setItem('token', loginRes['token']);
   
        localStorage.setItem('id', loginRes['data']['id']);

        localStorage.setItem('firstName', loginRes['data']['first_name']);
        localStorage.setItem('lastName', loginRes['data']['last_name']);

        localStorage.setItem('roleId', loginRes['data']['role'][0]['role_id']);
        localStorage.setItem('roleTitle', loginRes['data']['role'][0]['role_title']);
        localStorage.setItem('schoolTitle', loginRes['data']['role'][0]['school_title']);
        localStorage.setItem('schoolId', loginRes['data']['role'][0]['school_id']);

        localStorage.setItem('login', true);
        requestNotifcationPermission();
        handleLogin();
        
      }
      else{
        setResponseMsg("You can not log in the system because have been assigned not any role")}
       
    }
    return(
    <div className="login-page">

    <div className="header-name-logo">
    <img className="header-logo-image" src={busLogo} alt="logo" />
    <h2>Schoob Bus Tracking</h2>
    </div>
        <div className="form-card" >
          <div className="card-header">
          <h5>Login to your account</h5>
          <img className="card-header-logo-image" src={roadLogo} alt="logo" />
          </div>
        
              <div className="herader-divider"></div>
 
                <Form onSubmit={(e) => handleSubmit(e)}>
                  
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control 
        required
        id="email"
        name="email"
        onChange={(e) => handleChange(e)} 
        type="email" placeholder="Enter email" />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control 
           required
           id="password"
           name="password"
           onChange={(e) => handleChange(e)} 
        type="password" placeholder="Password" />
      </Form.Group>
      <Button
      disabled={isLoading?true:false}
      variant="primary" type="submit">
        Submit
      </Button>
      <p className="resMsg">{responseMSg}</p>
    </Form>
    </div>
    </div>

    );


}

export default LoginPage;