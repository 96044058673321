import api_url from "../my_config"; 
class NotificaitonServices{
    getUrl=api_url+'/api/get_noti/school';
    addUrl=api_url+'/api/add_noti';

     async getDataBySchoolId(id) {
       try{
       // Default options are marked with *
       const response = await fetch(this.getUrl+'/'+id, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
           'Content-Type': 'application/json',
           'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
           // 'Content-Type': 'application/x-www-form-urlencoded',
         },
         redirect: 'follow', // manual, *follow, error
         referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
         // body: JSON.stringify(data) // body data type must match "Content-Type" header
       });
       if(response.status==200){
        const data = await response.json();
        if(data['response']==200){
          if( data['data']==null)
          return []; 
       else return data['data']; 
        }
        else return [];
      }else return[];
    }catch(e){
      return[];
    }
     
      // parses JSON response into native JavaScript objects
     }
   
     async addData(school_id,title,body,imageFile) {
       try{
                const formData = new FormData();
         if(imageFile!=null)
       { formData.append("image", imageFile);}
       if(school_id!=null)
        formData.append("school_id", school_id);
        if(title!=null)
        formData.append("title", title);
        if(body!=null)
       formData.append("body", body);
     
       const response = await fetch(this.addUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: formData // body data type must match "Content-Type" header
      });
           if(response.status==200){
             const data = await response.json();
             if(data['response']==201||data['response']==200)
             return data; 
             else return "error";
           }else return"error";
         }catch(e){
        
           return"error";
         }
     
      // parses JSON response into native JavaScript objects
     }
    

}
   
export default new NotificaitonServices();