import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getMessaging, onMessage } from "firebase/messaging";
// import firebase from "firebase";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCiTef5V1R19eDExOwcZJL3KeWtBRyA7E8",
  authDomain: "bus-app-new-8d36b.firebaseapp.com",
  projectId: "bus-app-new-8d36b",
  storageBucket: "bus-app-new-8d36b.appspot.com",
  messagingSenderId: "525683536865",
  appId: "1:525683536865:web:76bab1c2977208043698a0",
  measurementId: "G-TZW7PTZHK7"
};

// Initialize FirebaseW
const app = initializeApp(firebaseConfig);
export const messaging=getMessaging(app);

// export const auth = getAuth(app);

// export default  db = getFirestore(app);

export const db = getFirestore(app);

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

export const getToken = (setTokenFound) => {
  return getToken(messaging, {vapidKey: 'BDU0lnccPubHYP48RB3CVQiyLsKR0VkZOuEGaL9ESyjZ1rkqtgha-8bJqaT2P_9A46oQcxrvFQnoc32wMQE8n60'}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

