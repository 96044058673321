import { Row, Col, Form, Button, Dropdown, Modal, Card,Table } from 'react-bootstrap'
import "../../common/custoForm.css"
import moment from "moment";
import { useEffect, useState } from 'react';
import LoadingCompinentCommon from '../../common/commonLoading';
import schoolServices from '../../services/schoolServices';
import { useNavigate, useParams } from 'react-router-dom';
import cityService from '../../services/cityService';
import { useLocation } from "react-router-dom";
import UploadFileService from '../../services/uploadFile';
import HeaderSubTitle from '../../header/headerSubTitle';
import busesService from '../../services/busesService';
import Select from 'react-select';
import tripService from "../../services/tripService";
import GoogleMapRouteLiveTrack from "../../components/googleMap/gogleMapRouteLiveTrck";
function UpdateTripHistory($busId) {
    const roleId=localStorage.getItem('roleId');
    const schoolId=localStorage.getItem('schoolId');
    const schoolTitle=localStorage.getItem('schoolTitle');
    const [schoolOPData,setschoolOPData] = useState([]);
    const [tripTravellerHistory, setTripTravellerHistory] = useState([]);
    const [tripTravellers, setTripTravellers] = useState([]);
    const [schoolLatng,setSchoolLatng] = useState();
    const [busTripDetails,setBusTripDetails] = useState();
    const [tripHistory,setTripHistory] = useState([]);
    const { ID } = useParams();
  
    const navigate = useNavigate();
    const [data, setData] = useState(
        {
            vehicle_number: "",
            company_name: "",
            title: "",
            color: "",
            school_id:""
    }
    );
    const [isLoading, setLoading] = useState(false);
    const [resSuccess, setResSuccess] = useState(false);
    const [resMsg, setResMsg] = useState('')
    const [show, setShow] = useState(false);
    const [school, setSchool] = useState([]);
    const [dataisLoaded, setDataisLoaded] = useState(false);
    const [isDataNull, setisDataNull] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [image, setImage] = useState(null)
    const [imageId, setImageId] = useState(null)
    const [isImageUploading, setFlagUloadImage] = useState(false)
    const [isAssignUserLoading, setAsigneUserLoading] = useState(false);
    function handleClose() {
        setShow(false);
        // if (resSuccess == true)
        //     navigate(-1)
    }
    const handleShow = () => setShow(true);
    function getTripTravellerHistory() {
        setLoading(true);
        tripService
        .getTripTHByTHId(ID)
        .then((data) => {
          setTripTravellerHistory(data);
        }); 
                setLoading(false);
    }
    
    function getUsersAssign() {
      setLoading(true);
      setAsigneUserLoading(true);
  
      tripService.getTripInitUsersByTriphIdByStatus(ID, 1).then(
        (data) => {
          setTripTravellers(data);
          setAsigneUserLoading(false);
        }
     
      );
      setLoading(false);
    }
    
    function getBusTrupDetails() {
      setLoading(true);
      setAsigneUserLoading(true);

      tripService.getBusTripDetailsInfoByTHID(ID).then(
        (data) => {
          if(data!=null){
           setBusTripDetails(data)}
          setAsigneUserLoading(false);
        }
     
      );
      setLoading(false);
    }
    function getSChoolLatLng() {
      setLoading(true);
      setAsigneUserLoading(true);
 
      tripService.getSChoolInfoByTHID(ID).then(
        (data) => {
          if(data!=null){
            if(data.lng!=null&&data.lat!=null)
          {
      
            setSchoolLatng({"lat":data.lat,"lng":data.lng,"title":data.title})}
          }
  
          setAsigneUserLoading(false);
        }
     
      );
      setLoading(false);
    }
    function getThById() {
      setLoading(true);
      setAsigneUserLoading(true);
 
      tripService.getHistoryById(ID).then(
        (data) => {
          if(data.length>0){
           
            setTripHistory(data);
            getUsersAssign();
            getSChoolLatLng()
            getBusTrupDetails();
       
          }
          
          setAsigneUserLoading(false);
        }
     
      );
      setLoading(false);
    }
    
    function handleChangeSchool(e) {
       
        const newData = { ...data }
        newData["school_id"] = e.value
        setData(newData);
        console.log(data);
    }
    useEffect(() => {
      getThById();
     
  
        getTripTravellerHistory();
    }, []);
    function handleSubmit(e) {
        setLoading(true);
        setResMsg('')
        setResSuccess(false);
        e.preventDefault();
        busesService.updateData(ID,data.school_id,data.title,data.vehicle_number,data.company_name,data.color)
        .then((res) => {
            if (res == 'error') {
                setResMsg('Something went wrong')
            } else {
                if (res['status'] == true) { setResSuccess(true); }
                setResMsg(res['message'])
            }
            handleShow();

            setLoading(false);
        });
    }
    function handleChange(e) {
        const newData = { ...data }
        newData[e.target.id] = e.target.value
        setData(newData);

    }
    function handleRemoveImage(){
        setImage(null);
        setImageId(null);
        setSelectedFile(null);
        
    }
  
    const handleUploadImage = async (e) => {
        setFlagUloadImage(true);
        setResMsg('')
        setResSuccess(false);
        e.preventDefault();
        UploadFileService.addData(selectedFile, 'profile_image').then((res) => {
            if (res == 'error') {
                setResMsg('Something went wrong')
            } else {
                if (res['status'] == true) { 
                     setResSuccess(true); 
                    if(res['id']!=null)
                    setImageId(res['id']);}
                setResMsg(res['message'])
            }
            handleShow();
            setFlagUloadImage(false);
        });

    
      };
      function handleChangeImage(event) {
        if (event.target.files && event.target.files[0]) {
            setSelectedFile(event.target.files[0])
            setImage(URL.createObjectURL(event.target.files[0]));
        }
    }

    return (
        <div className="UpdateTripHistory"> 
         <HeaderSubTitle title='History Details'/>
    
        {tripHistory.length<=0?<></>:
        <div className='trip-status-box'>
        <span className="dot"></span>
       { tripHistory[0].status==1?"Trip Status Preparing":
       
       tripHistory[0].status==2?"Trip Status Started":
       tripHistory[0].status==3?"Trip Status Finished":"Not Updated"
       }
        
        
        
        </div>
        }

          { tripHistory.length<=0?<></>:
          
          tripHistory[0].status==3?<></>:
           schoolLatng==null?<></>
        :tripHistory[0].is_pick_up==1?
        <>
        
      { schoolLatng.lat==null||schoolLatng.lng==null?
     <>Update School Latitude and Longitude to show google map</>:
      !isAssignUserLoading&&tripTravellers.length>0? 
      tripHistory.length<=0?<></>: <GoogleMapRouteLiveTrack 
      allLocation={tripTravellers} 
      schoolLat={schoolLatng.lat}
      schoolLng={schoolLatng.lng}
      schoolName={schoolLatng.title}
      isPickUp={1}
      busId={tripHistory[0].bus_id}
      />
      :<></>}
        </>
        :
        schoolLatng.lat==null||schoolLatng.lng==null?
     <>Update School Latitude and  Longitude to show google map</>:
      !isAssignUserLoading&&tripTravellers.length>0? 
  
      tripHistory.length<=0?<></>: <GoogleMapRouteLiveTrack 
      allLocation={tripTravellers} 
      schoolLat={schoolLatng.lat}
      schoolLng={schoolLatng.lng}
      schoolName={schoolLatng.title}
      isPickUp={0}
      busId={tripHistory[0].bus_id}
      
    
      />
      :<></>}
        <br/>
        <br/>
   <Table striped className="body-table">
                        <thead>
                          <tr>
                            <th>#</th>              
                            <th>Name</th>
                            <th>status</th>
                            <th>Time Stamp</th>
                    
                          </tr>
                        </thead>
                        <tbody>
                          {tripTravellerHistory.length == 0
                            ? "No Data Found"
                            : tripTravellerHistory &&
                            tripTravellerHistory.map((p,index) => {
                                let dateAndTimeC = "";
                                let dateAndTimeU = "";
        
                                dateAndTimeC = moment(p.created_at)
                                  .utc()
                                  .format("YYYY-MM-DD hh:mm:ss");
                                dateAndTimeU = moment(p.updated_at)
                                  .utc()
                                  .format("YYYY-MM-DD hh:mm:ss");
                                return (
                                  <tr key={p.id}>
                                    <td>{index+1}</td>
                                    <td>
                                      {p.first_name} {p.last_name}
                                    </td>
                                    <td> {
                                    p.status==1?"Picked":
                                      p.status==2?"Not Pciked":
                                      p.status==4?"Droped":
                                      p.status==5?"Not Droped":"--"
                                      }
                                  
                                    </td>
                                  
                                    <td>{dateAndTimeU}</td>              
                                  </tr>
                                );
                              })}
                        </tbody>
                      </Table>
                      <br/>
        <br/>
       
        </div>
    );

}

export default UpdateTripHistory;