import { Row, Col, Form, Button, Dropdown, Modal, Card } from 'react-bootstrap'
import "../../common/custoForm.css"
import { useEffect, useState } from 'react';
import LoadingCompinentCommon from '../../common/commonLoading';
import schoolServices from '../../services/schoolServices';
import { useNavigate, useParams } from 'react-router-dom';
import cityService from '../../services/cityService';
import { useLocation } from "react-router-dom";
import UploadFileService from '../../services/uploadFile';
import HeaderSubTitle from '../../header/headerSubTitle';
import api_url from "../../my_config"; 
import GoogleMapComponent from "../../components/googleMap/googleMap";
import "./school.css";
import validation from '../../helper/validation';

function UpdateSchool() {
    const [mapLatLng,setMapLatLng]=useState(null);
    const roleId=localStorage.getItem('roleId');
    const [showGoogleMapBox, setShowGoogleMapBox] = useState(false);
    const [pickMapForDrop,setPickMapForDrop]=useState(false);
    const { schoolId } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({
        email: '',
        password: "",
        title: "",
        description: "",
        cityId: "",
        image_id:null,
        image:null
    
    });
    const [isLoading, setLoading] = useState(false);
    const [resSuccess, setResSuccess] = useState(false);
    const [resMsg, setResMsg] = useState('')
    const [show, setShow] = useState(false);
    const [cities, setcities] = useState([]);
    const [dataisLoaded, setDataisLoaded] = useState(false);
    const [isDataNull, setisDataNull] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [image, setImage] = useState(null)
    const [imageId, setImageId] = useState(null)
    const [isImageUploading, setFlagUloadImage] = useState(false)
    const [errorTtile, setErrorsTitle] = useState(null);
    const [errorsLat, setErrorsLat] = useState(null);
    const [errorsLng, setErrorsLng] = useState(null);
    
    function handleClose() {
        setShow(false);
        // if (resSuccess == true)
        //     navigate(-1)
    }
    const handleShow = () => setShow(true);
    function getCity() {
        setLoading(true);
        cityService.getData()
            .then((data) => {
                setcities(data);
                setDataisLoaded(true);
            })
        schoolServices.getDataById(schoolId)
            .then((data) => {
                console.log("*********************")
                console.log(data);
           
                if (data == null)
                    setisDataNull(true)
                else {
                    setData({
                        'email': data['email'],
                        'password': '11111111',
                        'title':  data['title'],
                        'lat':  data['lat'],
                        'lng':  data['lng'],
                        'description': data['description'],
                        'cityId': data['city_id'],
                        'image_id':data['image_id'],
                        'image':data['image'],
                    })
                }

                setLoading(false);

            })
    }
    useEffect(() => {
        getCity();
    }, []);
    function handleSubmit(e) {
        e.preventDefault();
        let isValidTitle=false;
        let isValidLat=false;
        let isValidLng=false;
        const titleValidate=validation.checkNameValidation(data.title);
        if(titleValidate){
            isValidTitle=true;
            setErrorsTitle(null);
        }
            else{
                isValidTitle=false
                setErrorsTitle("Length must be greater than 3 letters");
            
            }
            const latValidate=validation.checkLatValidation(data.lat);
            if(latValidate){
                isValidLat=true;
                setErrorsLat(null);
            }
                else{
                    isValidLat=false
                    setErrorsLat("Enter valid latitude");
                
                }
                const lngValidate=validation.checkLngValidation(data.lng);
                if(lngValidate){
                    isValidLng=true;
                    setErrorsLng(null);
                }
                    else{
                        isValidLng=false
                        setErrorsLng("Enter valid longitude");
                    
                    }
                    if(isValidLat==true&&isValidLng==true&&isValidTitle==true){
                        setLoading(true);
                        setResMsg('')
                        setResSuccess(false);
                    
                 
                        if(selectedFile!=null)
                        { 
                          console.log("case 1")
                          schoolServices.updateData(schoolId, data.title, data.description, data.cityId,data.lat,data.lng,selectedFile,data.image_id).
                          then((res) => {
                              if (res == 'error') {
                                  setResMsg('Something went wrong')
                              } else {
                                  if (res['status'] == true) { setResSuccess(true); }
                                  setResMsg(res['message'])
                              }
                              handleShow();
                  
                              setLoading(false);
                          });}
                          else if(selectedFile==null){
                              
                              if(data.image_id==null)
                              {  
                                  console.log("case 2")
                                  schoolServices.updateData(schoolId, data.title, data.description, data.cityId,data.lat,data.lng,selectedFile,data.image_id)
                                  .then((res) => {
                                      if (res == 'error') {
                                          setResMsg('Something went wrong')
                                      } else {
                                          if (res['status'] == true) { setResSuccess(true); }
                                          setResMsg(res['message'])
                                      }
                                      handleShow();
                          
                                      setLoading(false);
                                  });}
                                  else if(data.image_id!=null&&data.image!=null)
                                  {  
                                      console.log("case 3")
                                      schoolServices.updateData(schoolId, data.title, data.description, data.cityId,data.lat,data.lng,selectedFile,null)
                                      .then((res) => {
                                          if (res == 'error') {
                                              setResMsg('Something went wrong')
                                          } else {
                                              if (res['status'] == true) { setResSuccess(true); }
                                              setResMsg(res['message'])
                                          }
                                          handleShow();
                              
                                          setLoading(false);
                                      });
                                  
                                  }
                                  else if(data.image_id!=null&&data.image==null)
                                  { 
                                      console.log("case 4")
                                    
                                      schoolServices.updateData(schoolId, data.title, data.description, data.cityId,data.lat,data.lng,selectedFile,data.image_id)
                                      .then((res) => {
                                          if (res == 'error') {
                                              setResMsg('Something went wrong')
                                          } else {
                                              if (res['status'] == true) { setResSuccess(true); }
                                              setResMsg(res['message'])
                                          }
                                          handleShow();
                              
                                          setLoading(false);
                                      });
                                  
                                  }
                                  
                          }

                    }
       
    }
    function handleChange(e) {
        const newData = { ...data }
        newData[e.target.id] = e.target.value
        setData(newData);

    }
    function handleRemoveImage(){
        setImage(null);
        setImageId(null);
        setSelectedFile(null);
        const newData = { ...data }
        newData['image'] =null;
        setData(newData);
        console.log(newData);
        
    }
    function handleChangeImage(event) {
        if (event.target.files && event.target.files[0]) {
            if (!event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i))
                 alert('not an image');
    
    else{
        let file_size = event.target.files[0].size;
        if(file_size>0&&file_size<512000)
   {    
    
    const newData = { ...data }
    newData['image'] =null;
    setData(newData);
    setSelectedFile(event.target.files[0])
        setImage(URL.createObjectURL(event.target.files[0]));
    }
        else{
            alert("File Size must be less then 500kb");
            // alert(file_size);
        }
    }
           
        }
    }
    function setCordinatesPickUpLatLng(cordinates){
    
        const newData = { ...data };
        newData["lat"] = cordinates.lat;
        newData["lng"] = cordinates.lng;
        setData(newData);
        
      }
    function handleShowGoogleMapBox(pickMapForDropStatus) {
        setPickMapForDrop(pickMapForDropStatus)
        setShowGoogleMapBox(true);
       
      }
 
      function handleCloseGoogleMapBox() {
        setShowGoogleMapBox(false);
      }
      function handleSubmitGoogleMap() {
        if(mapLatLng!=null){
            setCordinatesPickUpLatLng(mapLatLng);
        }
        setShowGoogleMapBox(false);
      }
    return (
        <div className='update-school'>  <HeaderSubTitle title='Update School'/>
         <Modal
        dialogClassName="map-modal"
        show={showGoogleMapBox}
        onHide={handleCloseGoogleMapBox}
        className="alert-box"
      >
        <Modal.Header closeButton>
          <Modal.Title>Mark Location</Modal.Title>
        </Modal.Header>
        <Modal.Body className="alert-box-body">
        {<GoogleMapComponent
        schoolLat={data.lat}
        schoolLng={data.lng}
        returnCordinates={setMapLatLng} />}  
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleSubmitGoogleMap()}>
            Submit
          </Button>
          <Button variant="secondary" onClick={() => handleCloseGoogleMapBox()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
             
        <div className="Cutso-Form">
            <Modal show={show} onHide={handleClose} className='alert-box'>
                <Modal.Header closeButton>
                    <Modal.Title className={resSuccess == false ? 'alert-box-title-error' : 'alert-box-title'}>Response</Modal.Title>
                </Modal.Header>
                <Modal.Body className='alert-box-body'>{resMsg}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
                </Modal.Footer>
            </Modal>

            <div className='form-section-update'>
            {/* <Card >
                <Card.Header>Credentials</Card.Header>
                <Card.Body className='form-card-body'>

                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label >
                                Email
                            </Form.Label>
                      
                                <Form.Control required onChange={(e) => handleChange(e)} name='email' id='email' value={data.email} type="email" placeholder="email@example.com" className='form-control' />
                      
                        </Form.Group>
                        {/* <Form.Group className="mb-3">
                            <Form.Label>
                                Password
                            </Form.Label>
                         
                                <Form.Control required onChange={(e) => handleChange(e)} type="password" placeholder="Password" name='password' id='password' value={data.password} />
                      
                        </Form.Group> 
                         {isLoading == true ? <LoadingCompinentCommon /> :
                            isDataNull == true ? <Button size="sm"  disabled variant="primary" type="submit">
                                Update
                            </Button> : <Button  size="sm"   disabled variant="primary" type="submit">
                                Update
                            </Button>}
                    </Form>

                </Card.Body>
            
            </Card>
            <br/> */}
            <Card >
                <Card.Header>Basic Information</Card.Header>
                <Card.Body className='form-card-body'>
                    <Form onSubmit={(e) => handleSubmit(e)}>
                        <Form.Group  className="mb-3">
                            <Form.Label className='form-label' >
                                City
                            </Form.Label>
                         
                                <Form.Control 
                                disabled={roleId==1?false:true}
                                required as="select" onChange={(e) => handleChange(e)} value={data.cityId} id='cityId' name='cityId'>
                                    <option key={'empty'} value={''}>...</option>
                                    {
                                        cities &&
                                        cities.map((p) => {

                                            return (
                                                <option key={p.id} value={p.id}>{p.title}</option>
                                            );
                                        })}

                                </Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label >
                                Title
                            </Form.Label>
                        
                                <Form.Control 
                                  isInvalid={!!errorTtile}
                                required onChange={(e) => handleChange(e)} type="text" placeholder="School Name" name='title' id='title' value={data.title} />
                                                                 <Form.Control.Feedback type="invalid">{errorTtile}</Form.Control.Feedback>

                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label >
                            Latitude 
                            </Form.Label>
                                <div class="input-group mb-3">
                                <Form.Control 
                                   isInvalid={!!errorsLat}
                                required onChange={(e) => handleChange(e)} type="text" placeholder="Latitude" name='lat' id='lat' value={data.lat} />
                                <div class="input-group-append">
                            <button class="btn btn-outline-secondary map-btn"
                            onClick={()=>handleShowGoogleMapBox(false)}
                            type="button"><i className="fa fa-map-marker fa-sm" aria-hidden="true"></i></button>
                        </div>
                              </div>
                            

                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label >
                            Longitude 
                            </Form.Label>
                            <div class="input-group mb-3">
                            <Form.Control 
                            isInvalid={!!errorsLng}           
                            required onChange={(e) =>
                                 handleChange(e)
                                 } type="text" placeholder="Longitude" name='lng' id='lng' value={data.lng} />
                                {/* <div class="input-group-append">
                            <button class="btn btn-outline-secondary map-btn"
                            onClick={()=>handleShowGoogleMapBox(false)}
                            type="button"><i className="fa fa-map-marker fa-sm" aria-hidden="true"></i></button>
                        </div> */}
                              </div>
                              <Form.Control.Feedback type="invalid">{errorsLng}</Form.Control.Feedback>

                        </Form.Group>
                        
                        <Form.Group  className="mb-3">
                            <Form.Label >
                                Description
                            </Form.Label>
                          
                                <Form.Control as="textarea" rows={3} 
                                onChange={(e) => 
                                 
    
                                        handleChange(e)
                                    
                               
                                } name='description' id='description' placeholder="About School" value={data.description} />
                          
                        </Form.Group>
                        {isLoading == true ? <LoadingCompinentCommon /> :
                            isDataNull == true ? <Button  size="sm"   disabled variant="primary" type="submit">
                                Update
                            </Button> : <Button 
                            disabled={roleId==1||roleId==2?false:true}
                            size="sm" variant="primary" type="submit">
                                Update
                            </Button>}
                    </Form>
                  
                </Card.Body>
            </Card>
            </div>
            
            <div className='image-box'>
            {

            data.image!=null&&data.image!=""?
            <img 
            src={'https://schoob.net/busappapifiles/public/uploads/images/'+data.image}
            alt="new"
            />
            :image==null?
            <div className='image-box-add'> <i className="fa fa-image" aria-hidden="true"></i></div>:
                <img id="image" src={image}/>} 
            
            {
                isLoading==true?
                <></>:
            isImageUploading==true?
            <>
            <br/>
            <LoadingCompinentCommon />
            </>
            :
            image==null&&data.image==null?
            <div className="form-group">
            <label htmlFor="myInput" className='image-operation-btn'>Select Image</label>
            <input id="myInput"type="file" name="file" className="mb-4" 

            style={{display:'none'}}
            onChange={(e) => handleChangeImage(e)}  />
            </div>:
            <>
                <button className='image-operation-btn' onClick={handleRemoveImage}>
                Remove
            </button>
            {/* <button className='image-operation-btn' onClick={handleUploadImage}>
                Upload Image
            </button> */}
            </>
            }
            </div>
   
        </div>
      
        </div>
    );

}

export default UpdateSchool;