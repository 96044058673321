import { Row, Col, Form, Button, Dropdown, Modal } from 'react-bootstrap'
import "../../common/custoForm.css" 

import { useEffect, useState } from 'react';
import LoadingCompinentCommon from '../../common/commonLoading';
import schoolServices from '../../services/schoolServices';
import { useNavigate } from 'react-router-dom';
import cityService from '../../services/cityService';
import UploadFileService from '../../services/uploadFile';
import BusesServices from '../../services/busesService';
import FirebaseService from '../../services/firebase';

import Select from 'react-select';
import HeaderSubTitle from "../../header/headerSubTitle";
import validation from '../../helper/validation';
import { toast } from 'react-toastify';
function AddBus() {
    const roleId=localStorage.getItem('roleId');
    const schoolId=localStorage.getItem('schoolId');
    const schoolTitle=localStorage.getItem('schoolTitle');
    const [selectedFile, setSelectedFile] = useState(null);
    const [schoolOPData,setschoolOPData] = useState([]);
    const [image, setImage] = useState(null)
    const [imageId, setImageId] = useState(null)
    const [isImageUploading, setFlagUloadImage] = useState(false)
    const navigate = useNavigate();
    const [data, setData] = useState({
        vehicle_number: "",
        company_name: "",
        title: "",
        color: "",
        school_id:""
    });
    const [isLoading, setLoading] = useState(false);
    const [resSuccess, setResSuccess] = useState(false);
    const [resMsg, setResMsg] = useState('')
    const [show, setShow] = useState(false);
    const [school, setSchool] = useState([]);
    const [errorsTtile, setErrorsTtile] = useState(null);
    const [errorsVechicleN, setErrorsVechicleN] = useState(null);
    const [errorsCompanyName, setErrorsCompanyName] = useState(null);
    const [errorColor, setErrorColor] = useState(null);

    function handleClose() {
        setShow(false);
        // if(resSuccess==true)
        // navigate(-1)
    }
    function handleShow  () {
        setShow(true)
    };
    function getSChool() {
        if(schoolId!="null"&&schoolId!=null)
        {        
   const newData = { ...data }
   newData["school_id"] = schoolId
   setData(newData);}
        schoolServices.getData()
            .then((data) => {
                const dataArr=[];
                data.map((e) => {
                    dataArr.push({
                         value: e.id, label: e.title 
                        
                    });
                });
                setschoolOPData([...dataArr]);

            })
    }
    useEffect(() => {
        getSChool();
    }, []);

    
    function handleChangeSchool(e) {
       
        const newData = { ...data }
        newData["school_id"] = e.value
        setData(newData);
        console.log(data);
    }
    function handleSubmit(e) {
        e.preventDefault();
        let isValidTite=false;
        let isValidVN=false;
        let isValidCopany=false;
        let isValidColor=false;
        const titleValidate=validation.checkNameValidation(data.title);
        if(titleValidate){
            isValidTite=true;
            setErrorsTtile(null);
        }
            else{
                isValidTite=false
                setErrorsTtile("Length must be greater than 3 letters");

            }
            const vNValidate=validation.checkVechicleNValidation(data.vehicle_number);
            if(vNValidate){
                isValidVN=true;
                setErrorsVechicleN(null);
            }
                else{
                    isValidVN=false
                    setErrorsVechicleN("Length must be greater than 5 letters");
                }
                const cNameValidate=validation.checkNameValidation(data.company_name);
                if(cNameValidate){
                    isValidCopany=true;
                    setErrorsCompanyName(null);
                }
                    else{
                        isValidCopany=false
                        setErrorsCompanyName("Length must be greater than 3 letters");
                    }
                const colorValidate=validation.checkColorValidation(data.color);
                if(colorValidate){
                    isValidColor=true;
                    setErrorColor(null);
                }
                    else{
                        isValidColor=false
                        setErrorColor("Length must be greater than 3 letters");
                    }
        if(isValidTite==true&&isValidVN==true&&isValidCopany==true&&isValidColor==true){
            setLoading(true);
            setResMsg('')
            setResSuccess(false);
            BusesServices.addData(data.school_id,data.title,data.vehicle_number,data.company_name,data.color,selectedFile).then((res) => {
                if (res == 'error') {
                    setResMsg('Something went wrong')
                } else {
                    if (res['status'] == false) { 
                        setResSuccess(true); 
                        setResMsg(res['message'])
                        handleShow(true);
                    }else if(res['status'] == true){
                        const body={
                            "lat":"",
                            "lng":""
                        }
                        FirebaseService.addData("bus",body,res['id'].toString())
            
                        navigate('/UpdateBus/'+res['id']);
                        toast.success(res['message'],
                        {
                            className: 'toast-success-container toast-success-container-after',
                        }
                        
                        );
                      
                    }
                   
                }
                                            
                setLoading(false);
            });
        }
    
    }
 
    function handleChange(e) {
        const newData = { ...data }
        newData[e.target.id] = e.target.value
        setData(newData);

    }
    function handleRemoveImage(){
        setImage(null);
        setImageId(null);
        setSelectedFile(null);
        
    }
  
    // const handleUploadImage = async (e) => {
    //     setFlagUloadImage(true);
    //     setResMsg('')
    //     setResSuccess(false);
    //     e.preventDefault();
    //     UploadFileService.addData(selectedFile, 'profile_image').then((res) => {
    //         if (res == 'error') {
    //             setResMsg('Something went wrong')
    //         } else {
    //             if (res['status'] == true) { 
    //                  setResSuccess(true); 
    //                 if(res['id']!=null)
    //                 setImageId(res['id']);
    //             }
    //             setResMsg(res['message'])
    //         }
    //         handleShow();
    //         setFlagUloadImage(false);
    //     });

    //   };
      function handleChangeImage(event) {

        if (event.target.files && event.target.files[0]) {
            if (!event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i))
                 alert('not an image');
    
    else{
        let file_size = event.target.files[0].size;
        if(file_size>0&&file_size<512000)
   {     setSelectedFile(event.target.files[0])
        setImage(URL.createObjectURL(event.target.files[0]));
    }
        else{
            alert("File Size must be less then 500kb");
            // alert(file_size);
        }
    }
           
        }
    }

    return (
    <>  
    <HeaderSubTitle title='Add New Bus'/>
        <div className="Cutso-Form">
         
            <Modal show={show} onHide={handleClose} className='alert-box'>
                <Modal.Header closeButton>
                    <Modal.Title className={resSuccess == false ? 'alert-box-title-error' : 'alert-box-title'}>Response</Modal.Title>
                </Modal.Header>
                <Modal.Body className='alert-box-body'>{resMsg}</Modal.Body>
                <Modal.Footer>
                    <Button   variant="secondary" onClick={() => handleClose()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className='form-section-update'>
            <Form onSubmit={(e) => handleSubmit(e)}> 
            <Form.Group className="mb-3">
                    <Form.Label className='form-label' >
                        School*
                    </Form.Label>
                    <Select
                   isDisabled={roleId==1?false:true}
                    defaultValue={schoolId=="null"||schoolTitle=="null"||schoolId==null||schoolTitle==null?null:{"value":schoolId,"label":schoolTitle}}
                    onChange={(e) => handleChangeSchool(e)}
                    options={schoolOPData}
                    />
                          
                 
                </Form.Group>
                {/* <Form.Group className="mb-3">
                    <Form.Label className='form-label' >
                        School*
                    </Form.Label>
                 
                        <Form.Control required as="select" onChange={(e) => handleChange(e)} value={data.school_id} id='school_id' name='school_id'>
                            <option key={'empty'} value={''}>...</option>
                            {
                                school &&
                                school.map((p) => {

                                    return (
                                        <option key={p.id} value={p.id}>{p.title} - {p.city_name}</option>
                                    );
                                })}

                        </Form.Control>
                 
                </Form.Group> */}
                
                <Form.Group  className="mb-3">
                    <Form.Label >
                        Title*
                    </Form.Label>
              
                        <Form.Control
                            isInvalid={!!errorsTtile}
                        required onChange={(e) => handleChange(e)} type="text" placeholder="Bus Name" name='title' id='title' value={data.title} />
                                                   <Form.Control.Feedback type="invalid">{errorsTtile}</Form.Control.Feedback>

                </Form.Group>
                <Form.Group  className="mb-3">
                    <Form.Label >
                        Vehicle Number*
                    </Form.Label>
             
                        <Form.Control 
                               isInvalid={!!errorsVechicleN}
                        required onChange={(e) => handleChange(e)} type="text" placeholder="CG045368" name='vehicle_number' id='vehicle_number' value={data.vehicle_number} />
                                                   <Form.Control.Feedback type="invalid">{errorsVechicleN}</Form.Control.Feedback>

                </Form.Group>
                <Form.Group  className="mb-3">
                    <Form.Label >
                        Company*
                    </Form.Label>
              
                        <Form.Control 
                            isInvalid={!!errorsCompanyName}
                        required onChange={(e) => handleChange(e)} type="text" placeholder="Tata" name='company_name' id='company_name' value={data.company_name} />
                                                   <Form.Control.Feedback type="invalid">{errorsCompanyName}</Form.Control.Feedback>

                </Form.Group>
                <Form.Group  className="mb-3">
                    <Form.Label >
                        Color*
                    </Form.Label>
              
                        <Form.Control 
                               isInvalid={!!errorColor}
                        required onChange={(e) => handleChange(e)} type="text" placeholder="Yello" name='color' id='color' value={data.color} />
                                                   <Form.Control.Feedback type="invalid">{errorColor}</Form.Control.Feedback>

                </Form.Group>
          
                {isLoading == true ? <LoadingCompinentCommon /> : 
           <Button size="sm"  variant="primary" type="submit">
           Submit
       </Button>
                }
            </Form>
        
            </div>
            <div className='image-box'>
           <div>
     </div>
           {image==null? <div className='image-box-add'> <i className="fa fa-image" aria-hidden="true"></i></div>:
                 <img id="image" src={image}/>} 
             {
                isLoading==true?
                <></>:
             isImageUploading==true?
             <>
             <br/>
             <LoadingCompinentCommon />
             </>
             :
             image==null?
             <div className="form-group">
             <label htmlFor="myInput" className='image-operation-btn'>Select Image</label>
              <input id="myInput"type="file" name="file" className="mb-4" 
               accept="image/*"
              style={{display:'none'}}
              onClick={(event)=> { 
                event.target.value = null
           }}
              onChange={(e) => handleChangeImage(e)}  />
             </div>:
             <>
               <button className='image-operation-btn' onClick={handleRemoveImage}>
                 Remove
              </button>
    
              {/* <button className='image-operation-btn' onClick={handleUploadImage}>
                 Upload Image
              </button> */}
              </>
              }
           </div>
        </div>
        </>
    );

}

export default AddBus;