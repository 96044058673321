import { Row, Col, Form, Button, Dropdown, Modal, Card } from 'react-bootstrap'
import "../../common/custoForm.css"
import { useEffect, useState } from 'react';
import LoadingCompinentCommon from '../../common/commonLoading';
import schoolServices from '../../services/schoolServices';
import { useNavigate, useParams } from 'react-router-dom';
import cityService from '../../services/cityService';
import { useLocation } from "react-router-dom";
import UploadFileService from '../../services/uploadFile';
import HeaderSubTitle from '../../header/headerSubTitle';
import EmpServices from '../../services/empService';
function UpdateEmp() {
    const { userID } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({
        email: "",
        password:"",
        address: "",
        first_name: "",
        last_name: "",
          phone_secondary:"",
          school_id:""
          
    });
    
    function getSChool() {
        schoolServices.getData()
            .then((data) => {
                setSchool(data);
            })
    }
    useEffect(() => {
        getSChool();
    }, []);
    const [school, setSchool] = useState([]);

    const [isLoading, setLoading] = useState(false);
    const [resSuccess, setResSuccess] = useState(false);
    const [resMsg, setResMsg] = useState('')
    const [show, setShow] = useState(false);
    const [userTypes, setUserTypes] = useState([]);
    const [isDataNull, setisDataNull] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [image, setImage] = useState(null)
    const [imageId, setImageId] = useState(null)
    const [isImageUploading, setFlagUloadImage] = useState(false)
    function handleClose() {
        setShow(false);
        if (resSuccess == true)
            navigate(-1)
    }
    const handleShow = () => setShow(true);
    function getCity() {
        setLoading(true);
        setUserTypes([
            {
                'id':"7",
                "title":"Manager"
            },
            {
                'id':"8",
                "title":"Operational Manager"
            },
            {
                'id':"9",
                "title":"Bus Driver"
            },
            {
                'id':"10",
                "title":"Bus Assistent"
            }
        
        ]);
        EmpServices.getDataById(userID)
            .then((data) => {
         
               if (data == null)
                    setisDataNull(true)
                else {
                    setData({
                        'email': data['email'],
                        'password': '',
                        "phone_secondary": data['phone_secondary'],
                        'first_name':  data['first_name'],
                        'last_name':  data['last_name'],
                        'address':  data['address'],
                        'user_type_id': data['user_type'],
                        'school_id':  data['school_id'],
                        
                    })
                }
                setLoading(false);

            })
    }
    useEffect(() => {
        getCity();
    }, []);
    function handleSubmit(e) {
        setLoading(true);
        setResMsg('')
        setResSuccess(false);
        e.preventDefault();
        EmpServices.updateData(userID,data.user_type_id,data.first_name,data.last_name,data.phone_secondary,data.address).then((res) => {
            if (res == 'error') {
                setResMsg('Something went wrong')
            } else {
                if (res['status'] == true) { setResSuccess(true); }
                setResMsg(res['message'])
            }
            handleShow();

            setLoading(false);
        });
    }
    function handleChange(e) {
        const newData = { ...data }
        newData[e.target.id] = e.target.value
        setData(newData);

    }
    function handleRemoveImage(){
        setImage(null);
        setImageId(null);
        setSelectedFile(null);
        
    }
  
    const handleUploadImage = async (e) => {
        setFlagUloadImage(true);
        setResMsg('')
        setResSuccess(false);
        e.preventDefault();
        UploadFileService.addData(selectedFile, 'profile_image').then((res) => {
            if (res == 'error') {
                setResMsg('Something went wrong')
            } else {
                if (res['status'] == true) { 
                     setResSuccess(true); 
                    if(res['id']!=null)
                    setImageId(res['id']);}
                setResMsg(res['message'])
            }
            handleShow();
            setFlagUloadImage(false);
        });

    
      };
      function handleChangeImage(event) {
        if (event.target.files && event.target.files[0]) {
            setSelectedFile(event.target.files[0])
            setImage(URL.createObjectURL(event.target.files[0]));
        }
    }

    return (
        <>  <HeaderSubTitle title='Update Employee'/>

        <div className="Cutso-Form">

            <Modal show={show} onHide={handleClose} className='alert-box'>
                <Modal.Header closeButton>
                    <Modal.Title className={resSuccess == false ? 'alert-box-title-error' : 'alert-box-title'}>Response</Modal.Title>
                </Modal.Header>
                <Modal.Body className='alert-box-body'>{resMsg}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
                </Modal.Footer>
            </Modal>
            <div className='form-section-update'>
            <Card>
                <Card.Header>Credentials</Card.Header>
                <Card.Body>

                    <Form>
                    <Form.Group  className="mb-3">
                    <Form.Label >
                        Email*
                    </Form.Label>
               
                        <Form.Control required onChange={(e) => handleChange(e)} name='email' id='email' value={data.email} type="email" placeholder="email@example.com" className='form-control' />
                
                </Form.Group>
                <Form.Group  className="mb-3">
                    <Form.Label >
                        Password*
                    </Form.Label>
           
                        <Form.Control required onChange={(e) => handleChange(e)} type="password" placeholder="Password" name='password' id='password' value={data.password} />
                
                </Form.Group>
        
                    </Form>

                </Card.Body>
            </Card>
            <br/>
            <Card>
                <Card.Header>Basic Information</Card.Header>
                <Card.Body>
                    <Form onSubmit={(e) => handleSubmit(e)}>
                        <Form.Group className="mb-3">
                            <Form.Label className='form-label' >
                                User Type
                            </Form.Label>
                           
                            <Form.Control required as="select" onChange={(e) => handleChange(e)} value={data.user_type_id} id='user_type_id' name='user_type_id'>
                            <option key={'empty'} value={''}>...</option>
                            {
                                userTypes &&
                                userTypes.map((p) => {

                                    return (
                                        <option key={p.id} value={p.id}>{p.title}</option>
                                    );
                                })}

                        </Form.Control>
                        
                        </Form.Group>
                        <Form.Group className="mb-3">
                    <Form.Label className='form-label' >
                        School*
                    </Form.Label>
                 
                        <Form.Control required as="select" disabled={true} onChange={(e) => handleChange(e)} value={data.school_id} id='school_id' name='school_id'>
                            <option key={'empty'} value={''}>...</option>
                            {
                                school &&
                                school.map((p) => {

                                    return (
                                        <option key={p.id} value={p.id}>{p.title} - {p.city_name}</option>
                                    );
                                })}

                        </Form.Control>
                 
                </Form.Group>
                        <Form.Group  className="mb-3">
                    <Form.Label >
                        First Name*
                    </Form.Label>
                
                        <Form.Control required onChange={(e) => handleChange(e)} type="text" placeholder="Jhon" name='first_name' id='first_name' value={data.first_name} />
                
                </Form.Group>
                <Form.Group  className="mb-3">
                    <Form.Label >
                        Last Name*
                    </Form.Label>
                   
                        <Form.Control required onChange={(e) => handleChange(e)} type="text" placeholder="Alexender" name='last_name' id='last_name' value={data.last_name} />
                  
                </Form.Group>
                <Form.Group  className="mb-3">
                    <Form.Label >
                    Phone Secondary
                    </Form.Label>
                   
                        <Form.Control  onChange={(e) => handleChange(e)} type="text" placeholder="1234567890" name='phone_secondary' id='phone_secondary' value={data.phone_secondary} />
                  
                </Form.Group>
                <Form.Group  className="mb-3">
                    <Form.Label >
                        Address
                    </Form.Label>
                  
                        <Form.Control as="textarea" rows={3} onChange={(e) => handleChange(e)} placeholder='Address' name='address' id='address' value={data.address} />
                   
                </Form.Group>
                        {isLoading == true ? <LoadingCompinentCommon /> :
                            isDataNull == true ? <Button size="sm"   disabled variant="primary" type="submit">
                                Update
                            </Button> : <Button  size="sm" variant="primary" type="submit">
                                Update
                            </Button>}
                    </Form>
                  
                </Card.Body>
            </Card>
            </div>
            
            <div className='image-box'>
           <div>

  </div>
           {image==null? <div className='image-box-add'> <i className="fa fa-image" aria-hidden="true"></i></div>:
                 <img id="image" src={image}/>} 
             {
                isLoading==true?
                <></>:
             isImageUploading==true?
             <>
             <br/>
             <LoadingCompinentCommon />
             </>
             :
             image==null?
             <div className="form-group">
             <label htmlFor="myInput" className='image-operation-btn'>Select Image</label>
              <input id="myInput"type="file" name="file" className="mb-4" 
              style={{display:'none'}}
              onChange={(e) => handleChangeImage(e)}  />
             </div>:
             <>
               <button className='image-operation-btn' onClick={handleRemoveImage}>
                 Remove
              </button>
    
              <button className='image-operation-btn' onClick={handleUploadImage}>
                 Upload Image
              </button>
              </>
              }
           </div>
        </div>
        </>
    );

}

export default UpdateEmp;