import "../../common/custoForm.css"
import { Row, Col, Form, Button, Dropdown, Modal, Card, Table } from 'react-bootstrap'

import { useEffect, useState } from 'react';
import LoadingCompinentCommon from '../../common/commonLoading';
import HeaderSubTitle from "../../header/headerSubTitle";
import ImportDataServices from '../../services/importDataService';

import "./importData.css" 
function ImportStudentData(){
    const schoolId=localStorage.getItem('schoolId');
    const [selctedFile, setSelctedFile] = useState(null);
    function handleChangeImage(event) {
        if (event.target.files && event.target.files[0]) {
            if (!event.target.files[0].name.match(/\.(xlsx|xls)$/i))
                 alert('File formats must be .xlsx or .xls');
    
    else{
        let file_size = event.target.files[0].size;
        if(file_size>0&&file_size<2048000)
   {    
    
    setSelctedFile(event.target.files[0])

    
        // setImage(URL.createObjectURL(event.target.files[0]));
    }
        else{
            alert("File Size must be less then 20mb");
            // alert(file_size);
        }
    }
           
        }
    }
    function handleClose() {
        setShow(false);
    }
    const handleShow = () => setShow(true);
    const [isLoading, setLoading] = useState(false);
    const [resSuccess, setResSuccess] = useState(false);
    const [resMsg, setResMsg] = useState('')
    const [show, setShow] = useState(false);
    const handleCloseButtonClick = () => {
        // Logic to handle the close button press
        console.log('Close button pressed');
        setSelctedFile(null);
      }

      function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        setResMsg('')
        setResSuccess(false);
        if(selctedFile!=null)
        { 
          console.log("case 1")
          ImportDataServices.addData(schoolId,selctedFile)
          .then((res) => {
              if (res == 'error') {
                  setResMsg('Something went wrong')
              } else {
                  if (res['status'] == true) { setResSuccess(true); 
                    setSelctedFile(null)}
                  setResMsg(res['message'])
              }
              handleShow();
              setLoading(false);
            
          });}
      }

    return (<div className='ImportStudentData' >
           <Modal show={show} onHide={handleClose} className='alert-box'>
                <Modal.Header closeButton>
                    <Modal.Title className={resSuccess == false ? 'alert-box-title-error' : 'alert-box-title'}>Response</Modal.Title>
                </Modal.Header>
                <Modal.Body className='alert-box-body'>{resMsg}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
                </Modal.Footer>
            </Modal>
    <HeaderSubTitle title='Import Students'/>
    <div className="spacer-2x"></div>
    <h4>Excel Sheet Formate</h4>
    <ul>
        <li>parent_number</li>
        <li>parent_number_2</li>
        <li>student_first_name</li>
        <li>student_last_name</li>
    </ul>
    <Form onSubmit={(e) => handleSubmit(e)}>

            {selctedFile==null?<>
             <label htmlFor="myInput" className='image-operation-btn'>Select File</label>
              <input id="myInput"type="file" name="file" className="mb-4" 

              style={{display:'none'}}
              onChange={(e) => handleChangeImage(e)}  /></>:
              <>
                <span className="selectedFileName" > {selctedFile.name}</span>
                <span  onClick={handleCloseButtonClick} class="close-btn">&times;</span>
              </>
              
       }
              
             <span className="spacer-right-2x"></span>
    {isLoading == true ? <LoadingCompinentCommon /> :
                    
                     <Button  disabled={selctedFile === null} size="sm" variant="primary" type="submit">
                                Upload
                            </Button>}
                    </Form>

    </div>)
}

export default ImportStudentData;