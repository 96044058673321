import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SchoolTable from './pages/school/schoolTable';
import AddSchool from './pages/school/add_school';
import UpdateSchool from './pages/school/updateSchool';
import UserTable from './pages/users/users';
import AddUser from './pages/users/addUser';
import UpdateUsers from './pages/users/updateUser';
import EmpTable from './pages/employees/empTable';
import AddEmp from './pages/employees/addEmp';
import UpdateEmp from './pages/employees/updateEmp';
import BusTable from './pages/buses/busTable';
import AddBus from './pages/buses/addBus';
import UpdateBus from './pages/buses/updateBus';
import TripTable from './pages/trip/tripTabel';
import AddTrip from './pages/trip/addTrip';
import UpdateTrip from './pages/trip/updateTrip';
import TripHistory from './pages/trip/tripHistory';
import UpdateTripHistory from './pages/trip/updateTripHistory';
import SchoolDashBoard from './pages/dashboad/schoolDashboad';
import LoginPage from './pages/login/loginPage';
import NotificationTable from './pages/notification/nottificationTable';
import AddNotification from './pages/notification/addNotification';
import TrackAllBuses from './pages/trackAllBus';
import ImportStudentData from './pages/importData/importStudentData';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
      <BrowserRouter>
       <Routes>
      <Route path="/" element={<App />}>
      {/* <Route index element={<App />} /> */}
      <Route path="Login" element={<LoginPage />} />

      <Route path="SchoolTable" element={<SchoolTable />} />
      <Route path="AddSchool" element={<AddSchool/>} />
      
      <Route path="UpdateSchool/:schoolId" element={<UpdateSchool/>} />
      <Route path="UserTable" element={<UserTable />} />
      <Route path="AddUser" element={<AddUser/>} />
      <Route path="UpdateUsers/:userID" element={<UpdateUsers/>} />

      <Route path="EmpTable" element={<EmpTable/>} />
      <Route path="AddEmp" element={<AddEmp/>} />
      <Route path="UpdateEmp/:userID" element={<UpdateEmp/>} />

      <Route path="BusTable" element={<BusTable/>} />
      <Route path="AddBus" element={<AddBus/>} />
      <Route path="UpdateBus/:ID" element={<UpdateBus/>}/>

      
      <Route path="TripTable" element={<TripTable/>} />
      <Route path="AddTrip" element={<AddTrip/>} />
      <Route path="UpdateTrip/:ID" element={<UpdateTrip/>}/>
      
      <Route path="TripHistory" element={<TripHistory/>} />
      <Route path="UpdateTripHistory/:ID" element={<UpdateTripHistory/>}/>
      
      <Route path="SchoolDashBoard" element={<SchoolDashBoard/>} />

      <Route path="NotificationTable" element={<NotificationTable/>} />
      <Route path="AddNotification" element={<AddNotification/>} />

      <Route path="TrackAllBuses" element={<TrackAllBuses/>} />
      <Route path="ImportStudentData" element={<ImportStudentData/>} />
      
      
      </Route>
      </Routes>
      </BrowserRouter>
  // </React.StrictMode> 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
