import api_url from "../my_config"; 
class TripService{
    getUrl=api_url+'/api/get_trip';
    getUrlBySchool=api_url+'/api/get_trip/school';
    deleteTripUrl=api_url+'/api/delete_trip';

    getTDBTIDUrl=api_url+'/api/get_trip_details/trip';
    getTTHBTHIDUrl=api_url+'/api/get_trip_traveller_history';
    getTripEMPBTDIDUrl=api_url+'/api/get_bus_trip_emp/btd';
    getTripTravellerUrl=api_url+'/api/get_trip_traveller';
    getAssignEmpByTripUrl=api_url+'/api/get_trip_emp/trip';
    addUrl=api_url+'/api/add_trip';
    addTHUrl=api_url+'/api/add_trip_history';
    addUserTripUrl=api_url+'/api/add_trip_traveller';
    updateUserTripUrl=api_url+'/api/update_trip_traveller_details';
    addEmpTripUrl=api_url+'/api/add_trip_emp';
    addBusTripEmpUrl=api_url+'/api/add_bus_trip_emp';
    addBusTripDetailsUrl=api_url+'/api/add_bus_trip_details';
    deleteUserTripUrl=api_url+'/api/delete_trip_traveller';
    updateUrl=api_url+'/api/update_trip';
    updateBusTripDetailsUrl=api_url+'/api/update_bus_trip_details';
    
    updateTripTravellerOrderUrl=api_url+'/api/update_trip_traveller_order';
    deleteEmpTripUrl=api_url+'/api/delete_trip_emp';
    deleteTripEmpUrl=api_url+'/api/delete_bus_trip_emp';
    getTHById=api_url+'/api/get_trip_history';
    getTHBBYDIDUrl=api_url+'/api/get_trip_history/btd';
    getTHBBYSchoolIDUrl=api_url+'/api/get_trip_history/school';
    getTripInitUserUrl=api_url+'/api/get_trip_initial_users';
    getSchoolInffoByTHIDUrl=api_url+'/api/get_trip_history/info_school';
    getBusInffoByTHIDUrl=api_url+'/api/get_trip_history/info_bus';
    getTHByBusIDUrl=api_url+'/api/get_trip_history/bus';
    getBusTripDetailsInfoByTHIDUrl=api_url+'/api/get_trip_history/info_bus_trip_details';
    async getHistoryByBusId(id) {
      try{
        // Default options are marked with *
        const response = await fetch(this.getTHByBusIDUrl+"/"+id, {
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          // body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
            if(response.status==200){
              const data = await response.json();
              if(data['response']==200){
                if( data['data']==null)
                return []; 
             else return data['data']; 
              }
              else return [];
            }else return[];
          }catch(e){
            return[];
          }
    
     // parses JSON response into native JavaScript objects
    }
    async getHistoryById(id) {
      try{
        // Default options are marked with *
        const response = await fetch(this.getTHById+"/"+id, {
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
            'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          // body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
            if(response.status==200){
              const data = await response.json();
              if(data['response']==200){
                if( data['data']==null)
                return []; 
             else return data['data']; 
              }
              else return [];
            }else return[];
          }catch(e){
            return[];
          }
    
     // parses JSON response into native JavaScript objects
    }
    async getHistoryByBTDId(btd_id) {
      try{
      // Default options are marked with *
  
      const response = await fetch(this.getTHBBYDIDUrl+'/'+btd_id, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
    
            if(data['response']==200){
            if(data['data']==null)
            return null;
           else return data['data']; 
            }
            else return null;
          }else return null ;
        }catch(e){
          return null;
        }
    
     // parses JSON response into native JavaScript objects
    }
    async getSChoolInfoByTHID(thid) {
      try{

  
      const response = await fetch(this.getSchoolInffoByTHIDUrl+'/'+thid, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
    
            if(data['response']==200){
            if(data['data']==null)
            return null;
           else return data['data']; 
            }
            else return null;
          }else return null ;
        }catch(e){
          return null;
        }
    
     // parses JSON response into native JavaScript objects
    }
    //
    async getBusTripDetailsInfoByTHID(thid) {
      try{
  
      const response = await fetch(this.getBusTripDetailsInfoByTHIDUrl+'/'+thid, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
    
            if(data['response']==200){
            if(data['data']==null)
            return null;
           else return data['data']; 
            }
            else return null;
          }else return null ;
        }catch(e){
          return null;
        }
    
     // parses JSON response into native JavaScript objects
    }
    async getBusInfoByTHID(thid) {
      try{
  
      const response = await fetch(this.getBusInffoByTHIDUrl+'/'+thid, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
    
            if(data['response']==200){
            if(data['data']==null)
            return null;
           else return data['data']; 
            }
            else return null;
          }else return null ;
        }catch(e){
          return null;
        }
    
     // parses JSON response into native JavaScript objects
    }
    async getHistoryBySchoolId(school_id) {
      try{
      // Default options are marked with *
  
      const response = await fetch(this.getTHBBYSchoolIDUrl+'/'+school_id, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
    
            if(data['response']==200){
            if(data['data']==null)
            return null;
           else return data['data']; 
            }
            else return null;
          }else return null ;
        }catch(e){
          return null;
        }
    
     // parses JSON response into native JavaScript objects
    }
    
    async getDataBySchoolId(school_id) {
      try{
      // Default options are marked with *
      const response = await fetch(this.getUrlBySchool+"/"+school_id, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
              if( data['data']==null)
              return []; 
           else return data['data']; 
            }
            else return [];
          }else return[];
        }catch(e){
          return[];
        }
    }
   async getData() {
       try{
       // Default options are marked with *
       const response = await fetch(this.getUrl, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
           'Content-Type': 'application/json',
           'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
           // 'Content-Type': 'application/x-www-form-urlencoded',
         },
         redirect: 'follow', // manual, *follow, error
         referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
         // body: JSON.stringify(data) // body data type must match "Content-Type" header
       });
           if(response.status==200){
             const data = await response.json();
             if(data['response']==200){
               if( data['data']==null)
               return []; 
            else return data['data']; 
             }
             else return [];
           }else return[];
         }catch(e){
           return[];
         }
     }
     async getAssignTripEmpData(tripId) {
      try{

      // Default options are marked with *
      const response = await fetch(this.getAssignEmpByTripUrl+"/"+tripId, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
              if( data['data']==null)
              return []; 
           else return data['data']; 
            }
            else return [];
          }else return[];
        }catch(e){
          return[];
        }
    }
    
    async getTripInitUsersByTriphIdByStatus(tripHId,status) {
      //status 1 for get all present users
 
      try{
    // Default options are marked with *
    const response = await fetch(this.getTripInitUserUrl+'/trip_history_id/'+tripHId+'/'+status, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      // body: JSON.stringify(data) // body data type must match "Content-Type" header
    });

        if(response.status==200){
          const data = await response.json();
          if(data['response']==200){
            if( data['data']==null)
            return []; 
         else 
                return data['data']; }
          
          else return [];
        }else return[];
      }catch(e){
        return[];
      }
  
   // parses JSON response into native JavaScript objects
  }

     async getTripeTravellerByTripIdData(tripId,fetchOrder) {
        try{
      // Default options are marked with *
      const response = await fetch(this.getTripTravellerUrl+'/trip/filter/'+tripId+'/'+fetchOrder, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
              if( data['data']==null)
              return []; 
           else 
                  return data['data']; }
            
            else return [];
          }else return[];
        }catch(e){
          return[];
        }
    
     // parses JSON response into native JavaScript objects
    }
     async getDataById(id) {
       try{
       // Default options are marked with *
       const response = await fetch(this.getUrl+'/'+id, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
           'Content-Type': 'application/json',
           'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
           // 'Content-Type': 'application/x-www-form-urlencoded',
         },
         redirect: 'follow', // manual, *follow, error
         referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
         // body: JSON.stringify(data) // body data type must match "Content-Type" header
       });
           if(response.status==200){
             const data = await response.json();
             if(data['response']==200){
             if(data['data']==null)
             return null;
            else return data['data']; 
             }
             else return null;
           }else return null ;
         }catch(e){
           return null;
         }
     
      // parses JSON response into native JavaScript objects
     }
     
     async getTripDetailsByTripId(id,isPickUp) {
      try{
      // Default options are marked with *
      const response = await fetch(this.getTDBTIDUrl+'/'+id+"/"+isPickUp, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
            if(data['data']==null)
            return null;
           else return data['data']; 
            }
            else return null;
          }else return null ;
        }catch(e){
          return null;
        }
    
     // parses JSON response into native JavaScript objects
    }
    async getTripDetailsByTripIdFIlterOrder(id,isPickUp) {
      try{
      // Default options are marked with *
      const response = await fetch(this.getTDBTIDUrl+'/'+id+"/filter/"+isPickUp, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
            if(data['data']==null)
            return null;
           else return data['data']; 
            }
            else return null;
          }else return null ;
        }catch(e){
          return null;
        }
    
     // parses JSON response into native JavaScript objects
    }
    async getTripEmpByTriDId(id) {
      try{
      // Default options are marked with *
      const response = await fetch(this.getTripEMPBTDIDUrl+'/'+id, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
            if(data['data']==null)
            return null;
           else return data['data']; 
            }
            else return null;
          }else return null ;
        }catch(e){
          return null;
        }
    
     // parses JSON response into native JavaScript objects
    }
    async getTripTHByTHId(id) {
      try{
        console.log("--------------------------");
        console.log(this.getTTHBTHIDUrl+'/trip_h_id/'+id);
      // Default options are marked with *
      const response = await fetch(this.getTTHBTHIDUrl+'/trip_h_id/'+id, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });

          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
            if(data['data']==null)
            return null;
           else return data['data']; 
            }
            else return null;
          }else return null ;
        }catch(e){
          return null;
        }
    
     // parses JSON response into native JavaScript objects
    }

     async addData(school_id,title,imageFile) {
       try{
        const formData = new FormData();
        if(imageFile!=null)
      { formData.append("image", imageFile);}
      if(school_id!=null)
       formData.append("school_id", school_id);
       if(title!=null)
       formData.append("title", title);
       // Default options are marked with *
       const response = await fetch(this.addUrl, {
         method: 'POST', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
          // 'Content-Type': 'application/json',
           'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
           // 'Content-Typse': 'application/x-www-form-urlencoded',
         },
         redirect: 'follow', // manual, *follow, error
         referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
         body: formData // body data type must match "Content-Type" header
       });
           if(response.status==200){
             const data = await response.json();
             if(data['response']==201||data['response']==200)
             return data; 
             else return "error";
           }else return"error";
         }catch(e){
        
           return"error";
         }
     
      // parses JSON response into native JavaScript objects
     }
     async addTHData(tripId, isPickUp) {

    // console.log("oooooooooooooooooooooooooooooooo");
    // console.log(tripId);
      try{
      // Default options are marked with *
      const response = await fetch(this.addTHUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(
          {
            "trip_id":tripId,   
            "is_pick_up":isPickUp 
        }
        
        ) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }
    async delete(id) {
      try{
      // Default options are marked with *
      const response = await fetch(this.deleteTripUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(
          {
            "id":id,
   
        }
      
        
        ) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }
     async addBusTripEmp(btdId,empId) {
      try{
      // Default options are marked with *
      const response = await fetch(this.addBusTripEmpUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(
          {
            "user_id":empId,
            "btd_id":btdId
        }
      
        
        ) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }
     async addDataEmpTrip(userId,tripId,is_pick_up,is_drop) {
      try{
      // Default options are marked with *
      const response = await fetch(this.addEmpTripUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(
         {
   

           "user_id":userId,
            "trip_id":tripId,
            "is_pick_up":is_pick_up==null||is_pick_up==undefined?false:is_pick_up,
            "is_drop":is_drop==null||is_drop==undefined?false:is_drop,
    
       }
        
        ) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }
    async addDataUserTrip(
      trip_traveller_id,
      userId,
      tripId,
      is_pick_up,
      is_drop,
      pick_up_building_number,
      pick_up_street_name,
      pick_up_area_name,
      pick_up_pin_code,
      pick_up_city,
      pick_up_lat,
      pick_up_lng,
      drop_building_number,
      drop_street_name,
      drop_area_name,
      drop_pin_code,
      drop_city,
      drop_lat,
      drop_lng
      ) {
      try{
      let url=trip_traveller_id!=null&&trip_traveller_id!=""?this.updateUserTripUrl:this. addUserTripUrl;
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(
         {
          "trip_traveller_id":trip_traveller_id,
           "user_id":userId,
            "trip_id":tripId,
            "is_pick_up":is_pick_up,
            "is_drop":is_drop,
            "pick_up_building_number":pick_up_building_number,
            "pick_up_street_name":pick_up_street_name,
            "pick_up_area_name":pick_up_area_name,
            "pick_up_pin_code":pick_up_pin_code,
            "pick_up_city":pick_up_city,
            "pick_up_lat":pick_up_lat,
            "pick_up_lng":pick_up_lng,
            "drop_building_number":drop_building_number,
            'drop_street_name':drop_street_name,
            "drop_area_name":drop_area_name,
            "drop_pin_code":drop_pin_code,
            "drop_city":drop_city,
            "drop_lat":drop_lat,
            "drop_lng":drop_lng
            

       }
        
        ) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }
    async addBusTripDetails(
      trip_id,
      bus_id,
      start_time,
      days,
      is_pick_up
      ) {
      try{

      // Default options are marked with *
      const response = await fetch(this.addBusTripDetailsUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(
         {
          "trip_id":trip_id,
          "bus_id":bus_id,
          "start_time":start_time,
          "days":days,
          "is_pick_up":is_pick_up      
            
       }
        
        ) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }

   
    
    async deleteDataUserTrip(tripTravellerId) {
      try{
      // Default options are marked with *
      const response = await fetch(this.deleteUserTripUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(
         {
           "id":tripTravellerId,
        

       }
        
        ) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }
    async deleteDataEmpTrip(tripEmpId) {
      try{
      // Default options are marked with *
      const response = await fetch(this.deleteEmpTripUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(
         {
           "id":tripEmpId,
        

       }
        
        ) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }
    async deleteTripBusEmp(empID) {
      try{
      // Default options are marked with *
      const response = await fetch(this.deleteTripEmpUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(
         {
           "id":empID,
        

       }
        
        ) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }
    async updateOrder(tripTravellers) {
      try{
      // Default options are marked with *
      const response = await fetch(this.updateTripTravellerOrderUrl, {
    
     
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(
         {   
           "trip_travellers":tripTravellers
         })

                 // body data type must match "Content-Type" header
      });

          if(response.status==200){
            const data = await response.json();
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
          console.log(e);
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }

     async updateData(id,school_id,title,imageFile,image_id,pickUpSTime,dripStime,bus_id,assign_id) {
       try{
       // Default options are marked with *
       const formData = new FormData();
       if(imageFile!=null)
     { formData.append("image", imageFile);}
     if(image_id!=null)
     { formData.append("image_id", image_id);}
     if(id!=null)
     formData.append("id", id);
     if(school_id!=null)
      formData.append("school_id", school_id);
      if(title!=null)
      formData.append("title", title);
      if(pickUpSTime!=null)
      formData.append("pick_up_start_time", pickUpSTime);
      if(dripStime!=null)
      formData.append("drop_start_time", dripStime);
      if(bus_id!=null)
      formData.append("bus_id", bus_id);
      if(assign_id!=null)
      formData.append("assign_id", assign_id);
      console.log("[[[[[[[[[[]]]]]]]]]]]]]]]",assign_id)
      console.log("[[[[[[[[[[]]]]]]]]]]]]]]]",bus_id)

       const response = await fetch(this.updateUrl, {
         method: 'POST', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
          // 'Content-Type': 'application/json',
           'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
           // 'Content-Type': 'application/x-www-form-urlencoded',
         },
         redirect: 'follow', // manual, *follow, error
         referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
         body: formData // body data type must match "Content-Type" header
       });
 
           if(response.status==200){
             const data = await response.json();
             if(data['response']==201||data['response']==200)
             return data; 
             else return "error";
           }else return"error";
         }catch(e){
        
           return"error";
         }
     
      // parses JSON response into native JavaScript objects
     }
     async updateBusTripDetailsData(
      id,
      trip_id,
      bus_id,
      start_time,
      days,
      is_pick_up

     ) {
      try{
      // Default options are marked with *
   
      const response = await fetch(this.updateBusTripDetailsUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(
         {   
          "id":id,
          "start_time":start_time,
          "days":days,
           
       }
        
        ) // body data type must match "Content-Type" header
      });
      console.log("--------------------------------");
      console.log(response);
          if(response.status==200){
            const data = await response.json();
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }



}
   
export default new TripService();