import api_url from "../my_config"; 
class BusesServices{
    getUrl=api_url+'/api/get_bus';
    addUrl=api_url+'/api/add_bus';
    updateUrl=api_url+'/api/update_bus';
    deleteUrl=api_url+'/api/delete_bus';
    
   async getData() {
       try{
       // Default options are marked with *
       const response = await fetch(this.getUrl, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
           'Content-Type': 'application/json',
           'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
           // 'Content-Type': 'application/x-www-form-urlencoded',
         },
         redirect: 'follow', // manual, *follow, error
         referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
         // body: JSON.stringify(data) // body data type must match "Content-Type" header
       });
           if(response.status==200){
             const data = await response.json();
             if(data['response']==200){
               if( data['data']==null)
               return []; 
            else return data['data']; 
             }
             else return [];
           }else return[];
         }catch(e){
           return[];
         }
     
      // parses JSON response into native JavaScript objects
     }
     async getDataById(id) {
       try{
       // Default options are marked with *
       const response = await fetch(this.getUrl+'/'+id, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
         mode: 'cors', // no-cors, *cors, same-origin
         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
         credentials: 'same-origin', // include, *same-origin, omit
         headers: {
           'Content-Type': 'application/json',
           'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
           // 'Content-Type': 'application/x-www-form-urlencoded',
         },
         redirect: 'follow', // manual, *follow, error
         referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
         // body: JSON.stringify(data) // body data type must match "Content-Type" header
       });
       if(response.status==200){
        const data = await response.json();
        if(data['response']==200){
          if( data['data']==null)
          return []; 
       else return data['data']; 
        }
        else return [];
      }else return[];
    }catch(e){
      return[];
    }
     
      // parses JSON response into native JavaScript objects
     }
     async getDataBySchooId(school_id) {
      try{
        console.log(this.getUrl+'/school/'+school_id);
      // Default options are marked with *
      const response = await fetch(this.getUrl+'/school/'+school_id, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
          if(response.status==200){
            const data = await response.json();
            if(data['response']==200){
            if(data['data']==null)
            return null;
           else return data['data']; 
            }
            else return null;
          }else return null ;
        }catch(e){
          return null;
        }
    
     // parses JSON response into native JavaScript objects
    }
     async addData(school_id,title,vehicle_number,company_name,color,imageFile) {
    
       try{
                const formData = new FormData();
         if(imageFile!=null)
       { formData.append("image", imageFile);}
       if(school_id!=null)
        formData.append("school_id", school_id);
        if(title!=null)
        formData.append("title", title);
        if(vehicle_number!=null)
       formData.append("vehicle_number", vehicle_number);
       if(company_name!=null)
       formData.append("company_name", company_name);
       if(color!=null)
       formData.append("color", color);
       const response = await fetch(this.addUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: formData // body data type must match "Content-Type" header
      });
           if(response.status==200){
             const data = await response.json();
             if(data['response']==201||data['response']==200)
             return data; 
             else return "error";
           }else return"error";
         }catch(e){
        
           return"error";
         }
     
      // parses JSON response into native JavaScript objects
     }
     async delete(id) {
      try{
      // Default options are marked with *
      const response = await fetch(this.deleteUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(
          {
           "id":id
          }
        
        ) // body data type must match "Content-Type" header
      });

          if(response.status==200){
            const data = await response.json();
            if(data['response']==201||data['response']==200)
            return data; 
            else return "error";
          }else return"error";
        }catch(e){
       
          return"error";
        }
    
     // parses JSON response into native JavaScript objects
    }
     async updateData(id,school_id,title,vehicle_number,company_name,color,imageFile,image_id) {
       try{
       
        const formData = new FormData();
         if(imageFile!=null)
       { formData.append("image", imageFile);}
       if(image_id!=null)
       { formData.append("image_id", image_id);}
       if(id!=null)
       formData.append("id", id);
       if(school_id!=null)
        formData.append("school_id", school_id);
        if(title!=null)
        formData.append("title", title);
        if(vehicle_number!=null)
       formData.append("vehicle_number", vehicle_number);
       if(company_name!=null)
       formData.append("company_name", company_name);
       if(color!=null)
       formData.append("color", color);
       // Default options are marked with *
       const response = await fetch(this.updateUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Authorization':"Bearer 65|T2Uj2kuLmoYvYH81fkauycJ6EWUjrUNyWuseb3kc"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: formData // body data type must match "Content-Type" header
      });
  
           if(response.status==200){
             const data = await response.json();
             if(data['response']==201||data['response']==200)
             return data; 
             else return "error";
           }else return"error";
         }catch(e){
        
           return"error";
         }
     
      // parses JSON response into native JavaScript objects
     }

}
   
export default new BusesServices();