import { Row, Col, Form, Button, Dropdown, Modal } from 'react-bootstrap'
import "../../common/custoForm.css" 
import Select from 'react-select';
import { useEffect, useState } from 'react';
import LoadingCompinentCommon from '../../common/commonLoading';
import schoolServices from '../../services/schoolServices';
import { useNavigate } from 'react-router-dom';
import UserServices from '../../services/userService';
import UploadFileService from '../../services/uploadFile';
import HeaderSubTitle from '../../header/headerSubTitle';
import roleService from '../../services/roleService';
import validation from '../../helper/validation';


function AddUser() {
    
    const roleId=localStorage.getItem('roleId');
    const schoolId=localStorage.getItem('schoolId');
    const schoolTitle=localStorage.getItem('schoolTitle');
    const [image, setImage] = useState(null)
const [imageId, setImageId] = useState(null)
const [isImageUploading, setFlagUloadImage] = useState(false)
const [selectedFile, setSelectedFile] = useState(null);
const [errorsFName, setErrorsFName] = useState(null);
const [errorsLName, setErrorsLName] = useState(null);
const [errorPhone, setErrorsPhone] = useState(null);
const [errorRole, setErrorsRole] = useState(null);
const [errorPass, setErrorsPass] = useState(null);

    const navigate = useNavigate();
    const [data, setData] = useState({
        phone: "",
        address: "",
        first_name: "",
        last_name: "",
      
    });
    const [schoolOPData,setschoolOPData] = useState([]);
    const [selectedRoolId, setselectedRoolId] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [resSuccess, setResSuccess] = useState(false);
    const [resMsg, setResMsg] = useState('')
    const [show, setShow] = useState(false);
    const [userTypes, setUserTypes] = useState([]);
   

    function handleClose() {
        setShow(false);
        if(resSuccess===true)
            navigate(-1)
    }
    function handleShow  (isNavigateBack) {
   
        setShow(true)
    };
    function getCity() { 
        if(roleId==1){
  
            setUserTypes([{value:2,label:"Admin"}])
        }else{
            if(schoolId!="null"&&schoolId!=null)
                 {        
            const newData = { ...data }
            newData["school_id"] = schoolId
            setData(newData);}
           
            const dataArr=[];
            if(roleId==2)
         {   roleService.getDataSchool()
            .then((data) => {
                data.map((e) => {
                    if( e.id!=7)
                    dataArr.push({
                         value: e.id, label: e.title 
                        
                    });
                });
                setUserTypes([...dataArr]);
            
            })}
            if(roleId==3)
            {   roleService.getDataManager()
               .then((data) => {
                   data.map((e) => {
                       if( e.id!=7)
                       dataArr.push({
                            value: e.id, label: e.title 
                           
                       });
                   });
                   setUserTypes([...dataArr]);
               
               })}
        }
   
     
    }
    useEffect(() => {
        getCity();
        getSChool();
    }, []);

    function getSChool() {
        schoolServices.getData()
            .then((data) => {
                const dataArr=[];
                data.map((e) => {
                    dataArr.push({
                         value: e.id, label: e.title 
                        
                    });
                });
                setschoolOPData([...dataArr]);
         
            })
    }
    function handleSubmit(e) {
         e.preventDefault();
        let isValidFName=false;
        let isValidLName=false;
        let isValidPhone=false;
        let isValidRole=false;
        let isValidPass=false;
        
        const fNameValidate=validation.checkNameValidation(data.first_name);
        if(fNameValidate){
            isValidFName=true;
            setErrorsFName(null);

        
        }
            else{
                isValidFName=false
                setErrorsFName("Length must be greater than 3 letters");
    
            
            }
       
            const lNameValidate=validation.checkNameValidation(data.last_name);
            if(lNameValidate){
                isValidLName=true;
              setErrorsLName(null);
          
            }
                else{
                    isValidLName=false
                    setErrorsLName("Length must be greater than 3 letters");
           
                }
                console.log("=========================",data.phone);
                if(data.phone!==null&&data.phone!==""){
                    const phoneValidate=validation.checkPhoneValidation(data.phone);

                    if(phoneValidate){
                        isValidPhone=true;
                    setErrorsPhone(null);
                    
                    }
                        else{
                            isValidPhone=false
                            setErrorsPhone("Enter valid 10 digit phone number");
                    
                        }
                }else{
                    isValidPhone=true;
                    setErrorsPhone(null);
                }
                console.log("=====================jj====",data.password);
                if(data.password!=null&&data.password!=""){

                    const passValidate=validation.checkPassValidation(data.password);

                    if(passValidate){
                        isValidPass=true;
                        setErrorsPass(null);
                    
                    }
                        else{
                            isValidPass=false
                            setErrorsPass("Password must be greater then 8 digit");
                    
                        }
                }else{
                    isValidPass=true;
                    setErrorsPass(null);
                }
          
            
                    if(data.role_id==null){
                        isValidRole=false
                        setErrorsRole("Select Role")
            
                    }
                        else{
                            isValidRole=true;
            
                            setErrorsRole(null)
                            
                        }
            

                    if(isValidFName==true&&isValidLName==true&&isValidPhone==true&&isValidRole==true&&isValidPass==true){
                
                    setLoading(true);
                    setResMsg('')
                    setResSuccess(false);

                    UserServices.addData(data,selectedFile).then((res) => {
                        if (res == 'error') {
                            setResMsg('Something went wrong')
                        } else {
                            if (res['status'] == true) { setResSuccess(true); }
                            setResMsg(res['message'])
                        }
                        handleShow(true);
                        setLoading(false);
                    });
                    }

 
       
    }
 
    function handleChange(e) {
        const newData = { ...data }
        newData[e.target.id] = e.target.value
        setData(newData);
        console.log(data);
    }
    function handleChangeSchool(e) {
       
        const newData = { ...data }
        newData["school_id"] = e.value
        setData(newData);
        console.log(data);
    }
    function handleChangeRole(e) {
       
        const newData = { ...data }
        newData["role_id"] = e.value
        setData(newData);
        console.log(data);
    }
    function handleRemoveImage(){
        setImage(null);
        setImageId(null);
        setSelectedFile(null);
        
    }
    
      function handleChangeImage(event) {
    
        if (event.target.files && event.target.files[0]) {
            if (!event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i))
                 alert('not an image');
    
    else{
        let file_size = event.target.files[0].size;
        if(file_size>0&&file_size<512000)
    {     setSelectedFile(event.target.files[0])
        setImage(URL.createObjectURL(event.target.files[0]));
    }
        else{
            alert("File Size must be less then 500kb");
            // alert(file_size);
        }
    }
           
        }
    }
    
    
    return (
        <> <HeaderSubTitle title='Add New User'/>
        <div className="Cutso-Form">

            <Modal show={show} onHide={handleClose} className='alert-box'>
                <Modal.Header closeButton>
                    <Modal.Title className={resSuccess == false ? 'alert-box-title-error' : 'alert-box-title'}>Response</Modal.Title>
                </Modal.Header>
                <Modal.Body className='alert-box-body'>{resMsg}</Modal.Body>
                <Modal.Footer>
                    <Button   variant="secondary" onClick={() => handleClose()}>
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
                </Modal.Footer>
            </Modal>
            <div className='form-section-update'>

        
              
    
      
            <Form onSubmit={(e) => handleSubmit(e)}>
            <Form.Group className="mb-3">
                    <Form.Label className='form-label' >
                        School*
                    </Form.Label>
                    <Select
                       isDisabled={roleId==1?false:true}
                    defaultValue={schoolId=="null"||schoolTitle=="null"||schoolId==null||schoolTitle==null?null:{"value":schoolId,"label":schoolTitle}}
            
                    onChange={(e) => handleChangeSchool(e)}
        
                    options={schoolOPData}
                    />
                    
                 
                </Form.Group>
                <Form.Group className="mb-3">
                            <Form.Label className='form-label'>
                                Role
                            </Form.Label>
                            
                            <Select
                            required={true}
                    defaultValue={selectedRoolId}
                    onChange={(e) => handleChangeRole(e)}
                    options={userTypes}
                    />
                                
                        </Form.Group>

                        {errorRole?<span className="text-danger">{errorRole}</span>:<></>}
                <Form.Group className="mb-3" >
                  
                </Form.Group>
                <Form.Group  className="mb-3">
                    <Form.Label >
                        Phone*
                    </Form.Label>
            
                        <Form.Control 
                              isInvalid={!!errorPhone}
                         required={data.role_id==8?true:false}
                            //data.role_id==6?false:data.email==""||data.email==null?true:false}
                        onChange={(e) => handleChange(e)} type="number" placeholder="1234567890" name='phone' id='phone' value={data.phone} />
                   
        
                        <Form.Control.Feedback type="invalid">{errorPhone}</Form.Control.Feedback>

                </Form.Group>
                <Form.Group className="mb-3">
                            <Form.Label>
                                Email
                            </Form.Label>
                    
                                <Form.Control
                                 required={ data.role_id==6||data.role_id==8?false:true}
                                    //data.phone==""||data.phone==null?true:false}
                                  onChange={(e) => handleChange(e)} name='email' id='email' value={data.email} type="email" placeholder="email@example.com" className='form-control' />
                    
                        </Form.Group>
                        <></>
                        {data.role_id==6||data.role_id==8?<></>:
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Password
                            </Form.Label>
                                <Form.Control 
                                   isInvalid={!!errorPass}
                                 required={data.email==""||data.email==null?false:true}
                                onChange={(e) => handleChange(e)} name='password' id='password' value={data.password} type="password" placeholder="" className='form-control' />
                          <Form.Control.Feedback type="invalid">{errorPass}</Form.Control.Feedback>
                        </Form.Group>
                             }
                <Form.Group className="mb-3">
                    <Form.Label>
                        First Name*
                    </Form.Label>
                
                        <Form.Control 
                       isInvalid={!!errorsFName}
                        required onChange={(e) => handleChange(e)} type="text" placeholder="Jhon" name='first_name' id='first_name' value={data.first_name} />
                        <Form.Control.Feedback type="invalid">{errorsFName}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>
                        Last Name*
                    </Form.Label>
                 
                        <Form.Control
                         isInvalid={!!errorsLName}
                        required onChange={(e) => handleChange(e)} type="text" placeholder="Alexender" name='last_name' id='last_name' value={data.last_name} />
                    
                     <Form.Control.Feedback type="invalid">{errorsLName}</Form.Control.Feedback>

                </Form.Group>
                <Form.Group  className="mb-3">
                    <Form.Label >
                        Address
                    </Form.Label>
    
                        <Form.Control as="textarea" rows={3} onChange={(e) => handleChange(e)} placeholder='Address' name='address' id='address' value={data.address} />
                    
                </Form.Group>
                {isLoading == true ? <LoadingCompinentCommon /> : 
        
                <Button size="sm"  variant="primary" type="submit">
                    Submit
                </Button>
                }
            </Form>
            </div>
            <div className='image-box'>
<div>
</div>
{image==null? <div className='image-box-add'> <i className="fa fa-image" aria-hidden="true"></i></div>:
      <img id="image" src={image}/>} 
  {
     isLoading==true?
     <></>:
  isImageUploading==true?
  <>
  <br/>
  <LoadingCompinentCommon />
  </>
  :
  image==null?
  <div className="form-group">
  <label htmlFor="myInput" className='image-operation-btn'>Select Image</label>
   <input id="myInput"type="file" name="file" className="mb-4" 
    accept="image/*"
   style={{display:'none'}}
   onClick={(event)=> { 
     event.target.value = null
}}
   onChange={(e) => handleChangeImage(e)}  />
  </div>:
  <>
    <button className='image-operation-btn' onClick={handleRemoveImage}>
      Remove
   </button>

   {/* <button className='image-operation-btn' onClick={handleUploadImage}>
      Upload Image
   </button> */}
   </>
   }
</div>
        
             
        
        </div>
        </>
    );

}

export default AddUser;