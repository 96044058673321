import React from 'react';
import Chart from 'react-apexcharts'
// import {Chart, ArcElement,Title,Tooltip,Legend} from 'chart.js'
// Chart.register(ArcElement,Legend,Tooltip,Title);

function AllTripStudentChart() {
  
    return (
      <div>
        <Chart
        type='bar' 
       height={500}
     
         series={
          [{
            name: 'Present',
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
          }, {
            name: 'Absetnt',
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
          }
        ]
         }
        options={
          {
            legend: {
              show: true
            },
        
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: ['01/02/2023', '01/02/2023', '01/02/2023', '01/02/2023', '01/02/2023', '01/02/2023', '01/02/2023', '01/02/2023', '01/02/2023'],
            },
            title:{
              text:"Trip/Studnet"
            },
              plotOptions:{
                bar: {
                  horizontal: false,
                  columnWidth: '55%',
                  endingShape: 'rounded'
                }

        },

   

      }
        }
        />
      </div>
    );
  }
  
  export default AllTripStudentChart;