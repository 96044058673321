import React from 'react';
import Chart from 'react-apexcharts'
// import {Chart, ArcElement,Title,Tooltip,Legend} from 'chart.js'
// Chart.register(ArcElement,Legend,Tooltip,Title);

function AllUsersChart({student,parent,managers,opManagers,busDrivers,busAssistent}) {

    return (
      <div>
        <Chart
        type="donut" 
  
         series={[student,parent,managers, opManagers, busDrivers,busAssistent]}
        options={
          {
            legend: {
              show: true
            },
            dataLabels: {
              
              formatter: function (val, opts) {
                  return opts.w.config.series[opts.seriesIndex]
              },
            },
            labels: [
                'Student',
                'Parent',
              'Manager',
              'Operations anager',
              'Bus Driver',
              "Bus Assistant"
            ],
            title:{
              text:"All Users"
            },
              plotOptions:{
                pie:{
                  donut:{
                    labels:{
                      show:true,
                      total:{
                        showAlways:true,
                        show:true,
                        fontSize:20
                      },
      
                    }
                  }
                }

        }
      }
        }
        />
      </div>
    );
  }
  
  export default AllUsersChart;